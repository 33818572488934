<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { datelocalised } from '@/utils/date'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useQuoteStore } from '@/stores/quote'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import QuoteFormContinueButton from '@/components/Quote/QuoteFormContinueButton.vue'
import BackButton from '@/components/Quote/BackButton.vue'
import DownloadsList from '@/components/Quote/DownloadsList.vue'
import DetailLineItem from '@/components/Quote/DetailLineItem.vue'
import LinkExternal from '@/components/LinkExternal.vue'
import { addressFormatter, delay } from '@/utils/helpers'
import CurrencyValue from '@/components/Quote/CurrencyValue.vue'
import TimeViewForm from '@/components/Quote/TimeViewForm.vue'
import Modal from '@/components/Modal.vue'
import EffectiveExcessDetailLine from '@/components/Quote/EffectiveExcessDetailLine.vue'

const $route = useRoute()
const $router = useRouter()

const quote = useQuoteStore()

useHead({
  title: 'Your Quote - Zixty Quote'
})

const timeViewFormModal = ref<InstanceType<typeof Modal> | null>(null)

const timeViewFormProcessingState = ref<boolean>(false)

const isAggregator = computed(() => $route.params?.journey === 'aggregator')

const showAlmostThereNext = computed((): boolean => {
  // only do this if this is an aggregator quote
  if (!!isAggregator.value) {
    // check if we have revisions
    const haveRevisions = !!quote.responses.aggregator?.revisions

    // show 'almost there' if no revisions
    if (!haveRevisions) return true

    // show true if we have no available quote
    const haveDocuments = !!quote.policy.selectedBrand?.Documents?.length

    if (haveRevisions && !haveDocuments) return true
  }

  // default is no
  return false
})

const dateTimeStringValue = (dateString?: string) => {
  const dayjsObject = datelocalised(dateString)
  return `
    <span class='flex'>
      <span>${dayjsObject.format('DD')}</span>
      <span>/</span>
      <span>${dayjsObject.format('MM')}</span>
      <span>/</span>
      <span>${dayjsObject.format('YYYY')}</span>
      <span>&nbsp;</span>
      <span>${dayjsObject.format('HH')}</span>
      <span>:</span>
      <span>${dayjsObject.format('mm')}</span>
    </span>
  `
}

const requotedModal = ref<InstanceType<typeof Modal> | null>(null)
const isRequoted = (): boolean => quote.responses.aggregator?.quote?.requoted === true
const requotedHandler = (): boolean => {
  requotedModal.value?.close()
  if (!isAggregator.value || !isRequoted()) return false

  requotedModal.value?.open()
  return true
}
const requotedContinue = (e: Event) => {
  if (isRequoted()) quote.responses.aggregator!.quote!.requoted = false
  requotedModal.value?.close()
  onSubmit(e)
}

const onSubmit = (e: Event) => {
  if (requotedHandler()) return

  // normal flow
  if (!!showAlmostThereNext.value) {
    if (!!isAggregator.value) {
      return $router.push({
        name: 'quote.almost-there-buy'
      })
    }
    return $router.push({
      name: 'quote.almost-there'
    })
  }

  $router.push({
    name: 'quote.upgrade'
  })
}

const aggregatorEdit = (e?: Event) => {
  const revisions = quote.responses.aggregator?.revisions || 0

  quote.responses.aggregator = {
    ...(quote.responses.aggregator || {}),
    revisions: revisions + 1
  }

  $router.push({
    name: 'quote.vehicle'
  })
}

onMounted(() => {
  quote.helpers.recordWebEvent('YOUR_QUOTE')
})
</script>

<template>
  <div class="w-full">
    <div class="pt-6 mx-auto lg:w-6/12 md:max-w-sm">
      <div class="py-3 md:py-6 flex justify-center items-center text-center">
        <QuoteFormTitle>Your quote</QuoteFormTitle>
      </div>
      <div class="w-full md:max-w-sm mx-auto">
        <div class="py-3 w-full flex flex-col justify-center md:justify-start text-center">
          <QuoteFormTitle>
            <span class="text-[#595959]">
              <CurrencyValue
                :value="
                  quote?.policy?.total ||
                  quote.policy.selectedBrand?.PremiumInclFeeWithMandatoryAddOns ||
                  quote.policy.selectedBrand?.PremiumInclFee ||
                  0
                "
                currency="GBP"
              />
            </span>
          </QuoteFormTitle>
          <div class="text-sm text-[#a9a9a9] my-3">
            <span class="flex justify-center items-center"> including IPT & fees </span>
          </div>
        </div>
      </div>
    </div>

    <div class="my-3 md:max-w-2xl mx-auto">
      <Image src="/images/static/quote-banner.png" />
    </div>

    <div class="pt-6 mx-auto lg:w-6/12 md:max-w-sm">
      <form id="form-quote-quote" @submit.prevent="onSubmit" novalidate>
        <div class="w-full md:max-w-sm mx-auto">
          <div class="py-3 w-full flex flex-col justify-center md:justify-start">
            <div class="w-full md:max-w-md mx-auto">
              <DetailLineItem
                :track-hotjar="true"
                title="Registration"
                :value="quote.inputs?.registration_number_ui"
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="Make"
                :value="`${quote?.responses?.vehicle?.make || 'Unknown Make'}`"
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="Model"
                :value="`${quote?.responses?.vehicle?.model || 'Unknown Model'}`"
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="Duration"
                :value="
                  quote.inputs?.selected_duration +
                  ' ' +
                  (quote.inputs?.selected_duration === 1
                    ? quote.inputs?.selected_duration_unit?.replace(/s$/, '')
                    : quote.inputs?.selected_duration_unit)
                "
              >
                <template v-slot:action>
                  <button
                    type="button"
                    class="text-[#95C11F] hover:opacity-80"
                    @click="timeViewFormModal?.open"
                  >
                    <span class="text-xs"><i class="fa fa-pencil" /></span>
                    <span class="mx-1">Edit</span>
                  </button>
                </template>
              </DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="Start date"
                :value="dateTimeStringValue(quote.policy.selectedBrand?.InceptDateTime)"
              >
                <template v-slot:action>
                  <button
                    type="button"
                    class="text-[#95C11F] hover:opacity-80"
                    @click="timeViewFormModal?.open"
                  >
                    <span class="text-xs"><i class="fa fa-pencil" /></span>
                    <span class="mx-1">Edit</span>
                  </button>
                </template>
              </DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="End date"
                :value="dateTimeStringValue(quote.policy.selectedBrand?.ExpiryDateTime)"
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="Product"
                :value="quote.policy.selectedBrand?.Brand === 'Zixtyplus' ? 'Zixty Plus' : 'Zixty'"
              ></DetailLineItem>
            </div>
            <div class="w-full md:max-w-md mx-auto mt-6">
              <DetailLineItem
                :track-hotjar="false"
                title="Name"
                :value="quote.inputs?.first_name + ' ' + quote.inputs?.last_name"
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="Address"
                :value="addressFormatter(quote.inputs?.address, '<br />')"
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="false"
                title="Date of birth"
                :value="datelocalised(quote.inputs?.date_of_birth).format('DD/MM/YYYY')"
              ></DetailLineItem>
            </div>
            <div class="w-full md:max-w-md mx-auto mt-6">
              <EffectiveExcessDetailLine />
            </div>
            <div class="mt-6 w-full">
              <div class="w-60 mx-auto">
                <DownloadsList :documents="quote.policy.selectedBrand?.Documents || []" />
              </div>
            </div>
          </div>
          <div v-if="isAggregator" class="w-full flex justify-center text-sm text-[#595959] py-6">
            <div class="max-w-sm text-center">
              <button class="text-[#95C11F] hover:opacity-80" @click.prevent="aggregatorEdit">
                Edit all details
              </button>
            </div>
          </div>
          <div
            v-if="isAggregator"
            class="w-full flex justify-center text-sm text-[#595959] py-3 pb-6"
          >
            <div class="max-w-sm text-center">
              <p>
                We have used the information provided to us <br />
                and on the
                <span>{{ quote.responses.aggregator?.partner?.name || `aggregator's` }}</span>
                website.
              </p>
              <p>
                By continuing, you accept our <br />
                <LinkExternal href="https://www.zixty.com/terms-and-conditions/"
                  >Terms &amp; Conditions</LinkExternal
                >
                and
                <LinkExternal href="https://www.zixty.com/privacy-policy/"
                  >Privacy Notice</LinkExternal
                >.
              </p>
            </div>
          </div>
          <div
            v-if="!isAggregator"
            class="pt-6 w-full flex flex-col justify-center text-[#595959] text-center"
          >
            <h2 class="text-[#a9a9a9] text-xl md:text-2xl font-thin">Choose your extras next</h2>
          </div>
          <div class="py-3 md:py-6 w-full flex justify-center">
            <QuoteFormContinueButton type="submit" id="submit-go-to-extras-button">
              Continue
            </QuoteFormContinueButton>
          </div>
          <BackButton
            v-if="!isAggregator || !!quote.responses.aggregator?.revisions"
            route-name="quote.almost-there"
          />
        </div>
      </form>
    </div>

    <Modal size="max-w-2xl" ref="requotedModal" :is-closable="true">
      <template v-slot:title>
        <div>Notice</div>
      </template>
      <template v-slot:content>
        <div>
          <div class="text-[#595959] my-3">
            <p>
              Your quote start time has been updated. Please ensure this new time meets your
              insurance needs.
            </p>
          </div>
          <div class="text-center">
            <button
              class="bg-[#95C11F] text-white font-medium p-2 px-6 rounded-full"
              @click.prevent="requotedContinue"
            >
              Continue
            </button>
          </div>
        </div>
      </template>
    </Modal>

    <Modal size="max-w-2xl" ref="timeViewFormModal" :is-closable="!timeViewFormProcessingState">
      <template v-slot:title>
        <div
          :class="{
            'form23a-loading': !!timeViewFormProcessingState
          }"
        >
          Edit your cover
        </div>
      </template>
      <template v-slot:content>
        <div class="mt-1.5">
          <TimeViewForm
            :is-update-view="true"
            :back-button="false"
            :on-success="
              () => {
                $router.push({
                  name: 'quote.quote'
                })

                delay(25).then(() => {
                  timeViewFormModal?.close()
                })
              }
            "
            :is-processing="(state: boolean) => {
              if (state === true || state === false) {
                timeViewFormProcessingState = state
              }
            }"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>
