<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useQuoteStore } from '@/stores/quote'
import { useRoute, useRouter } from 'vue-router'
import { buyPolicyLookup, isZixtyAxiosErrorCheck, onCoverCheck, getWebQuote } from '@/utils/api'
import type { ApiErrorResponse } from '@/utils/api'
import type { BrandTypes } from '@/utils/api-types'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import LoadingText from '@/components/LoadingText.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'
import { delay } from '@/utils/helpers'

const $route = useRoute()
const $router = useRouter()

const quote = useQuoteStore()

const errorsServer = ref<ApiErrorResponse | undefined>()

const showSpinner = ref<boolean>(true)

// according to google this can be: 'succeeded', 'failed', 'canceled'
const redirectStatus = ref<string>(($route.query?.redirect_status || '') as string)

const redirect = (name: string) => {
  $router.push({
    name: name
  })
}

const check = async (correlation_id: string, tries: number = 1) => {
  console.log('ProcessingView : correlation_id', correlation_id, ' attempt', tries)
  try {
    const resp = await onCoverCheck({
      correlation_id: correlation_id
    })

    if (!!resp.headers && resp.headers['content-type'] === 'text/html') {
      throw Error('invalid response')
    } else {
      const data = resp.data

      quote.responses.cover = data

      if (!!data.policy_number) {
        console.log('ProcessingView : success', data.policy_number)
        redirect('quote.thanks')
      } else {
        console.error('ProcessingView : failed, no policy number')
        throw Error('no policy number')
      }
    }
  } catch (error) {
    console.error('ProcessingView : error on try', tries, error)

    errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined

    if (!!errorsServer.value) {
      showSpinner.value = false
    } else if (tries > 5 && !!errorsServer.value) {
      showSpinner.value = false
    } else {
      await delay(1000 * tries)
      await check(correlation_id, tries + 1)
    }
  }
}

const getCorrelationId = (): string => {
  return ($route?.query?.correlation_id || '') as string
}

const getBrand = () => {
  return ($route?.query?.brand || 'Zixty') as BrandTypes
}

onMounted(async () => {
  if (!!getCorrelationId()) {
    // add the correlation_id back to the store
    quote.responses.correlation_id = getCorrelationId()

    // set the brand name
    quote.policy.brand = getBrand()

    // get details of the webquote
    try {
      const webQuoteResponse = await getWebQuote(getCorrelationId())
      if (!!webQuoteResponse?.data) {
        quote.responses.webquote = webQuoteResponse.data
      }
    } catch (err) {
      //
    }

    // handle...
    if (redirectStatus.value === 'failed' || redirectStatus.value === 'canceled') {
      // lookup the correlation_id and then rehydrate the buy state
      // so if we send the user back to the payment form it works
      const buyPolicyLookupRequest = getCorrelationId()

      try {
        const resp = await buyPolicyLookup(buyPolicyLookupRequest)

        quote.responses.correlation_id = getCorrelationId()
        quote.responses.buy = resp.data
      } catch (err) {
        buyPolicyLookup.delete(buyPolicyLookupRequest)
      }
    } else {
      check(getCorrelationId())
    }
  }
})
</script>

<template>
  <div class="w-full">
    <FormServerErrors v-if="errorsServer" :error="errorsServer" />
    <div class="flex justify-center items-center">
      <div class="flex items-center">
        <div v-if="redirectStatus === 'failed' || redirectStatus === 'canceled'">
          <div v-if="!!quote.responses?.buy && !!quote.responses?.buy?.total_billable_amount">
            <router-link
              :to="{
                name: 'quote.payment'
              }"
            >
              <span v-if="redirectStatus === 'failed'">
                Looks like the payment request failed. Click here to try again.
              </span>
              <span v-if="redirectStatus === 'canceled'">
                Looks like the payment request was canceled. Click here to try again.
              </span>
            </router-link>
          </div>
          <div v-else>
            <router-link
              :to="{
                name: 'quote.vehicle'
              }"
            >
              <span>Sorry, it looks like you need to restart this quote.</span>
            </router-link>
          </div>
        </div>
        <div v-else>
          <div class="flex flex-col items-center" v-if="showSpinner">
            <div class="text-4xl my-6">
              <LoadingSpinner />
            </div>
            <div>
              <LoadingText text="Loading" :dots="0" class="text-[#95C11F] text-3xl font-thin" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
