import AggregatorLayout from '@/views/Aggregator/Layout.vue'

import AggregatorView from '@/views/Aggregator/AggregatorView.vue'

export const AggregatorRoutes = [
  {
    path: '/aggregator',
    name: 'aggregator',
    component: AggregatorLayout,
    children: [
      {
        path: ':aggregator',
        name: 'aggregator.aggregator',
        component: AggregatorView
      }
    ]
  }
]
