import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'

import type { Vue } from '@sentry/vue/types/types'
import type { Router } from 'vue-router'

export default (vueApp: Vue | Vue[] | undefined, vueRouter: Router) => {
  const env = import.meta.env.VITE_APP_ENVIRONMENT || ''
  if (!env.includes('local') || import.meta.env.VITE_APP_SEND_TO_SENTRY_ALWAYS === true) {
    Sentry.init({
      app: vueApp,
      dsn: 'https://fe1ac447e21d43fa9641c1894a1e8a04@o96068.ingest.sentry.io/4504014594965504',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(vueRouter)
        })
      ],
      maxBreadcrumbs: 10,
      environment: env,
      release: (import.meta.env.VITE_APP_PORTAL_VERSION || '') as string,
      tracesSampleRate: env.includes('local') ? 1.0 : 0.2,
      logErrors: env === 'production' ? false : true,
      debug: env === 'local' ? true : false,
      attachProps: true,
      attachStacktrace: true,
      beforeSend(event, hint) {
        try {
          const error: any = hint.originalException
          const errorMessage = error && error.message ? error.message : ''
          if (
            errorMessage.match(/H.Map \(Arg/i) ||
            errorMessage.match(/Cannot set property \'postscribe\' of/i) ||
            errorMessage.match(/Navigating to current location/) ||
            errorMessage.match(/ResizeObserver loop limit exceeded/)
          ) {
            console.log('Skipping sending error to Sentry as matches pre-set filter')
            return null
          }
        } catch (error) {
          console.log('Error occured whilst trying to filter out exception for Sentry')
        }
        return event
      }
    })
  }
}
