<script setup lang="ts">
import type { Dayjs } from 'dayjs'
import { DATE_FORMAT } from '@/constants'
import { delay } from '@/utils/helpers'
import { datelocalised } from '@/utils/date'
import { ref, computed, watch, onMounted, toRef } from 'vue'
import { useHead } from '@vueuse/head'
import { useQuoteStore } from '@/stores/quote'
import {
  DriverDetailsSchema,
  HowLongForSchema,
  getCoverStartMaximumDaysFromNow
} from '@/stores/quote.schema'
import type { HowLongForSchemaErrorsType } from '@/stores/quote.schema'
import QuoteFormFieldErrors from '@/components/Quote/QuoteFormFieldErrors.vue'
import QuoteFormContinueButton from '@/components/Quote/QuoteFormContinueButton.vue'
import BackButton from '@/components/Quote/BackButton.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'
import FormSpinner from '@/components/Quote/FormSpinner.vue'
import { isZixtyAxiosErrorCheck } from '@/utils/api-errors'
import {
  getFullQuote,
  type ApiErrorResponse,
  getQuickQuote,
  type FullQuoteResponse
} from '@/utils/api'
import { useRouter } from 'vue-router'

const withLeadingZero = (val: number | string): string => ('00' + (val || '')).slice(-2)

export interface Props {
  isUpdateView?: boolean
  backButton?: boolean
  onCancel?: () => void
  onSuccess?: () => void
  isProcessing?: (state: boolean) => void
}

const props = withDefaults(defineProps<Props>(), {
  isUpdateView: false,
  backButton: true,
  onCancel: () => {
    //
  },
  onSuccess: () => {
    //
  },
  isProcessing: (state: boolean) => {
    //
  }
})

const $router = useRouter()

const quote = useQuoteStore()

const inAjaxCall = ref<boolean>(false)

watch(inAjaxCall, (nv: boolean, ov: boolean) => {
  if (nv !== ov) {
    props.isProcessing(nv)
  }
})

const errorsClient = ref<HowLongForSchemaErrorsType | undefined>()
const errorsServer = ref<ApiErrorResponse | undefined>()

const cover_start_unselected_date = datelocalised().startOf('day').subtract(1, 'day')
const cover_start_unselected_date_unix = cover_start_unselected_date.unix()

const fullDateObject = datelocalised(
  quote.inputs?.cover_start_date_time || cover_start_unselected_date
).startOf('day')

const cover_start_date = toRef(
  quote?.inputs,
  'cover_start_date',
  fullDateObject.format(DATE_FORMAT)
)

const cover_start_date_unix = ref<number>(fullDateObject.unix())

const cover_start_hour = toRef(
  quote?.inputs,
  'cover_start_hour',
  withLeadingZero(fullDateObject.get('hours'))
)

const cover_start_minute = toRef(
  quote?.inputs,
  'cover_start_minute',
  withLeadingZero(fullDateObject.get('minutes'))
)

const isSelectedDateHistoric = computed(() => {
  return datelocalised().startOf('day').unix() - 1 > cover_start_date_unix.value
})

const getCoverMinuteIncrements = computed(() => {
  const isSelectedDay = datelocalised().startOf('day').unix() === cover_start_date_unix.value

  const isSelectedHour = Number(datelocalised().format('H')) === Number(cover_start_hour.value)

  const fivePlusMinutesFromNow = datelocalised().minute() + 5

  let minuteSlots: string[] = Array(12)
    .fill(5)
    .map((value, index) => index * value)
    .filter(() => !isSelectedDateHistoric.value)
    .filter((minutes) => !(isSelectedDay && isSelectedHour && fivePlusMinutesFromNow > minutes))
    .map((minutes) => withLeadingZero(minutes))

  if (!!cover_start_minute.value && minuteSlots.indexOf(cover_start_minute.value) === -1) {
    cover_start_minute.value = ''
  }

  return minuteSlots
})

const getCoverHourIncrements = computed(() => {
  const isSelectedToday = datelocalised().startOf('day').unix() === cover_start_date_unix.value

  const currentHour = datelocalised().hour()

  const minutesUntilNextHour = 60 - datelocalised().minute()

  let hourSlots: string[] = Array(24)
    .fill(1)
    .map((_, hour) => hour)
    .filter(() => !isSelectedDateHistoric.value)
    .filter((hour) =>
      isSelectedToday && (currentHour > hour || (currentHour === hour && minutesUntilNextHour <= 9))
        ? false
        : true
    )
    .map((hour) => withLeadingZero(hour))

  if (!!cover_start_hour.value && hourSlots.indexOf(cover_start_hour.value) === -1) {
    cover_start_hour.value = ''
  }

  return hourSlots
})

const getDurationUnitOptions = computed(() => {
  const selected_duration_unit = quote?.inputs?.selected_duration_unit || ''

  const is_day = selected_duration_unit === 'days'

  let max_value = is_day ? 28 : 24

  return Array(max_value)
    .fill(0)
    .map((val, key) => {
      let count = key + 1

      let value = count
      let label

      if (is_day) {
        label = count === 1 ? `${count}  day` : `${count} days`
      } else {
        label = count === 1 ? `${count}  hour` : `${count} hours`
      }

      return {
        value,
        label
      }
    }) as { value: number; label: string }[]
})

const getCoverStartOptions = computed(() => {
  const maxDay = getCoverStartMaximumDaysFromNow()

  const dateformat = 'dddd Do MMMM'

  let daysCount = -1

  let day
  let labelPrefix
  let resp = []

  while (true) {
    // increment
    daysCount++

    // get the day as a dayjs obj
    day = datelocalised().add(Number(daysCount), 'days').startOf('day')

    // exit when this day exceeds the max date
    if (day.unix() > maxDay.unix()) {
      break
    }

    // create the label prefix
    labelPrefix = ''
    if (day.isToday()) {
      labelPrefix = 'Today - '
    } else if (day.isTomorrow()) {
      labelPrefix = 'Tomorrow - '
    }

    // add to the response
    resp.push({
      value: day.unix(),
      label: labelPrefix + day.format(dateformat)
    })
  }

  // return what we have
  return resp
})

const setCoverStartDateTime = () => {
  let resp: Dayjs =
    cover_start_date_unix.value > 0
      ? datelocalised(new Date(cover_start_date_unix.value * 1000))
      : datelocalised()

  if (cover_start_hour.value) {
    resp = resp.set('hour', Number(cover_start_hour.value))
  }

  if (cover_start_minute.value) {
    resp = resp.set('minute', Number(cover_start_minute.value))
  }

  quote.inputs.cover_start_date_time = resp.toDate()
}

watch(
  () => {
    return {
      date: cover_start_date_unix.value,
      hour: cover_start_hour.value,
      minute: cover_start_minute.value
    }
  },
  (newValue, oldValue) => {
    // Turn off the 'requoted' flag. It is set to be on when the aggregator adjusts the start date.
    // This flag is used for showing a Modal to let the user know about this change. But if the user
    // changes the date on their own then there is no need for the Modal. So, we switch the flag off.
    if (
      quote.responses.aggregator?.quote?.requoted === true &&
      (newValue.date !== oldValue.date ||
        newValue.hour !== oldValue.hour ||
        newValue.minute !== oldValue.minute)
    ) {
      quote.responses.aggregator.quote.requoted = false
    }

    // update quote store value for date when it's different from newValue
    const cover_start_date_formatted = datelocalised(newValue.date * 1000).format(DATE_FORMAT)
    if (cover_start_date_formatted !== cover_start_date.value) {
      cover_start_date.value = cover_start_date_formatted
    }

    // when the date changes reset the hour and minutes, also update the quote store value
    if (newValue.date !== oldValue.date) {
      // reset hours/minutes in nextTick if the values are not available
      delay(50).then(() => {
        if (
          getCoverHourIncrements.value.indexOf(cover_start_hour.value) === -1 ||
          getCoverMinuteIncrements.value.indexOf(cover_start_minute.value) === -1
        ) {
          cover_start_hour.value = ''
          cover_start_minute.value = ''
        }
      })
    }

    // when the hour changes, pick the first item in minutes
    delay(75).then(() => {
      if (!!newValue.hour && newValue.hour !== oldValue.hour) {
        cover_start_minute.value = '' + getCoverMinuteIncrements.value.slice().shift()
      }
    })

    // set the start date time in the quote store
    setCoverStartDateTime()
  }
)

watch(
  () => {
    return quote.inputs?.selected_duration_unit || 0
  },
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      // reset on change
      quote.inputs.selected_duration = 0
    }
  }
)

onMounted(() => {
  setCoverStartDateTime()
})

useHead({
  title: 'Cover Time - Zixty Quote'
})

const onSubmitHandler = async (e: Event) => {
  if (!!inAjaxCall.value) return
  inAjaxCall.value = true

  quote.inputs.selected_duration = Number(quote.inputs?.selected_duration || 0)

  const safeParsed = HowLongForSchema.safeParse({ ...quote.inputs })

  errorsClient.value = safeParsed.success ? undefined : safeParsed.error.flatten()
  errorsServer.value = undefined

  if (safeParsed.success) {
    props.onSuccess()
  }

  inAjaxCall.value = false
}

const onSubmitQuickQuoteHandler = async (e: Event) => {
  if (!!inAjaxCall.value) return
  inAjaxCall.value = true

  const safeParsed = DriverDetailsSchema.safeParse({ ...quote.inputs })

  errorsClient.value = safeParsed.success ? undefined : safeParsed.error.flatten()
  errorsServer.value = undefined

  if (safeParsed.success) {
    try {
      const request = {
        correlation_id: quote.responses.correlation_id || '',
        cover_start: datelocalised(quote.inputs?.cover_start_date_time).toISOString(),
        cover_period_hours: quote.inputs.cover_period_hours || 0,
        first_name: quote.inputs.first_name || '',
        last_name: quote.inputs.last_name || '',
        date_of_birth: datelocalised(quote.inputs.date_of_birth).format('YYYY-MM-DD'),
        address: quote.inputs.address || '',
        email_address: quote.inputs.email_address || '',
        licence_type: String(quote.inputs.dlntype)
      }

      const response = await getQuickQuote(request)

      if (response.data.status !== 'OK') {
        $router.push({
          name: 'quote.sorry'
        })
      } else {
        quote.responses.quickquote = response.data
        quote.responses.quote_expires_at = response?.headers['x-expiry-date'] || ''

        quote.responses.fullquote = {
          ...(quote.responses.fullquote || {}),
          quotes: {
            ...(quote.responses.fullquote?.quotes || {}),
            [quote.policy.brand]: {
              ...(quote.responses.fullquote?.quotes?.[quote.policy.brand] || {}),
              PremiumInclFee: response.data?.quote?.premium || '0',
              InceptDateTime:
                response.data?.quote?.InceptDateTime ||
                quote.policy.selectedBrand?.InceptDateTime ||
                '',
              ExpiryDateTime:
                response.data?.quote?.ExpiryDateTime ||
                quote.policy.selectedBrand?.ExpiryDateTime ||
                ''
            }
          } as FullQuoteResponse['quotes']
        }

        props.onSuccess()
      }
    } catch (error) {
      errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined
    }
  }

  inAjaxCall.value = false
}

const onSubmitUpdateHandler = async (e: Event) => {
  if (!!inAjaxCall.value) return
  inAjaxCall.value = true

  const safeParsed = HowLongForSchema.safeParse({ ...quote.inputs })

  errorsClient.value = safeParsed.success ? undefined : safeParsed.error.flatten()
  errorsServer.value = undefined

  if (safeParsed.success) {
    try {
      const response = await getFullQuote({
        correlation_id: quote.responses.correlation_id || '',
        driving_licence_number: quote.inputs.driving_licence_number || '',
        phone_number: '+44' + (quote.inputs.phone_number || '').replace(/^\0/, ''),
        reason_for_cover: quote.inputs.reason_for_cover || '',
        reason_for_cover_own_vehicle: quote.inputs.reason_for_cover_own_vehicle || null,
        relation: quote.inputs.relation || null,
        occupation: quote.inputs.occupation || '',
        business_type: quote.inputs.business_type || '',
        lender_first_name: quote.inputs.lender_first_name || '',
        lender_last_name: quote.inputs.lender_last_name || '',
        cover_start: datelocalised(quote.inputs.cover_start_date_time).toISOString(),
        cover_period_hours: quote.inputs.cover_period_hours || 0,
        gender: quote.inputs.gender || ''
      })

      quote.responses.quote_expires_at = response?.headers['x-expiry-date'] || ''

      const data = response.data

      quote.responses.fullquote = {
        ...data
      }

      props.onSuccess()
    } catch (error) {
      errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined
    }
  }

  inAjaxCall.value = false
}

const onSubmit = async (e: Event) => {
  if (!!props.isUpdateView) {
    if (!quote.inputs.driving_licence_number) {
      onSubmitQuickQuoteHandler(e)
    } else {
      onSubmitUpdateHandler(e)
    }
  } else {
    quote.helpers.recordWebEvent('DURATION_SUBMITTED')
    onSubmitHandler(e)
  }
}
</script>

<template>
  <div>
    <FormServerErrors v-if="errorsServer" :error="errorsServer" />
    <div class="w-full">
      <form
        id="form-quote-cover-time"
        @submit.prevent="onSubmit"
        novalidate
        :class="{
          'form23a-loading': inAjaxCall
        }"
      >
        <FormSpinner v-if="inAjaxCall" />
        <div
          class="form23a-field-group"
          :class="{
            error: !!errorsClient?.fieldErrors?.selected_duration_unit
          }"
        >
          <label class="form23a-label" for="duration_type">
            Select your cover duration in hours or days
          </label>
          <select
            name="duration_unit"
            id="form-quote-cover-time--field-duration-unit"
            v-model="quote.inputs.selected_duration_unit"
            class="data-hj-allow form23a-select"
            :class="{
              'form23a-unselected': !quote.inputs.selected_duration_unit
            }"
          >
            <option disabled :value="undefined">Select a duration unit...</option>
            <option
              v-for="item in [
                { value: 'hours', label: 'Hours (1 to 24 hours)' },
                { value: 'days', label: 'Days (1 to 28 days)' }
              ]"
              :value="item.value"
              :key="item.value"
            >
              {{ item.label }}
            </option>
          </select>
          <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.selected_duration_unit" />
        </div>
        <div
          class="form23a-field-group"
          :class="{
            error: !!errorsClient?.fieldErrors?.selected_duration
          }"
        >
          <label class="form23a-label" for="duration"> Select your cover duration </label>
          <select
            name="duration"
            id="form-quote-cover-time--field-duration"
            v-model="quote.inputs.selected_duration"
            class="data-hj-allow form23a-select"
            :class="{
              'form23a-unselected': !quote.inputs.selected_duration
            }"
          >
            <option disabled value="0">Select a duration...</option>
            <option v-for="item in getDurationUnitOptions" :value="item.value" :key="item.value">
              {{ item.label }}
            </option>
          </select>
          <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.selected_duration" />
        </div>
        <div
          class="form23a-field-group"
          :class="{
            error:
              !!errorsClient?.fieldErrors?.cover_start_date_time ||
              !!errorsClient?.fieldErrors?.cover_start_date ||
              !!errorsClient?.fieldErrors?.cover_start_hour ||
              !!errorsClient?.fieldErrors?.cover_start_minute
          }"
        >
          <label for="cover_start" class="form23a-label"> When do you want cover to start? </label>
          <span class="text-xs hidden">{{ quote.inputs.cover_start_date_time }}</span>
          <select
            class="data-hj-allow form23a-select w-full truncate pr-9"
            name="start_date"
            id="form-quote-cover-time--field-start-date"
            v-model="cover_start_date_unix"
            :class="{
              'form23a-unselected':
                !cover_start_date_unix || cover_start_date_unix === cover_start_unselected_date_unix
            }"
          >
            <option disabled :value="cover_start_unselected_date_unix">
              Select a cover start date...
            </option>
            <option v-for="item in getCoverStartOptions" :key="item.value" :value="item.value">
              {{ item.label }}
            </option>
          </select>
        </div>
        <div
          class="form23a-field-group"
          :class="{
            error:
              !!errorsClient?.fieldErrors?.cover_start_date_time ||
              !!errorsClient?.fieldErrors?.cover_start_date ||
              !!errorsClient?.fieldErrors?.cover_start_hour ||
              !!errorsClient?.fieldErrors?.cover_start_minute
          }"
        >
          <label for="" class="form23a-label"> What time do you want cover to start? </label>
          <div class="w-full -ml-1.5">
            <select
              name="start_time_hour"
              id="form-quote-cover-time--field-start-time-hour"
              class="data-hj-allow form23a-select w-28 ml-1.5"
              v-model="cover_start_hour"
              :class="{
                'form23a-unselected': !cover_start_hour
              }"
            >
              <option value="" disabled>Hour</option>
              <option
                v-for="hours in getCoverHourIncrements"
                :key="hours"
                :selected="hours === cover_start_hour"
                :value="hours"
              >
                {{ hours }}
              </option>
            </select>
            <select
              name="start_time_minutes"
              id="form-quote-cover-time--field-start-time-minutes"
              class="data-hj-allow form23a-select w-28 ml-1.5"
              v-model="cover_start_minute"
              :class="{
                'form23a-unselected': !cover_start_minute
              }"
            >
              <option value="" disabled>Minutes</option>
              <option
                v-for="minutes in getCoverMinuteIncrements"
                :key="minutes"
                :selected="minutes === cover_start_minute"
                :value="minutes"
              >
                {{ minutes }}
              </option>
            </select>
          </div>
          <QuoteFormFieldErrors
            :errors="
              errorsClient?.fieldErrors?.cover_start_date_time ||
              errorsClient?.fieldErrors?.cover_start_date ||
              errorsClient?.fieldErrors?.cover_start_hour ||
              errorsClient?.fieldErrors?.cover_start_minute
            "
          />
        </div>
        <div class="py-3 md:py-6 w-full flex justify-center">
          <QuoteFormContinueButton type="submit" :deactivate="inAjaxCall" id="submit-time-button">
            Continue
          </QuoteFormContinueButton>
        </div>
        <BackButton v-if="!!backButton" :on-click="onCancel" />
      </form>
    </div>
  </div>
</template>
