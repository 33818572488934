<script setup lang="ts">
import { ref } from 'vue'
import { delay } from '@/utils/helpers'
import type { FullQuoteDocumentResponse } from '@/utils/api'
import IconDownload from '@/components/icons/IconDownload.vue'

export type PropsDocument = Pick<FullQuoteDocumentResponse, 'document_ref' | 'url' | 'name'>

export interface Props {
  documents: PropsDocument[]
}

const props = withDefaults(defineProps<Props>(), {
  //
})

const fetching = ref<Record<string, boolean>>({
  //
})

const errors = ref<Record<string, string | null>>({
  //
})

const download = async (policyDocument: PropsDocument, e?: Event) => {
  // local vars
  let response
  let policyDocumentUrl = policyDocument?.url || ''

  // set fetching state
  if (!!fetching.value[policyDocumentUrl]) return
  fetching.value[policyDocumentUrl] = true

  // reset error
  errors.value[policyDocumentUrl] = null

  try {
    // fetch the "endpoint"
    response = await fetch(policyDocumentUrl)

    if (response.status === 200) {
      // create a url for the "endpoint" and open in new window
      const url = window.URL.createObjectURL(await response.blob())
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()

      // cleanup
      delay(100).then(() => {
        link.parentNode?.removeChild(link)
      })
    } else if (response.status === 400) {
      // update errors
      const jsonerror: {
        code: string
        message: string
      } = await response.json()

      if (jsonerror.code === 'CSS004') {
        errors.value[policyDocumentUrl] = `Please try again in a few moments.`
      } else if (jsonerror.code === 'CSS004') {
        errors.value[policyDocumentUrl] = `Link has expired.`
      }
    } else {
      errors.value[policyDocumentUrl] = `Something has gone wrong, please check and try again.`
    }
  } catch (error) {
    // generic errors
    console.log(error)
    errors.value[policyDocumentUrl] = `Please try again in a few moments.`
  }

  // cancel fetching state
  fetching.value[policyDocumentUrl] = false
}
</script>

<template>
  <div class="w-full" v-for="doc in documents || []" :key="doc.document_ref">
    <a
      :href="doc.url"
      target="_blank"
      rel="noopener"
      class="flex flex-wrap items-center my-3 text-sm text-[#95C11F] hover:opacity-80 -ml-1"
      @click.prevent="download(doc, $event)"
    >
      <div class="flex items-center">
        <IconDownload />
        <span class="ml-1">
          {{ doc.name }}
        </span>
      </div>
      <div v-if="!!errors[doc.url]" class="text-xs block text-red-500 ml-3">
        {{ errors[doc.url] }}
      </div>
    </a>
  </div>
</template>
