import axios, { type AxiosResponse } from 'axios'
import { useMemoize } from '@vueuse/core'
import { asZodFlatErrors, isAxiosErrorCheck, isZixtyAxiosErrorCheck } from '@/utils/api-errors'
import type {
  ApiErrorResponse,
  StartRequest,
  StartResponse,
  AggregatorStartRequest,
  AggregatorStartResponse,
  AddressLookupRequest,
  AddressLookupResponse,
  VehicleLookupRequest,
  VehicleLookupResponse,
  QuickQuoteRequest,
  QuickQuoteEnumerationsType,
  QuickQuoteResponse,
  FullQuoteRequest,
  FullQuoteDocumentResponse,
  FullQuoteAddOnResponse,
  FullQuoteResponseEntry,
  FullQuoteResponse,
  BuyPolicyRequest,
  BuyPolicyResponse,
  OnCoverRequest,
  OnCoverResponse,
  GetWebQuoteResponse,
  PolicyDocumentsRequest,
  PolicyDocumentItemResponse,
  PolicyDocumentsResponse,
  PolicySelectionsRequest,
  PolicySelectionsResponse,
  RecordWebEventRequest,
  RecordWebEventResponse,
  isOccupationBusinessOkRequest,
  isOccupationBusinessOkResponse
} from '@/utils/api-types'

// re-exported for ease
export type {
  ApiErrorResponse,
  StartRequest,
  StartResponse,
  AggregatorStartRequest,
  AggregatorStartResponse,
  AddressLookupRequest,
  AddressLookupResponse,
  VehicleLookupRequest,
  VehicleLookupResponse,
  QuickQuoteRequest,
  QuickQuoteEnumerationsType,
  QuickQuoteResponse,
  FullQuoteRequest,
  FullQuoteDocumentResponse,
  FullQuoteAddOnResponse,
  FullQuoteResponseEntry,
  FullQuoteResponse,
  BuyPolicyRequest,
  BuyPolicyResponse,
  OnCoverRequest,
  OnCoverResponse,
  GetWebQuoteResponse,
  PolicyDocumentsRequest,
  PolicyDocumentItemResponse,
  PolicyDocumentsResponse,
  PolicySelectionsRequest,
  PolicySelectionsResponse,
  RecordWebEventRequest,
  RecordWebEventResponse,
  isOccupationBusinessOkRequest,
  isOccupationBusinessOkResponse
}

export { asZodFlatErrors, isAxiosErrorCheck, isZixtyAxiosErrorCheck }

// axios setup
const axiosInstance = axios.create({
  baseURL: '',
  timeout: 60 * 1000,
  headers: {
    'content-type': 'application/json',
    'x-custom-timeout-seconds': '60',
    'x-web-version': import.meta.env.VITE_APP_PORTAL_VERSION
  }
})

// helpers
const v3Path = (path: string): string => `/v3/zixty/webquote/${path}/`

// fetch HTML content
export const getHTMLContent = useMemoize(async (url: string) => {
  return axios.create().get<string>(url)
})

// api's
export const getStart = async (request: StartRequest) => {
  return axiosInstance.post<StartResponse>(v3Path('start'), request)
}

export const getAggregatorStart = async (request: AggregatorStartRequest) => {
  return axiosInstance.post<AggregatorStartResponse>(v3Path('aggstart'), request)
}

export const getVehicle = async (request: VehicleLookupRequest) => {
  return axiosInstance.post<VehicleLookupResponse>(v3Path('vehicle'), request)
}

export const getAddresses = useMemoize(async (request: AddressLookupRequest) => {
  return axiosInstance.post<AddressLookupResponse>(v3Path('address'), request)
})

export const getAddressesSetKeyValue = (
  key: AddressLookupRequest,
  value: AddressLookupResponse
) => {
  if (!key?.correlation_id) return
  if (!key?.postcode) return
  if (!value) return

  getAddresses.cache.set(
    getAddresses.generateKey({
      correlation_id: key.correlation_id,
      postcode: (key?.postcode || '').replace(' ', '').toUpperCase()
    }),
    Promise.resolve({
      data: {
        ...(value || {})
      },
      status: 200,
      statusText: 'OK',
      headers: {},
      config: {},
      request: {}
    }) as Promise<AxiosResponse<AddressLookupResponse>>
  )
}

export const getQuickQuote = async (request: QuickQuoteRequest) => {
  return axiosInstance.post<QuickQuoteResponse>(v3Path('quickquote'), request)
}

export const getFullQuote = async (request: FullQuoteRequest) => {
  return axiosInstance.post<FullQuoteResponse>(v3Path(`fullquote`), request)
}

export const buyPolicy = async (request: BuyPolicyRequest) => {
  return axiosInstance.post<BuyPolicyResponse>(v3Path('buy'), request)
}

export const buyPolicyLookup = useMemoize(async (correlation_id: string) => {
  return axiosInstance.get<BuyPolicyResponse>(v3Path('buy') + `${correlation_id}/`)
})

export const onCoverCheck = async (request: OnCoverRequest) => {
  return axiosInstance.post<OnCoverResponse>(v3Path('oncover'), request)
}

export const getWebQuote = async (correlation_id: string) => {
  return axiosInstance.get<GetWebQuoteResponse>(v3Path(`${correlation_id}`))
}

export const getPolicyDocuments = async (request: PolicyDocumentsRequest) => {
  return axiosInstance.post<PolicyDocumentsResponse>(v3Path(`documents`), request)
}

export const continueQuote = async (correlation_id: string) => {
  return axiosInstance.get<any>(v3Path(`continue/${correlation_id}`))
}

export const setPolicySelections = async (request: PolicySelectionsRequest) => {
  return axiosInstance.post<PolicySelectionsResponse>(v3Path(`selections`), request)
}

export const recordWebEvent = async (request: RecordWebEventRequest) => {
  return axiosInstance.post<RecordWebEventResponse>(v3Path('event'), request)
}

// this can only be called after a successful webquote/quickquote or webquote/aggstart call
export const isOccupationBusinessOk = async (request: isOccupationBusinessOkRequest) => {
  return axiosInstance.post<isOccupationBusinessOkResponse>(v3Path('occupation_business'), request)
}
