<script setup lang="ts">
export interface Props {
  total: number
  selected: number
}

const props = withDefaults(defineProps<Props>(), {
  total: 3,
  selected: 1
})

const items = Array(props.total).fill(1)
</script>

<template>
  <div class="flex flex-row -ml-1.5">
    <div class="w-6 h-6 mx-1.5" v-for="(item, index) in items">
      <svg
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        :fill="index < props.selected ? '#95C11F' : '#CCCCCC'"
      >
        <circle cx="30" cy="30" r="30" />
      </svg>
    </div>
  </div>
</template>
