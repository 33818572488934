<script setup lang="ts">
import { ref } from 'vue'
import { useHead } from '@vueuse/head'
import Footer from '@/components/Footer.vue'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import LinkExternal from '@/components/LinkExternal.vue'

useHead({
  title: `Page Not Found - Zixty Quote`
})
</script>

<template>
  <div class="flex">
    <div class="w-full bg-white">
      <div class="w-full flex">
        <div class="w-full px-6 py-3 pb-3 md:px-12 md:pt-12 md:pb-6 md:max-w-2/3">
          <div class="container mx-auto">
            <div id="header" class="flex justify-center md:justify-start px-6">
              <div class="flex flex-col text-right">
                <LinkExternal href="https://zixty.com/" title="Zixty Homepage">
                  <Image src="/images/logo.png" class="h-12" />
                </LinkExternal>
              </div>
            </div>
          </div>
        </div>
        <!-- blank to make the page flow the same as the other pages -->
        <div class="hidden md:block md:w-1/3 max-w-[650px]">&nbsp;</div>
      </div>
      <div class="w-full flex">
        <div class="min-h-[80vh] w-full px-6 py-6 md:px-12 md:py-12">
          <div id="content" class="h-full flex px-3 py-3 md:px-6 md:py-6">
            <div class="w-full">
              <div class="mx-auto">
                <div class="py-3 flex justify-center text-center">
                  <QuoteFormTitle> Page not found </QuoteFormTitle>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="footer">
    <Footer />
  </div>
</template>
