<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import type { AlmostThereEUSchemaErrorsType } from '@/stores/quote.schema'
import IconInformation from '@/components/icons/IconInformation.vue'
import QuoteFormFieldErrors from '@/components/Quote/QuoteFormFieldErrors.vue'
import { useToggle } from '@vueuse/core'
import { useQuoteStore } from '@/stores/quote'

export interface Props {
  modelValue?: string
  errorsClient?: AlmostThereEUSchemaErrorsType | undefined
}

const props = withDefaults(defineProps<Props>(), {
  //
})

const quote = useQuoteStore()

const showHelp = ref<boolean>(false)

const [drivingLicenseHelpToggleValue, drivingLicenseHelpToggle] = useToggle()

// replace 'Required' error with more detail to the error message
const fieldErrors = computed(() => {
  let errors =
    props?.errorsClient?.fieldErrors?.driving_licence_number_ui ||
    props?.errorsClient?.fieldErrors?.driving_licence_number

  return errors
})

// boot
onMounted(async () => {
  //
})
</script>

<template>
  <div
    class="form23a-field-group"
    :class="{
      error:
        !!errorsClient?.fieldErrors?.driving_licence_number_ui ||
        !!errorsClient?.fieldErrors?.driving_licence_number
    }"
    id="field-group-licence-number"
  >
    <!-- label -->
    <div class="flex">
      <label class="form23a-label" for="driver_licence_number"> EU Driver's licence number </label>
      <button
        v-if="showHelp"
        type="button"
        @click.prevent="($event) => drivingLicenseHelpToggle()"
        class="ml-1 -mt-1 text-[#95C11F] cursor-pointer"
      >
        <IconInformation class="h-5 w-5" />
      </button>
    </div>

    <!-- help text subline -->
    <div>
      <small class="my-1 mb-3 text-[#595959]"> ... </small>
    </div>

    <!-- EU licence number input -->
    <div class="w-full">
      <div class="flex -ml-3">
        <input
          type="text"
          name="driver_licence_number_section_d"
          id="form-quote-almost-there--field-driver-licence-number-section-d"
          class="data-hj-allow form23a-input w-full uppercase ml-3 my-1.5"
          placeholder=""
          :value="props.modelValue"
          @input="$emit('update:modelValue', ($event.target as HTMLInputElement)?.value)"
          v-capitalize-input
        />
      </div>

      <input
        type="text"
        name="driver_licence_number"
        id="form-quote-almost-there--field-driver-licence-number"
        class="form23a-input w-full uppercase hidden"
        placeholder="Enter driver's licence number..."
        v-model="quote.inputs.driving_licence_number_ui"
        v-capitalize-input
      />
    </div>

    <!-- EU licence number errors -->
    <QuoteFormFieldErrors :errors="fieldErrors" />

    <!-- EU licence number help close button -->
    <div
      v-show="drivingLicenseHelpToggleValue"
      class="mt-3 text-xs text-[#595959] py-3 px-6 bg-[#F6F6F6] rounded-md"
    >
      <div class="flex justify-end -mr-3">
        <button
          type="button"
          class="cursor-pointer hover:text-[#95C11F] border-none focus:ring-0 focus-visible:outline-none"
          @click.prevent="drivingLicenseHelpToggle()"
          title="Close"
        >
          <span aria-hidden="true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
          <span class="sr-only">Close</span>
        </button>
      </div>

      <!-- EU licence number help -->
      <div class="mt-0.5 mx-auto">
        <Image
          src="/images/static/uk-driving-licence-example.png"
          class="shadow-md rounded-[15px]"
        />
      </div>
      <div class="mt-7 pb-3">
        <p class="mt-3">...</p>
        <p class="mt-3">...</p>
        <p class="mt-3">...</p>
      </div>
    </div>
  </div>
</template>
