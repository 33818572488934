<script setup lang="ts">
export interface Props {
  title: string
  value: string
  trackHotjar?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  value: '',
  trackHotjar: false
})
</script>

<template>
  <div class="flex flex-wrap justify-between w-full py-1 items-start">
    <span
      class="text-[#595959] text-xs md:text-sm font-normal px-2"
      :class="{
        'w-2/5': true
      }"
    >
      <span v-html="props.title"></span>
    </span>
    <span
      class="text-[#414042] text-xs md:text-sm font-semibold px-2"
      :class="{
        'w-2/5': !!$slots.action,
        'w-3/5': !$slots.action
      }"
    >
      <span
        v-html="props.value"
        :class="[!!trackHotjar ? 'data-hj-allow' : 'data-hj-suppress']"
      ></span>
    </span>
    <span
      v-if="!!$slots.action"
      class="text-[#95C11F] text-xs md:text-sm font-normal px-2"
      :class="{
        'w-1/5': true
      }"
    >
      <slot name="action"></slot>
    </span>
  </div>
</template>
