import type { NavigationGuard } from 'vue-router'

import { useQuoteStore } from '@/stores/quote'
import { datelocalised } from '@/utils/date'

import QuoteLayout from '@/views/Quote/Layout.vue'
import VehicleView from '@/views/Quote/VehicleView.vue'
import TimeView from '@/views/Quote/TimeView.vue'
import DriverDetailsView from '@/views/Quote/DriverDetailsView.vue'
import QuickQuoteView from '@/views/Quote/QuickQuoteView.vue'
import AlmostThereView from '@/views/Quote/AlmostThereView.vue'
import QuoteView from '@/views/Quote/QuoteView.vue'
import UpgradeView from '@/views/Quote/UpgradeView.vue'
import DeclarationsView from '@/views/Quote/DeclarationsView.vue'
import ConfirmView from '@/views/Quote/ConfirmView.vue'
import PaymentView from '@/views/Quote/PaymentView.vue'
import ProcessingView from '@/views/Quote/ProcessingView.vue'
import ThanksView from '@/views/Quote/ThanksView.vue'
import SorryView from '@/views/Quote/SorryView.vue'

const hasCorrelationId: NavigationGuard = (to, from, next) => {
  const quote = useQuoteStore()

  // user can continue to route if this is not zixty.com, or they have a correlation_id
  if (quote.meta.isZixtyDotCom === false || !!quote.responses?.correlation_id) {
    next()
  } else {
    next({ name: 'quote.vehicle', params: { ...to.params } })
  }
}

const quoteHasNotExpired: NavigationGuard = (to, from, next) => {
  const quote = useQuoteStore()
  const hasExpired = quote.helpers.hasQuoteExpired()

  // user can continue to route if this is not zixty.com, or the quote has not expired
  if (quote.meta.isZixtyDotCom === false || hasExpired === false) {
    next()
  } else {
    next({ name: 'quote.vehicle', params: { ...to.params } })
  }
}

const needsUtmSource: NavigationGuard = (to, from, next) => {
  const quote = useQuoteStore()
  if (to.path == '/quote/aggregator/quote') {
    let source = quote?.responses?.aggregator?.partner?.name?.toLowerCase().replace('.', '')
    if (source) {
      to.fullPath += '?utm_source=' + source + '&utm_medium=referral'
    }
  }
  next()
}

const quoteSharedGuards = [hasCorrelationId, quoteHasNotExpired, needsUtmSource]

export const QuoteRoutes = [
  {
    path: '/quote',
    name: 'quote',
    component: QuoteLayout,
    children: [
      {
        path: ':journey',
        children: [
          {
            path: 'vehicle',
            name: 'quote.vehicle',
            // component: () => import('@/views/Quote/VehicleView.vue'),
            component: VehicleView
          },
          {
            path: 'time',
            name: 'quote.time',
            // component: () => import('@/views/Quote/TimeView.vue'),
            component: TimeView,
            beforeEnter: [...quoteSharedGuards]
          },
          {
            path: 'driver-details',
            name: 'quote.driver-details',
            // component: () => import('@/views/Quote/DriverDetailsView.vue'),
            component: DriverDetailsView,
            beforeEnter: [...quoteSharedGuards]
          },
          {
            path: 'quick-quote',
            name: 'quote.quick-quote',
            // component: () => import('@/views/Quote/QuickQuoteView.vue'),
            component: QuickQuoteView,
            beforeEnter: [...quoteSharedGuards]
          },
          {
            path: 'almost-there',
            name: 'quote.almost-there',
            // component: () => import('@/views/Quote/AlmostThereView.vue'),
            component: AlmostThereView,
            beforeEnter: [...quoteSharedGuards]
          },
          {
            path: 'almost-there?placement=buy',
            name: 'quote.almost-there-buy',
            // component: () => import('@/views/Quote/AlmostThereView.vue'),
            component: AlmostThereView,
            beforeEnter: [...quoteSharedGuards]
          },
          {
            path: 'quote',
            name: 'quote.quote',
            // component: () => import('@/views/Quote/QuoteView.vue'),
            component: QuoteView,
            beforeEnter: [...quoteSharedGuards]
          },
          {
            path: 'upgrade',
            name: 'quote.upgrade',
            // component: () => import('@/views/Quote/UpgradeView.vue'),
            component: UpgradeView,
            beforeEnter: [...quoteSharedGuards]
          },
          {
            path: 'declarations',
            name: 'quote.declarations',
            // component: () => import('@/views/Quote/DeclarationsView.vue'),
            component: DeclarationsView,
            beforeEnter: [...quoteSharedGuards]
          },
          {
            path: 'confirm',
            name: 'quote.confirm-and-buy',
            // component: () => import('@/views/Quote/ConfirmView.vue')',
            component: ConfirmView,
            beforeEnter: [...quoteSharedGuards]
          },
          {
            path: 'payment',
            name: 'quote.payment',
            // component: () => import('@/views/Quote/PaymentView.vue'),
            component: PaymentView,
            beforeEnter: [...quoteSharedGuards]
          },
          {
            path: 'processing',
            name: 'quote.processing',
            // component: () => import('@/views/Quote/ProcessingView.vue'),
            component: ProcessingView
          },
          {
            path: 'thanks',
            name: 'quote.thanks',
            // component: () => import('@/views/Quote/ThanksView.vue'),
            component: ThanksView
          },
          {
            path: 'sorry',
            name: 'quote.sorry',
            // component: () => import('@/views/Quote/SorryView.vue'),
            component: SorryView
          }
        ]
      }
    ]
  }
]
