<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import LinkExternal from '@/components/LinkExternal.vue'
import { useQuoteStore } from '@/stores/quote'
import { CRISP_CHAT_WEBSITE_ID } from '@/constants'
import { trackAwinPurchase, trackPurchase } from '@/utils/gtag-ecommerce'

const quote = useQuoteStore()

const $route = useRoute()

const partnerName = (document.querySelector('.layout-quote')?.id || '').split('-').pop() || ''

const appImageSrc = ref<string>()

const crispChatUrl = ref<string>(
  `https://go.crisp.chat/chat/embed/?website_id=${CRISP_CHAT_WEBSITE_ID}`
)
const crispChatLoaded = ref<boolean>(!!window.$crisp && !!document.getElementById('crisp-chatbox'))

useHead({
  title: `Thanks! You’re all set - Zixty Quote`
})

const benefits = ref<string[]>([
  'Get your documents',
  'Carbon offsetting',
  'eco-driving tips',
  'Discounts and offers'
])

const isAndroid = () => {
  try {
    return window?.navigator?.userAgent?.toLowerCase()?.indexOf('android') > -1
  } catch (err) {
    //
  }
  return false
}

const stores = ref<
  {
    name: string
    logo: string
    url: string
  }[]
>([
  {
    name: 'Apple',
    logo: '/images/static/app-store-icon-apple.png',
    url: 'https://app.zixty.com/go'
  },
  {
    name: 'Google',
    logo: '/images/static/app-store-icon-google.png',
    url: 'https://app.zixty.com/go'
  }
])

// show android first for android devices
stores.value = isAndroid() ? stores.value.reverse() : stores.value

const isEuLicence = computed(() => {
  return quote?.responses?.webquote?.licence_type === 'LICENCE_TYPE_EU'
})

const openChat = (e: Event) => {
  if (!!window.$crisp) {
    window.$crisp.push(['do', 'chat:open'])
  }
}

const onSubmit = (e: Event) => {
  //
}

onMounted(() => {
  const correlation_id = quote?.responses?.correlation_id
  const total_billable_amount = quote?.responses?.webquote?.total_billable_amount

  const journey_type = $route?.params?.journey || 'direct'

  if (!!correlation_id && !!total_billable_amount) {
    quote.helpers.recordWebEvent('ON_COVER')

    // track google ecommerce purchase
    trackPurchase(correlation_id, total_billable_amount, quote.policy?.brand)

    // track awin purchase when needed
    if (
      !!quote?.responses?.cover?.policy_number &&
      !!quote?.responses?.cover?.awin_commission_group
    ) {
      trackAwinPurchase(
        quote.responses.cover.policy_number,
        total_billable_amount,
        quote.responses.cover.awin_commission_group
      )
    }
  }

  // set the image
  if (['bluelught', 'perkbox'].indexOf(partnerName) > -1) {
    appImageSrc.value = '/images/static/thank-you-page-app-signpost-bluelight-perkbox.png'
  } else {
    appImageSrc.value = '/images/static/thank-you-page-app-signpost-default.png'
  }
})
</script>

<template>
  <div class="w-full">
    <div class="pt-6 mx-auto">
      <div class="py-3 flex justify-center text-center">
        <QuoteFormTitle> Thanks! You’re all set </QuoteFormTitle>
      </div>
      <div class="py-6 flex justify-center" v-if="!!quote.responses?.cover?.policy_number">
        <span class="text-[#595959]" id="policy-number-text">
          Policy Number: {{ quote.responses.cover.policy_number }}
        </span>
      </div>

      <div v-if="isEuLicence" class="w-full max-w-sm mx-auto">
        <div class="text-center text-lg text-[#95C11F] my-12">
          <a href="/my-documents">
            <h3>
              Download your Policy <br />
              documents on Zixty.com
            </h3>
          </a>
        </div>
      </div>
      <div v-else class="w-full max-w-sm mx-auto text-[#595959]">
        <form id="form-quote-thanks" class="mx-auto" @submit.prevent="onSubmit" novalidate>
          <div class="text-center">
            <div class="mx-auto max-w-sm mt-9 mb-6" v-if="appImageSrc">
              <img :src="appImageSrc" alt="image of the app" class="w-full" />
            </div>
            <div v-if="stores && !!stores.length" class="py-6">
              <div v-for="store in stores" class="py-6 flex justify-center" :key="store.name">
                <LinkExternal :href="store.url">
                  <img :src="store.logo" :alt="store.name" class="w-44" />
                </LinkExternal>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="text-center">
        <p class="py-3 text-sm">
          <span v-if="!isEuLicence">
            If you are unable to download the app, please <br />
            contact us using
          </span>
          <span v-if="isEuLicence">
            If you have any questions then please <br />
            contact us using
          </span>
          <span>
            <LinkExternal v-if="!crispChatLoaded" :href="crispChatUrl">chat.</LinkExternal>
            <button
              type="button"
              v-if="crispChatLoaded"
              @click.prevent="openChat"
              class="text-[#95C11F] hover:opacity-80"
            >
              chat.
            </button>
          </span>
        </p>
      </div>

      <div v-if="isEuLicence" class="w-full max-w-sm mx-auto my-12">
        <img src="/images/static/thank-you-page-eu-licence-person.png" class="w-full" />
      </div>
    </div>
  </div>
</template>
