<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { delay, getUtmParameters } from '@/utils/helpers'
import {
  getAggregatorStart,
  isZixtyAxiosErrorCheck,
  getAddressesSetKeyValue,
  type ApiErrorResponse,
  type AggregatorStartResponse,
  type AggregatorStartRequest,
  type FullQuoteResponse
} from '@/utils/api'
import { useQuoteStore } from '@/stores/quote'
import { useHead } from '@vueuse/head'
import LoadingText from '@/components/LoadingText.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'
import { datelocalised } from '@/utils/date'
import { DATE_FORMAT } from '@/constants'

const $route = useRoute()
const $router = useRouter()

const getQueryStringValue = (key: string) => ($route?.query[key] || '') as string

useHead({
  title: `Aggregator - ${$route.params?.aggregator || 'Zixty'}`
})

/*

https://zixty-quick-quote.inzura.local/aggregator/COMC?H=Zixty-services-uat.nextventure.co.uk&cc_origin_code=pyf&cc_quote_id=113&cc_sales_provider_id=0&cc_site_code=GoCo&k=hlNppRcGMXXQVHxnVKRr3WlH0zoSHhsPGxSisTdlhFxslUnHcs8syxfymoKGhIN6

https://zixty-quick-quote.inzura.local/partner/evergreen

https://zixty-quick-quote.inzura.local/quote/partner/processing?correlation_id=0A90D608F0F53B7047DE11D352A2A15FC20E43E063173F03BBC9590D4E601EB2&partner=Evergreen&payment_intent=pi_3NI9GLJT4FeZAdxp1Gt6koEz&payment_intent_client_secret=pi_3NI9GLJT4FeZAdxp1Gt6koEz_secret_7yj2x935jd205BZ3ZqEdd1Sr1&redirect_status=succeeded

https://zixty-quick-quote.inzura.local/aggregator/COMC?H=Zixty-services-uat.nextventure.co.uk&cc_origin_code=pyf&cc_quote_id=113&cc_sales_provider_id=0&cc_site_code=GoCo&k=8qKuU%2Bf7XDa1kLdDYV%2FvDhFG0Z6ELUCmJK%2F8z2jVx%2Fd7d7uFVnfJ9%2B%2Bcif5Uq%2FZ2

*/

const quote = useQuoteStore()

const errorsServer = ref<ApiErrorResponse | undefined>()

const startTime = ref<number>(datelocalised().unix())

const inAjaxCall = ref<boolean>(false)

const aggregator = ref<string>(getQueryStringValue('aggregator'))

const benefits = ref<string[]>([
  'Tree planted for every policy',
  '5-Star Reviews',
  'Free carbon offsetting'
])

// inspiration from https://stackoverflow.com/a/11381730
const isMobileDeviceOverkill = (): boolean => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator?.userAgent || (navigator as any)?.vendor || (window as any)?.opera)
  return check
}

const callAggregatorStartApi = async (
  tries: number = 1
): Promise<Partial<AggregatorStartResponse>> => {
  try {
    // build the getStartRequest
    let aggregatorStartRequest: AggregatorStartRequest = {
      partner: aggregator.value,
      H: getQueryStringValue('H'),
      cc_origin_code: getQueryStringValue('cc_origin_code'),
      cc_quote_id: getQueryStringValue('cc_quote_id'),
      cc_sales_provider_id: getQueryStringValue('cc_sales_provider_id'),
      cc_site_code: getQueryStringValue('cc_site_code'),
      k: getQueryStringValue('k'),
      is_mobile: isMobileDeviceOverkill()
    }

    // get utm params
    const utm_params = getUtmParameters($route)
    if (!!utm_params && Object.keys(utm_params).length > 0) {
      aggregatorStartRequest.utm = utm_params
    } else {
      aggregatorStartRequest.utm = {
        utm_source: getAggregatorCode.value,
        utm_medium: 'referral'
      }
    }

    const response = await getAggregatorStart(aggregatorStartRequest)

    // update the expires at time
    quote.responses.quote_expires_at = response?.headers['x-expiry-date'] || ''

    if (response.data?.postcode_lookup) {
      getAddressesSetKeyValue(
        {
          correlation_id: response.data.correlation_id,
          postcode: response.data.risk?.postcode || ''
        },
        {
          ...response.data.postcode_lookup
        }
      )
    }

    return response?.data || {}
  } catch (error) {
    if (isZixtyAxiosErrorCheck(error)) {
      throw error
    }

    if (tries > 10) {
      throw error
    }

    await delay(tries * 1000)
    return await callAggregatorStartApi(tries + 1)
  }
}

const onStart = async () => {
  if (!!inAjaxCall.value) return
  inAjaxCall.value = true

  try {
    // fetch the data
    const resp = await callAggregatorStartApi()

    // normalise
    const risk = resp.risk || {}
    const coverPeriodHours = Number((resp.risk?.cover_period_hours || 0).toFixed(0))
    const isUnder24Hours = coverPeriodHours <= 24
    const riskCoverStart = datelocalised(risk?.cover_start)
    const riskCoverEnd = datelocalised(risk?.cover_end)

    // update the correlation_id
    quote.responses.correlation_id = resp.correlation_id

    // update the quote
    quote.inputs.email_address = risk?.email_address || ''
    quote.inputs.address = risk?.address || ''
    quote.inputs.postcode = risk?.postcode || (risk?.address || '').split(',').pop()?.trim()
    quote.inputs.cover_start_date = riskCoverStart.format(DATE_FORMAT)
    quote.inputs.cover_start_hour = ('0' + riskCoverStart.hour()).slice(-2)
    quote.inputs.cover_start_minute = ('0' + riskCoverStart.minute()).slice(-2)
    quote.inputs.cover_start_date_time = riskCoverStart.toDate()
    quote.inputs.selected_duration = isUnder24Hours ? coverPeriodHours : coverPeriodHours / 24
    quote.inputs.selected_duration_unit = isUnder24Hours ? 'hours' : 'days'
    quote.inputs.reason_for_cover = risk?.reason_for_cover || ''
    quote.inputs.reason_for_cover_own_vehicle = risk?.reason_for_cover_own_vehicle || ''
    quote.inputs.relation = risk?.relation || ''
    quote.inputs.type_approval = risk?.type_approval || ''
    quote.inputs.journey_type = 'aggregator'
    quote.inputs.registration_number = risk?.registration_number || ''
    quote.inputs.registration_number_ui = risk?.registration_number || ''
    quote.inputs.first_name = risk?.first_name || ''
    quote.inputs.last_name = risk?.last_name || ''
    quote.inputs.dlntype = (risk?.licence_type ||
      'LICENCE_TYPE_FULL') as typeof quote.inputs.dlntype
    if (risk?.licence_held_for_3_or_more_years) {
      quote.inputs.driving_licence_held_for_years = risk?.licence_held_for_3_or_more_years ? 3 : 1
    }
    quote.inputs.occupation = ('0' + risk?.occupation).slice(-3)
    quote.inputs.business_type = ('0' + risk?.business_type).slice(-3)
    quote.inputs.driving_licence_number = resp?.driving_licence_number_is_ok
      ? (risk?.driving_licence_number || '').replace(' ', '')
      : ''
    quote.inputs.driving_licence_number_ui = risk?.driving_licence_number || ''

    if (quote.inputs.driving_licence_number && quote.inputs.driving_licence_number.length >= 16) {
      const dln = quote.inputs.driving_licence_number.slice(0, 16)
      quote.inputs.driving_licence_number_section_a = dln.slice(0, 5)
      quote.inputs.driving_licence_number_section_b = dln.slice(5, 11)
      quote.inputs.driving_licence_number_section_c = dln.slice(11, 12)
      quote.inputs.driving_licence_number_section_d = dln.slice(12, 16)
    }

    // get the gender from the dln
    if (!!quote.inputs.driving_licence_number_section_b) {
      const dlngender = quote.inputs.driving_licence_number_section_b.slice(1, 2)
      if (dlngender === '0' || dlngender === '1') {
        quote.inputs.gender = 'male'
      } else if (dlngender === '5' || dlngender === '6') {
        quote.inputs.gender = 'female'
      }
    }

    quote.inputs.date_of_birth = datelocalised(risk?.date_of_birth, 'YYYY-MM-DD').toDate()
    quote.inputs.phone_number = risk?.phone_number || ''
    quote.inputs.phone_number_ui = risk?.phone_number || ''

    // update the vehicle details
    quote.responses.vehicle = quote.responses.vehicle || {}
    quote.responses.vehicle.make = risk?.make || ''
    quote.responses.vehicle.model = risk?.model || ''

    // set the brand
    quote.policy.brand = (resp as any)?.quote?.brand || 'Zixty'

    // mock the fields for the selected quote
    quote.responses.fullquote = {
      quotes: {
        ...(quote.responses.fullquote || {}),
        [quote.policy.brand]: {
          Brand: quote.policy.brand,
          PremiumInclFee: `${resp?.quote?.premium || '0'}`,
          InceptDateTime: riskCoverStart.toISOString(),
          ExpiryDateTime: riskCoverEnd.toISOString(),
          PolicyExcess: `${resp?.quote?.excess || '0'}`,
          AddOns: {
            //
          }
        }
      } as FullQuoteResponse['quotes']
    }

    // add the enumerations and the quick quote
    quote.responses.quickquote = {
      enumerations: resp.enumerations
    }

    // update the aggregator response
    quote.responses.aggregator = {
      ...resp,
      partner: resp.partner
    }

    // we seem to have two copies, should this be copied in for aggregators?
    quote.responses.partner = resp.partner

    // track event
    quote.helpers.recordWebEvent('LOADING_QUOTE')

    // ensure we have been on this page for a few seconds
    const secondsSinceStart = datelocalised().unix() - startTime.value
    const secondsToShowThisPage = 0
    if (secondsSinceStart < secondsToShowThisPage) {
      await delay((secondsToShowThisPage - secondsSinceStart) * 1000)
    }

    // determine the next screen
    let nextScreen
    if (
      quote.responses.aggregator?.single_char_first_name === true ||
      quote.responses.aggregator?.first_name_includes_title === true ||
      quote.responses.aggregator?.last_name_includes_title === true ||
      (quote.responses.aggregator?.postcode_lookup?.addresses &&
        !quote.responses.aggregator?.risk?.address)
    ) {
      // send the user to the driver details page
      nextScreen = {
        name: 'quote.driver-details',
        params: {
          journey: 'aggregator'
        }
      }
    } else {
      // send the user to the quote page
      nextScreen = {
        name: 'quote.quote',
        params: {
          journey: 'aggregator'
        }
      }
    }

    // redirect
    $router.push(nextScreen)
  } catch (error) {
    errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined
  }

  inAjaxCall.value = false
}

const getAggregatorCode = computed((): string => {
  let code = ''

  if (quote?.responses?.aggregator?.partner?.code) {
    code = quote.responses.aggregator.partner?.code
  }

  if (!code) {
    switch ($route.params?.aggregator) {
      case 'COMC':
        code = 'gocompare'
        break
      default:
        break
    }
  }

  return code
})

const getAggregatorLogoSrc = computed((): string => {
  let logo = ''

  if (quote?.responses?.aggregator?.partner?.webquote_default_logo_path) {
    logo = quote.responses.aggregator.partner?.webquote_default_logo_path
  }

  // try to find the hardcoded one
  if (!logo) {
    switch ($route.params?.aggregator) {
      case 'COMC':
        logo =
          '/images/static/partner/1F541855459F0E17CB631B1558D0736438060B7CD2F716BC55571588F625C9D4.png'
        break
      default:
        break
    }
  }

  return logo
})

onMounted(async () => {
  await onStart()
})
</script>

<template>
  <div id="content" class="h-full flex px-3 py-3 md:px-6 md:py-6">
    <div class="w-full mx-auto">
      <div class="w-full mx-auto pt-6 md:pt-12 md:max-w-md md:-mb-5">
        <div class="py-3 md:py-6 justify-center text-center" v-if="!!getAggregatorLogoSrc">
          <h2
            class="text-[28px] text-[#595959] font-medium flex flex-col justify-center items-center"
          >
            <span>Transferring you from</span>
          </h2>
          <div class="mt-6">
            <Image :src="getAggregatorLogoSrc" class="w-[10rem] mx-auto" />
          </div>
        </div>
        <div class="py-3 md:py-6 text-center">
          <h2
            class="text-[28px] text-[#595959] font-medium flex flex-col justify-center items-center"
          >
            <span>We’re just</span>
            <LoadingText text="getting your quote" :dots="3"></LoadingText>
          </h2>
        </div>
        <div v-if="benefits && !!benefits.length" class="my-6 w-64 mx-auto text-[#595959]">
          <div v-for="benefit in benefits" class="my-0.5">
            <span>
              <i class="fa-solid fa-check text-[#95C11F]"></i>
            </span>
            <span class="ml-3">
              {{ benefit }}
            </span>
          </div>
        </div>
        <FormServerErrors v-if="errorsServer" :error="errorsServer" />
        <div class="w-full">
          <form
            id="form-aggregator"
            novalidate
            :class="{
              'form23a-loading': inAjaxCall
            }"
          ></form>
        </div>
      </div>
    </div>
  </div>
</template>
