<script setup lang="ts">
import { computed, onMounted, ref, toRef } from 'vue'
import { datelocalised } from '@/utils/date'
import { ConfirmAndBuySchema, type ConfirmAndBuySchemaErrorsType } from '@/stores/quote.schema'
import { useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useQuoteStore } from '@/stores/quote'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import QuoteFormContinueButton from '@/components/Quote/QuoteFormContinueButton.vue'
import BackButton from '@/components/Quote/BackButton.vue'
import QuoteFormFieldErrors from '@/components/Quote/QuoteFormFieldErrors.vue'
import Tooltip from '@/components/Tooltip.vue'
import DetailLineItem from '@/components/Quote/DetailLineItem.vue'
import DetailLineCurrencyValue from '@/components/Quote/DetailLineCurrencyValue.vue'
import DetailPriceBreakdownModal, {
  type PropsCurrency
} from '@/components/Quote/DetailPriceBreakdownModal.vue'
import LoadingText from '@/components/LoadingText.vue'
import FormSpinner from '@/components/Quote/FormSpinner.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'
import { buyPolicy, isZixtyAxiosErrorCheck, type ApiErrorResponse } from '@/utils/api'
import LinkExternal from '@/components/LinkExternal.vue'
import EffectiveExcessDetailLine from '@/components/Quote/EffectiveExcessDetailLine.vue'

const $router = useRouter()

useHead({
  title: 'Confirm and Buy - Zixty Quote'
})

const quote = useQuoteStore()

const policy = toRef(quote, 'policy')

const inAjaxCall = ref<boolean>(false)

const isConfirmationEmailFieldBlank = ref<boolean>(true)

const errorsClient = ref<ConfirmAndBuySchemaErrorsType | undefined>()
const errorsServer = ref<ApiErrorResponse | undefined>()

const addOnKeysIncludingMandatoryArray = computed(() =>
  Object.keys(policy.value.selectedAddOnsIncludingMandatory)
)

const GBP = ref<PropsCurrency>('GBP')

const pageCopy: Record<string, Record<string, string>> = {
  PE250: {
    title: 'Excess Protect',
    modalTitle: 'Details for your Excess protection',
    modalSubtitle: 'These charges relate to your Excess protection cover.'
  },
  BDC: {
    title: 'Breakdown cover',
    modalTitle: 'Details for your Breakdown cover',
    modalSubtitle: 'These charges relate to your Breakdown cover.'
  }
}

const breakdownTotal = computed(() => {
  let total = {
    PremiumLessIPT: Number(quote?.policy?.selectedBrand?.PremiumLessIPTWithMandatoryAddOns || 0),
    IPT: Number(quote?.policy?.selectedBrand?.IPTWithMandatoryAddOns || 0),
    Fee: Number(quote?.policy?.selectedBrand?.FeeWithMandatoryAddOns || 0)
  }

  return addOnKeysIncludingMandatoryArray.value
    .map((addOnKey) => policy.value.selectedAddOnsIncludingMandatory[addOnKey])
    .filter((addOn) => !addOn.Mandatory)
    .reduce((acc, item) => {
      return {
        PremiumLessIPT: Number(acc.PremiumLessIPT || 0) + Number(item?.PremiumLessIPT || 0),
        IPT: acc.IPT + Number(item?.IPT || 0),
        Fee: acc.Fee + Number(item?.Fee || 0)
      }
    }, total)
})

const onSubmit = async (e: Event) => {
  if (quote.helpers.hasQuoteExpired()) {
    return $router.push({
      name: 'quote.time'
    })
  }

  if (!!inAjaxCall.value) return
  inAjaxCall.value = true

  const safeParsed = ConfirmAndBuySchema.safeParse({ ...quote.inputs })

  errorsClient.value = safeParsed.success ? undefined : safeParsed.error.flatten()
  errorsServer.value = undefined

  isConfirmationEmailFieldBlank.value = quote?.inputs?.email_address_confirmation === ''

  if (safeParsed.success) {
    try {
      const response = await buyPolicy({
        correlation_id: quote.responses?.correlation_id || '',
        brand: policy.value.selectedBrand?.Brand || 'Zixty',
        add_on_codes: addOnKeysIncludingMandatoryArray.value,
        total_billable_amount: policy.value.total.toFixed(2),
        email_address: quote.inputs?.email_address || '',
        receive_marketing: quote.inputs?.receive_marketing || false,
        quote_id: quote.policy?.selectedBrand?.QuoteID!
      })

      quote.responses.quote_expires_at = response?.headers['x-expiry-date'] || ''

      const data = response.data

      quote.responses.buy = data

      $router.push({
        name: 'quote.payment'
      })
    } catch (error) {
      errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined
    }
  } else {
    // reset email address if the email address confirmaiton is incorrect
    if (
      !isConfirmationEmailFieldBlank.value &&
      !!errorsClient.value?.fieldErrors?.email_address_confirmation &&
      !!errorsClient.value?.fieldErrors?.email_address_confirmation?.length
    ) {
      quote.inputs.email_address = ''
    }
  }

  inAjaxCall.value = false
}

onMounted(() => {
  quote.helpers.recordWebEvent('CONFIRM_AND_BUY')
})
</script>

<template>
  <div class="w-full">
    <div class="pt-6 mx-auto lg:w-6/12 md:max-w-sm">
      <div class="py-3 md:py-6 flex justify-center text-center">
        <QuoteFormTitle> Confirm and buy </QuoteFormTitle>
      </div>
      <FormServerErrors v-if="errorsServer" :error="errorsServer" />
      <div>
        <form
          id="form-quote-confirm-and-buy"
          class="mx-auto md:max-w-[360px]"
          :class="{
            'form23a-loading': inAjaxCall
          }"
          @submit.prevent="onSubmit"
          novalidate
        >
          <FormSpinner v-if="inAjaxCall" />
          <div class="py-3 w-full">
            <div class="w-full md:max-w-md mx-auto">
              <DetailLineItem
                :track-hotjar="true"
                title="Registration"
                :value="quote.inputs?.registration_number_ui"
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="Make"
                :value="`${quote?.responses?.vehicle?.make || 'Unknown Make'}`"
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="Model"
                :value="`${quote?.responses?.vehicle?.model || 'Unknown Model'}`"
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="false"
                title="Driver"
                :value="quote.inputs?.first_name + ' ' + quote.inputs?.last_name"
              ></DetailLineItem>
            </div>
          </div>
          <div class="py-3 w-full flex flex-col justify-center md:justify-start">
            <div class="w-full md:max-w-md mx-auto">
              <DetailLineItem
                :track-hotjar="true"
                title="Duration"
                :value="
                  quote.inputs?.selected_duration +
                  ' ' +
                  (quote.inputs?.selected_duration === 1
                    ? quote.inputs?.selected_duration_unit?.replace(/s$/, '')
                    : quote.inputs?.selected_duration_unit)
                "
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="Start date"
                :value="
                  datelocalised(quote?.policy?.selectedBrand?.InceptDateTime).format(
                    'DD/MM/YYYY HH:mm'
                  )
                "
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="End date"
                :value="
                  datelocalised(quote?.policy?.selectedBrand?.ExpiryDateTime).format(
                    'DD/MM/YYYY HH:mm'
                  )
                "
              ></DetailLineItem>

              <DetailLineItem
                :track-hotjar="true"
                title="Product"
                :value="quote?.policy.brand === 'Zixtyplus' ? 'Zixty Plus' : 'Zixty'"
              ></DetailLineItem>
            </div>
          </div>
          <div class="py-3 w-full flex flex-col justify-center md:justify-start">
            <div class="w-full md:max-w-md mx-auto">
              <EffectiveExcessDetailLine />

              <DetailLineItem
                :track-hotjar="true"
                title="Insurer"
                :value="quote?.policy?.selectedBrand?.Insurer"
              ></DetailLineItem>
            </div>
          </div>
          <div class="py-3 w-full flex flex-col justify-center md:justify-start">
            <div class="w-full md:max-w-md mx-auto">
              <div class="flex flex-wrap justify-between w-full py-1 items-start">
                <span class="w-full text-[#595959] text-xs md:text-sm font-normal px-2">
                  Charges
                </span>
              </div>

              <DetailLineCurrencyValue
                :track-hotjar="true"
                title="Motor policy"
                :value="
                  Number(quote?.policy?.selectedBrand?.PremiumInclFeeWithMandatoryAddOns || 0)
                "
                :currency="quote.policy.currency"
                :minimum-fraction-digits="2"
              >
                <template v-slot:action>
                  <DetailPriceBreakdownModal
                    v-if="
                      quote?.policy?.selectedBrand?.PremiumInclFeeWithMandatoryAddOns !==
                      quote?.policy?.selectedBrand?.PremiumInclFee
                    "
                    title="Details for your Motor policy"
                    description="These charges relate to your motor policy and any add-ons included as standard."
                    :items="[
                      {
                        label: 'Premium excluding IPT',
                        value: Number(
                          quote?.policy?.selectedBrand?.PremiumLessIPTWithMandatoryAddOns || 0
                        ),
                        currency: GBP
                      },
                      {
                        label: 'IPT',
                        value: Number(quote?.policy?.selectedBrand?.IPTWithMandatoryAddOns || 0),
                        currency: GBP
                      },
                      {
                        label: 'Fee',
                        value: Number(quote?.policy?.selectedBrand?.FeeWithMandatoryAddOns || 0),
                        currency: GBP
                      }
                    ]"
                    :total="{
                      label: 'Total',
                      value: Number(
                        quote?.policy?.selectedBrand?.PremiumInclFeeWithMandatoryAddOns || 0
                      ),
                      currency: GBP
                    }"
                  />

                  <DetailPriceBreakdownModal
                    v-else
                    title="Details for your Motor policy"
                    description="These charges relate to your motor policy."
                    :items="[
                      {
                        label: 'Premium excluding IPT',
                        value: Number(quote?.policy?.selectedBrand?.PremiumLessIPT || 0),
                        currency: GBP
                      },
                      {
                        label: 'IPT',
                        value: Number(quote?.policy?.selectedBrand?.IPT || 0),
                        currency: GBP
                      },
                      {
                        label: 'Fee',
                        value: Number(quote?.policy?.selectedBrand?.Fee || 0),
                        currency: GBP
                      }
                    ]"
                    :total="{
                      label: 'Total',
                      value: Number(quote?.policy?.selectedBrand?.PremiumInclFee || 0),
                      currency: GBP
                    }"
                  />
                </template>
              </DetailLineCurrencyValue>

              <div
                v-if="
                  !!policy.selectedAddOnsIncludingMandatory &&
                  !!addOnKeysIncludingMandatoryArray.length
                "
                v-for="(addOn, addOnKey) in policy.selectedAddOnsIncludingMandatory"
                :class="{
                  hidden: !!addOn.Mandatory
                }"
              >
                <DetailLineCurrencyValue
                  :track-hotjar="true"
                  v-if="!!addOn"
                  :key="addOn.Name"
                  :title="pageCopy[addOnKey]?.title || addOn.Name"
                  :value="Number(addOn.PremiumInclFee || 0)"
                  :currency="quote.policy.currency"
                  :minimum-fraction-digits="2"
                >
                  <template v-slot:action>
                    <DetailPriceBreakdownModal
                      :title="pageCopy[addOnKey]?.modalTitle || `Details for ${addOn.Name}`"
                      :description="pageCopy[addOnKey]?.modalSubtitle || addOn.Description"
                      :items="[
                        {
                          label: 'Premium excluding IPT',
                          value: Number(addOn.PremiumLessIPT || 0),
                          currency: GBP
                        },
                        {
                          label: 'IPT',
                          value: Number(addOn.IPT || 0),
                          currency: GBP
                        },
                        {
                          label: 'Fee',
                          value: Number(addOn.Fee || 0),
                          currency: GBP
                        }
                      ]"
                      :total="{
                        label: 'Total',
                        value: Number(addOn.PremiumInclFee || 0),
                        currency: GBP
                      }"
                    />
                  </template>
                </DetailLineCurrencyValue>
              </div>

              <DetailLineCurrencyValue
                :track-hotjar="true"
                title="Total inc IPT and fees"
                :value="Number(policy.total || 0)"
                :currency="quote.policy.currency"
                :minimum-fraction-digits="2"
              >
                <template v-slot:action>
                  <DetailPriceBreakdownModal
                    title="Your charges explained"
                    description="These are your total charges for your motor policy and any selected add-on(s)."
                    :items="[
                      {
                        label: 'Premium excluding IPT',
                        value: Number(breakdownTotal.PremiumLessIPT || 0),
                        currency: GBP
                      },
                      {
                        label: 'IPT',
                        value: Number(breakdownTotal.IPT || 0),
                        currency: GBP
                      },
                      {
                        label: 'Fee(s)',
                        value: Number(breakdownTotal.Fee || 0),
                        currency: GBP
                      }
                    ]"
                    :total="{
                      label: 'Total',
                      value: Number(quote.policy?.total || 0),
                      currency: GBP
                    }"
                  />
                </template>
              </DetailLineCurrencyValue>
            </div>
          </div>
          <div class="py-3 pt-6 w-full">
            <div
              class="form23a-field-group"
              :class="{
                error: !!errorsClient?.fieldErrors?.email_address_confirmation
              }"
            >
              <label class="form23a-label flex" for="email_address_confirmation">
                Confirm your email address
                <span class="mx-0.5"></span>
                <Tooltip
                  content="Please use your real email address, as we need this in order to send you important information."
                />
              </label>
              <div>
                <input
                  id="form-quote-confirm-and-buy--field-email-address-confirmation"
                  type="email"
                  name="email_address_confirmation"
                  placeholder="Confirm your email address..."
                  class="form23a-input w-full"
                  v-model="quote.inputs.email_address_confirmation"
                />
              </div>
              <div
                class="mt-3"
                v-show="
                  !isConfirmationEmailFieldBlank &&
                  !!errorsClient?.fieldErrors?.email_address_confirmation
                "
              >
                <label for="email_address" class="sr-only">
                  Re-enter this email address please
                </label>
                <input
                  id="form-quote-confirm-and-buy--field-email-address"
                  type="email"
                  name="email_address"
                  placeholder="Re-enter your email address..."
                  class="form23a-input w-full"
                  v-model="quote.inputs.email_address"
                />
              </div>
              <div class="my-3 text-xs text-[#3F3F46]">
                <p>
                  This is the email address you will use to access all of your policy documents.
                </p>
              </div>
              <QuoteFormFieldErrors
                :errors="errorsClient?.fieldErrors?.email_address_confirmation"
              />
            </div>
          </div>
          <div class="w-full">
            <div class="mb-3 rounded-lg">
              <label class="form23a-label flex items-center">
                <span
                  id="form-quote-your-details--field-receive-marketing"
                  class="data-hj-allow text-sm"
                  :class="{
                    'text-red-500': !!errorsClient?.fieldErrors?.receive_marketing
                  }"
                >
                  <span class="text-sm">
                    Be the first to receive exclusive offers, tips, and news via email. We don’t
                    sell your data and won’t spam you. Opt out any time. Check out our
                    <LinkExternal href="https://www.zixty.com/privacy-policy/">
                      Privacy Policy.
                    </LinkExternal>
                  </span>
                  <span class="flex items-center -ml-1 mt-3">
                    <button
                      type="button"
                      class="rounded-full mx-1 my-1.5 border-2 w-12 h-12 text-xs"
                      :class="{
                        'border-red-500': !!errorsClient?.fieldErrors?.receive_marketing,
                        'bg-[#95C11F] border-[#95C11F] text-white':
                          quote.inputs.receive_marketing === true
                      }"
                      @click.prevent="quote.inputs.receive_marketing = true"
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      class="rounded-full mx-1 my-1.5 border-2 w-12 h-12 text-xs"
                      :class="{
                        'border-red-500': !!errorsClient?.fieldErrors?.receive_marketing,
                        'bg-[#95C11F] border-[#95C11F] text-white':
                          quote.inputs.receive_marketing === false
                      }"
                      @click.prevent="quote.inputs.receive_marketing = false"
                    >
                      No
                    </button>
                  </span>
                </span>
              </label>
            </div>
          </div>
          <div class="py-3 md:py-6 w-full flex justify-center">
            <QuoteFormContinueButton
              type="submit"
              :deactivate="inAjaxCall"
              id="submit-go-to-pay-button"
            >
              <span v-if="inAjaxCall" class="flex items-center">
                <LoadingText text="Processing" :dots="3" />
              </span>
              <span v-else>Buy now</span>
            </QuoteFormContinueButton>
          </div>
          <BackButton route-name="quote.declarations" />
          <div class="py-6 w-full">
            <div class="w-full max-w-md mx-auto">
              <Image src="/images/static/payment-trust-logos.png" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
