<script setup lang="ts">
import { ref } from 'vue'
// import { isWebpSupported } from '@/utils/helpers'

export interface Props {
  src: string
  alt?: string
}

const props = withDefaults(defineProps<Props>(), {
  //
})

const getAltTagFromImageSrc = (src: string): string => {
  if (src === 'logo.png') {
    return 'zixty logo'
  }

  let parts = (src || '').split('/')
  let part = parts.pop() || ''
  if (!!part) {
    part = part.replace(/\.[a-z]{0,4}$/i, '')
    part = part.replace(/-|_/g, ' ')
  }
  return part
}

const isReady = ref<boolean>(true)
const imgSrc = ref<string>(props.src || '')
const imgAlt = ref<string>(props.alt || getAltTagFromImageSrc(imgSrc.value))

// isWebpSupported('lossless')
//   .then((res) => {
//     if (res.result) {
//       imgSrc.value = (props.src || '').slice().replace(/\.png$/, '.webp')
//     }
//     return res
//   })
//   .then((res) => {
//     isReady.value = true
//   })
//   .catch((err) => {
//     isReady.value = true
//   })
</script>

<template>
  <img v-if="isReady" :src="imgSrc" :alt="imgAlt" />
</template>
