<script setup lang="ts">
export interface Props {
  routeName?: string
  onClick?: () => void
}

const props = withDefaults(defineProps<Props>(), {
  //
})
</script>

<template>
  <div class="w-full flex justify-center">
    <a
      href="#"
      class="text-[#999797] hover:text-[#95C11F] py-3 flex items-center"
      @click.prevent.stop="(e: Event) => {
        if (!!props?.routeName) {
          $router.push({
            name: props.routeName
          })
        } else if (!!props?.onClick) {
          props.onClick()
        } else {
          $router.back()
        }
      }"
    >
      <span class="text-xs mr-1">
        <i class="fa-solid fa-chevron-left" aria-hidden="true"></i>
      </span>
      <span>Back</span></a
    >
  </div>
</template>
