import { createRouter, createWebHistory } from 'vue-router'
import type { RouteLocation } from 'vue-router'
import { QuoteRoutes } from '@/router/quotes'
import { PartnerRoutes } from '@/router/partner'
import { AggregatorRoutes } from '@/router/aggregator'
import { PolicyDocumentRoutes } from '@/router/policydocuments'
import NotFoundView from '@/views/NotFoundView.vue'
import RetrieveQuoteView from '@/views/RetrieveQuoteView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return {
      top: -1,
      behavior: 'smooth'
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: (to: RouteLocation) => {
        // redirect to the partner page if we have it with query params
        if (!!to.query && !!to.query.partner) {
          return {
            path: `/partner/${to.query.partner}`,
            query: to.query
              ? {
                  ...to.query,
                  partner: undefined
                }
              : {}
          }
        }

        // send to the quote page with query params
        return {
          path: '/quote/direct/vehicle',
          query: to.query ? { ...to.query } : {}
        }
      }
    },
    {
      path: '/retrieve-quote/:id',
      name: 'retrieve-quote',
      component: RetrieveQuoteView
    },
    {
      path: '/:pathMatch(.*)',
      name: '404',
      component: NotFoundView
    },
    ...QuoteRoutes,
    ...PartnerRoutes,
    ...AggregatorRoutes,
    ...PolicyDocumentRoutes
  ]
})

export default router
