<script setup lang="ts">
import { computed, defineAsyncComponent, ref, toRef } from 'vue'
import { useRouter } from 'vue-router'
import { useQuoteStore } from '@/stores/quote'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import CurrencyValue from '@/components/Quote/CurrencyValue.vue'
import LoadingText from '@/components/LoadingText.vue'

const $router = useRouter()

const quote = useQuoteStore()

const buy = toRef(quote.responses, 'buy')

const isStripeReady = ref<boolean>(false)

const returnUrl = computed(() => {
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    $router.resolve({
      name: 'quote.processing',
      query: {
        correlation_id: !!quote?.responses?.correlation_id ? quote.responses.correlation_id : '',
        partner: quote?.responses?.partner?.name || 'direct',
        brand: quote?.policy?.brand || 'Zixty'
      }
    }).fullPath
  )
})

const StripePayment = defineAsyncComponent({
  loader: () => import('@/components/Quote/StripePayment.vue'),
  // loadingComponent: LoadingSpinner,
  delay: 0,
  // errorComponent: ErrorComponent,
  timeout: 15000
})
</script>

<template>
  <div class="w-full">
    <div class="pt-3 mx-auto lg:w-5/12 md:max-w-sm">
      <div class="py-3 md:py-6 flex justify-center items-center text-center">
        <QuoteFormTitle>
          <div>Payment</div>
          <div class="text-lg my-3">
            <CurrencyValue
              :value="Number(buy?.total_billable_amount || 0)"
              :currency="quote.policy.currency"
            />
          </div>
        </QuoteFormTitle>
      </div>
      <div
        class="w-full"
        v-if="!!buy && !!buy.stripe && !!buy.stripe.publishable_key && !!buy.stripe.client_secret"
      >
        <StripePayment
          :publishable-key="buy.stripe.publishable_key"
          :client-secret="buy.stripe.client_secret"
          :return-url="returnUrl"
          :on-is-ready="(isReady: boolean) => {
            isStripeReady = isReady
          }"
        />
        <div class="py-3 w-full" v-show="!!isStripeReady">
          <div class="w-full max-w-md mx-auto">
            <Image src="/images/static/payment-trust-logos.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="text-white h-6">
      <LoadingText text=" " :dots="3" />
    </div>
  </div>
</template>
