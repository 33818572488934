<script setup lang="ts">
import { reactive, ref } from 'vue'
import { z } from 'zod'
import { useHead } from '@vueuse/head'
import { datelocalised } from '@/utils/date'
import {
  isZixtyAxiosErrorCheck,
  getPolicyDocuments,
  type ApiErrorResponse,
  type PolicyDocumentsResponse,
  type PolicyDocumentItemResponse
} from '@/utils/api'
import { REGEX_PERSON_NAME, REGEX_UK_POSTCODE, REGEX_UK_VEHICLE_REGISTRATION } from '@/constants'
import { delay } from '@/utils/helpers'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import QuoteFormContinueButton from '@/components/Quote/QuoteFormContinueButton.vue'
import QuoteFormFieldErrors from '@/components/Quote/QuoteFormFieldErrors.vue'
import FormSpinner from '@/components/Quote/FormSpinner.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'
import DetailLineItem from '@/components/Quote/DetailLineItem.vue'
import IconDownload from '@/components/icons/IconDownload.vue'

// schema
const PolicyDocumentsSchema = z.object({
  policy_number: z.string().min(1),
  registration_number: z
    .string()
    .regex(REGEX_UK_VEHICLE_REGISTRATION, `Please enter a valid UK vehicle registration number`),
  last_name: z.string().regex(REGEX_PERSON_NAME, `Please check the driver's last name`),
  postcode: z.string().regex(REGEX_UK_POSTCODE, `Please pick a valid postcode`)
})

type PolicyDocumentsSchemaType = z.infer<typeof PolicyDocumentsSchema>

type PolicyDocumentsSchemaErrorsType = z.inferFlattenedErrors<typeof PolicyDocumentsSchema>

// hooks
useHead({
  title: 'Policy Documents - Zixty Quote'
})

// vars
const expiredLinkErrorMessage = ref<string>(
  `This link has expired. Please log in again to view this document.`
)

const documentsByUrl = ref<Record<string, PolicyDocumentItemResponse>>({
  //
})

const documentsByUrlClicks = ref<Record<string, number>>({
  //
})

const expiredLinks = ref<Record<string, boolean>>({
  //
})

const results: Partial<PolicyDocumentsResponse> = reactive({
  //
})

const fields: Partial<PolicyDocumentsSchemaType> = reactive({
  //
})

const inAjaxCall = ref<boolean>(false)

const errorsClient = ref<PolicyDocumentsSchemaErrorsType | undefined>()
const errorsServer = ref<ApiErrorResponse | undefined>()

const lookupDownloadLinkByName = (
  type: 'policy' | 'add_ons',
  policyDocument: PolicyDocumentItemResponse,
  addOnKey?: string
) => {
  // get the place to look-in
  let documentSelected
  let documentsAll = results.documents || {}

  if (type === 'policy') {
    documentSelected = (documentsAll[type] || [])
      ?.filter((item) => item.name === policyDocument.name)
      .pop()
  } else if (type === 'add_ons' && !!addOnKey) {
    documentSelected = (documentsAll[type] || {})[addOnKey]
      ?.filter((item) => item.name === policyDocument.name)
      .pop()
  }

  return documentSelected
}

const download = async (
  type: 'policy' | 'add_ons',
  policyDocument: PolicyDocumentItemResponse,
  addOnKey?: string,
  tries: number = 0
) => {
  // localise
  const url = policyDocument.download.url

  // send the user back to the form if the tries exceeds 1
  if (tries > 1) {
    return onSubmitLogout()
  }

  // increment the count
  documentsByUrlClicks.value[url] = (documentsByUrlClicks.value[url] || 0) + 1

  // check if the link has expired
  let hasLinkExpired =
    datelocalised().unix() > datelocalised(documentsByUrl.value[url].download.expiry).unix() ||
    documentsByUrlClicks.value[url] > 3

  // update expired val
  expiredLinks.value[url] = hasLinkExpired

  // refetch
  if (!!hasLinkExpired) {
    // refetch
    await onSubmit()

    // wait for a bit
    await delay(100)

    // lookup the new download link
    const documentSelected = lookupDownloadLinkByName(type, policyDocument, addOnKey)
    if (!!documentSelected) {
      download(type, documentSelected, addOnKey, tries + 1)
    }
  } else {
    // open the document
    try {
      // create a download
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = url
      link.download = policyDocument.download.file_name
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    } catch (error) {
      //
    }
  }
}

const stripSpaces = (str: string) => (str || '').replace(/\s/g, '')

const generateKvOfDocumentByUrl = (documents: PolicyDocumentsResponse['documents']) => {
  let resp: Record<string, PolicyDocumentItemResponse> = {}

  // do the policy
  ;(documents.policy || []).forEach((document) => {
    resp[document.download.url] = document
  })

  // do the add-ons
  ;(<any>Object).values(documents.add_ons || {}).forEach((addOn: PolicyDocumentItemResponse[]) => {
    addOn.forEach((document) => {
      resp[document.download.url] = document
    })
  })

  return resp
}

const scrollToTop = async () => {
  await delay(25)

  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

const onSubmit = async (e?: Event) => {
  // block
  if (!!inAjaxCall.value) return
  inAjaxCall.value = true

  // reset the vars
  expiredLinks.value = {}
  documentsByUrlClicks.value = {}
  results.policy_number = undefined
  results.policy_start = undefined
  results.policy_end = undefined
  results.documents = undefined

  // validate
  const safeParsed = PolicyDocumentsSchema.safeParse({ ...fields })

  errorsClient.value = safeParsed.success ? undefined : safeParsed.error.flatten()
  errorsServer.value = undefined

  // call api or show error
  if (safeParsed.success) {
    try {
      // call the api
      const response = await getPolicyDocuments({
        policy_number: stripSpaces(safeParsed.data.policy_number).toUpperCase(),
        registration_number: stripSpaces(safeParsed.data.registration_number).toUpperCase(),
        last_name: safeParsed.data.last_name,
        postcode: stripSpaces(safeParsed.data.postcode).toUpperCase()
      })

      // handle the response
      if (response && response.data) {
        // update the local data
        results.brand = response.data.brand
        results.policy_number = response.data.policy_number
        results.policy_start = response.data.policy_start
        results.policy_end = response.data.policy_end
        results.documents = response.data.documents

        documentsByUrl.value = generateKvOfDocumentByUrl(results.documents)

        // scroll to the top of the page
        scrollToTop()
      }
    } catch (error) {
      errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined
    }
  }

  inAjaxCall.value = false
}

const onSubmitLogout = async (e?: Event) => {
  inAjaxCall.value = true

  await delay(100)

  await scrollToTop()

  inAjaxCall.value = false
  window.location.reload()
}
</script>

<template>
  <div class="w-full">
    <div v-if="!!results.policy_number">
      <div class="pt-6 md:pt-12 mx-auto">
        <div class="py-3 md:py-6 flex justify-center items-center text-center">
          <QuoteFormTitle> Your documents </QuoteFormTitle>
        </div>
        <div class="py-3">
          <div class="py-3 flex justify-center text-center">
            <div class="mx-auto">
              <h3 class="font-semibold text-[#595959]">Your Zixty policy documents</h3>
            </div>
          </div>
          <div class="w-full flex flex-col justify-center md:justify-start">
            <div class="w-full md:max-w-xs mx-auto">
              <DetailLineItem title="Policy number" :value="results.policy_number" />
              <DetailLineItem
                title="Policy start"
                :value="datelocalised(results.policy_start).format('DD/MM/YYYY HH:mm')"
              />
              <DetailLineItem
                title="Policy end"
                :value="datelocalised(results.policy_end).format('DD/MM/YYYY HH:mm')"
              />
              <DetailLineItem
                title="Product"
                :value="results.brand === 'Zixtyplus' ? 'Zixty Plus' : 'Zixty'"
              />
            </div>
          </div>
        </div>
        <div class="py-3">
          <div class="py-3 w-full flex flex-col justify-center md:justify-start">
            <div class="mx-auto pb-1.5">
              <h3 class="font-semibold text-[#595959] text-center">Your Motor policy documents</h3>
            </div>
            <div
              class="mx-auto"
              v-if="
                !!results.documents &&
                !!results.documents.policy &&
                !!results.documents.policy.length
              "
            >
              <div
                v-for="doc in results.documents.policy"
                :key="doc.download.url"
                class="w-full mx-auto flex flex-wrap items-end my-3 text-sm"
                @click="download('policy', doc)"
                :class="{
                  'text-[#a5a5a5]': !!expiredLinks[doc.download.url],
                  'text-[#95C11F] hover:opacity-80 cursor-pointer': !expiredLinks[doc.download.url]
                }"
              >
                <IconDownload />
                <span class="ml-1">
                  {{ expiredLinks[doc.download.url] ? expiredLinkErrorMessage : doc.name }}
                </span>
              </div>
            </div>
            <div v-else>Unable to load documents, please try again or contact support.</div>
          </div>
        </div>
        <div class="my-3">
          <div
            class="py-3 pb-6 w-full flex flex-col justify-center md:justify-start"
            v-for="(docs, key) in results.documents?.add_ons"
            :key="key"
          >
            <div class="mx-auto pb-1.5">
              <h3 class="font-semibold text-[#595959] text-center">
                <span>Your </span>
                <span v-if="key === 'BDC' || key === 'BR'">Breakdown</span>
                <span v-if="key === 'PE250' || key === 'PE'">Excess Protect</span>
                <span v-if="key === 'LC'">Legal Expenses</span>
                <span> documents</span>
              </h3>
            </div>
            <div class="mx-auto" v-if="!!docs && !!docs.length">
              <div
                v-for="doc in docs"
                :key="doc.download.url"
                class="w-full mx-auto flex flex-wrap items-end my-3 text-sm"
                @click="download('add_ons', doc, key)"
                :class="{
                  'text-[#a5a5a5]': !!expiredLinks[doc.download.url],
                  'text-[#95C11F] hover:opacity-80 cursor-pointer': !expiredLinks[doc.download.url]
                }"
              >
                <IconDownload />
                <span class="ml-1">
                  {{ expiredLinks[doc.download.url] ? expiredLinkErrorMessage : doc.name }}
                </span>
              </div>
            </div>
            <div v-else>Unable to load documents, please try again or contact support.</div>
          </div>
        </div>
        <div class="mx-auto">
          <form
            id="form-my-documents-logout"
            @submit.prevent="onSubmitLogout"
            novalidate
            :class="{
              'form23a-loading': inAjaxCall
            }"
          >
            <div class="py-3 md:py-6 w-full flex justify-center">
              <QuoteFormContinueButton
                type="submit"
                :deactivate="inAjaxCall"
                id="form-my-documents-logout-submit-button"
              >
                Done
              </QuoteFormContinueButton>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else class="pt-6 mx-auto lg:w-5/12 md:max-w-sm">
      <div class="py-3 md:py-6 flex justify-center items-center text-center">
        <QuoteFormTitle>Get your documents </QuoteFormTitle>
      </div>
      <div class="py-3 md:py-6 flex justify-center text-center text-[#595959]">
        <p>Enter your information to retrieve your documents.</p>
      </div>
      <FormServerErrors v-if="errorsServer" :error="errorsServer" />
      <div class="w-full">
        <form
          id="form-my-documents"
          @submit.prevent="onSubmit"
          novalidate
          :class="{
            'form23a-loading': inAjaxCall
          }"
        >
          <FormSpinner v-if="inAjaxCall" />
          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.policy_number
            }"
            id="field-group-policy-number"
          >
            <label class="form23a-label" for="policy_number"> Policy number </label>
            <div class="w-full">
              <input
                type="text"
                name="policy_number"
                id="form-my-documents--field-policy-number"
                placeholder="Enter your policy number..."
                class="form23a-input w-full uppercase"
                v-model.trim="fields.policy_number"
                v-capitalize-input
              />
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.policy_number" />
          </div>

          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.registration_number
            }"
            id="field-group-registration-number"
          >
            <label class="form23a-label" for="registration_number"> Vehicle registration </label>
            <div class="w-full">
              <input
                type="text"
                name="registration_number"
                id="form-my-documents--field-registration-number"
                placeholder="Enter vehicle registration rumber..."
                class="form23a-input w-full uppercase"
                v-model.trim="fields.registration_number"
                v-capitalize-input
              />
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.registration_number" />
          </div>

          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.last_name
            }"
            id="field-group-last-name"
          >
            <label class="form23a-label" for="last_name"> Driver's surname </label>
            <div class="w-full">
              <input
                type="text"
                name="last_name"
                id="form-my-documents--field-last-name"
                placeholder="Enter driver’s surname..."
                class="form23a-input w-full"
                v-model.trim="fields.last_name"
              />
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.last_name" />
          </div>

          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.postcode
            }"
            id="field-group-postcode"
          >
            <label class="form23a-label" for="postcode"> Driver’s postcode </label>
            <div class="w-full">
              <input
                type="text"
                name="postcode"
                id="form-my-documents--field-postcode"
                placeholder="Enter driver’s postcode..."
                class="form23a-input w-full uppercase"
                v-model.trim="fields.postcode"
                v-capitalize-input
              />
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.postcode" />
          </div>

          <div class="py-3 md:py-6 w-full flex justify-center">
            <QuoteFormContinueButton
              type="submit"
              :deactivate="inAjaxCall"
              id="form-my-documents-submit-button"
            >
              See documents
            </QuoteFormContinueButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
