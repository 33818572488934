<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useQuoteStore } from '@/stores/quote'
import type { ApiErrorResponse } from '@/utils/api-types'
import TimeViewForm from '@/components/Quote/TimeViewForm.vue'
import ProgressDots from '@/components/Quote/ProgressDots.vue'
import ToolTip from '@/components/Tooltip.vue'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'

const $route = useRoute()

const quote = useQuoteStore()

const isUpdateView = ref<boolean>(!!$route?.query?.update)

const historicalTimeError = ref<ApiErrorResponse | undefined>(
  quote.helpers.isCoverStartTimeHistorical() === true
    ? {
        code: '',
        message: '',
        what_to_do: '',
        what_to_say: `Please update the cover start time before continuing.`
      }
    : undefined
)

onMounted(() => {
  quote.helpers.recordWebEvent('DURATION')
})
</script>

<template>
  <div class="w-full">
    <div class="pt-6 mx-auto lg:w-5/12 md:max-w-sm">
      <div v-if="!isUpdateView">
        <div class="py-3 md:py-6 flex justify-center">
          <ProgressDots :total="3" :selected="1" />
        </div>
        <div class="py-3 md:py-6 flex justify-center items-center text-center">
          <QuoteFormTitle>How long for?</QuoteFormTitle>
          <span class="mx-0.5"></span>
          <ToolTip
            content="Please enter your start date and time, and your duration. These can be changed later."
            class="pt-1"
          />
        </div>
        <div class="py-3 md:py-6 flex justify-center text-center text-[#95C11F]">
          <span class="ml-1 font-bold">
            {{ quote?.responses?.vehicle?.make || 'Unknown Make' }}
            {{ quote?.responses?.vehicle?.model || 'Unknown Model' }}
          </span>
        </div>
      </div>

      <FormServerErrors v-if="historicalTimeError" :error="historicalTimeError" />

      <TimeViewForm
        :is-update-view="isUpdateView"
        :on-cancel="
          () => {
            $router.push({
              name: isUpdateView ? 'quote.quote' : 'quote.vehicle'
            })
          }
        "
        :on-success="
          () => {
            $router.push({
              name: isUpdateView ? 'quote.quote' : 'quote.driver-details'
            })
          }
        "
      />
    </div>
  </div>
</template>
