<script setup lang="ts">
import Modal from '@/components/Modal.vue'
import IconInformation from '@/components/icons/IconInformation.vue'
import CurrencyValue from '@/components/Quote/CurrencyValue.vue'

export type PropsCurrency = 'GBP'

export interface PropsSchema {
  title: string
  description: string
  items: Array<{
    label: string
    value: number
    currency: PropsCurrency
  }>
  total: {
    label: string
    value: number
    currency: PropsCurrency
  }
}

export interface Props extends PropsSchema {
  items: Array<{
    label: string
    value: number
    currency: PropsCurrency
    children?: PropsSchema
  }>
}

const props = withDefaults(defineProps<Props>(), {
  //
})

const display = (
  value: number,
  currency: string = 'GBP',
  locale: string = 'en',
  minimumFractionDigits: number = 2
) => {
  return Number(value).toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minimumFractionDigits
  })
}
</script>

<template>
  <Modal size="max-w-xl">
    <template v-slot:trigger>
      <span class="text-[#95C11F] cursor-pointer">
        <IconInformation class="h-5 w-5" />
      </span>
    </template>
    <template v-slot:title>
      <span class="text-xl" v-html="props.title"></span>
    </template>
    <template v-slot:content>
      <div class="text-sm font-normal text-[#595959]">
        <div class="mt-3">
          <span v-html="props.description"></span>
        </div>
        <div class="mt-3">
          <div class="flex items-center" v-for="item in props.items" :key="item.label">
            <div class="w-7/12 md:w-4/12">{{ item.label }}</div>
            <div class="w-4/12 md:w-6/12">
              <CurrencyValue :value="item.value" :currency="item.currency" />
            </div>
            <div v-if="!!item.children" class="w-1/12 md:2/12">
              <DetailPriceBreakdownModal v-bind="item.children" />
            </div>
          </div>
          <div class="flex items-center font-semibold">
            <div class="w-7/12 md:w-4/12">{{ total.label }}</div>
            <div class="w-4/12 md:w-6/12">
              <CurrencyValue :value="total.value" :currency="total.currency" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
