// import { useMemoize } from '@vueuse/core'
import { nextTick } from 'vue'
import type { RouteLocationNormalizedLoaded } from 'vue-router'
import Cookies from 'js-cookie'

export const getUtmParameters = ($route: RouteLocationNormalizedLoaded) => {
  // cookie get helper
  const get_cookie = (cookiename: string) => Cookies.get(cookiename)

  // what we'll send back
  let resp = {} as Record<string, string>

  // utm keys we are interested in
  const utmKeys = ['utm_source', 'utm_medium', 'utm_term', 'utm_content', 'utm_campaign']

  // look at the url query string parameters
  resp = utmKeys.reduce((acc, utm_param) => {
    if (!!utm_param && !!$route.query[utm_param] && typeof $route.query[utm_param] === 'string') {
      acc = {
        ...acc,
        [utm_param]: $route.query[utm_param] as string
      }
    }
    return acc
  }, resp)

  // check utmsimple's cookies if we still don't have anything
  if (!Object.keys(resp).length) {
    utmKeys.forEach((key: string) => {
      const val = get_cookie(key)
      if (!!val) {
        resp[key] = val
      }
    })
  }

  // if we don't have a utm_medium then use utmsimple.com's traffic_source - Paid, Organic, Direct, Referral, Social
  if (!resp.utm_medium && !!get_cookie('traffic_source')) {
    resp.utm_medium = (get_cookie('traffic_source') || '').toLowerCase()
  }

  // if we don't have a utm_source then use utmsimple.com's organic_source_str - organic source (Google, Bing, Instagram etc)
  if (!resp.utm_source && !!get_cookie('organic_source_str')) {
    resp.utm_source = (get_cookie('organic_source_str') || '').toLowerCase()
  }

  // send back what we have
  return resp
}

export const addressFormatter = (address: string = '', joinWith: string = ', ') => {
  return address
    .trim()
    .split(',')
    .map((item: string) => {
      return item.trim()
    })
    .filter(function (item: string) {
      return item !== ''
    })
    .join(joinWith)
}

export const delay = (ms: number = 0) => {
  return new Promise((resolve) => {
    nextTick(() => {
      setTimeout(resolve, ms)
    })
  })
}

/*
// check_webp_feature:
//   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//   'callback(feature, isSupported)' will be passed back the detection result (in an asynchronous way!)
type CheckWebpFeatureFeatureType = 'lossy' | 'lossless' | 'alpha' | 'animation'
type CheckWebpFeaturePromiseReturn = {
  feature: CheckWebpFeatureFeatureType
  result: boolean
}

export const isWebpSupported = useMemoize(
  (feature: CheckWebpFeatureFeatureType): Promise<CheckWebpFeaturePromiseReturn> => {
    return new Promise((resolve, reject) => {
      const kTestImages = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha:
          'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation:
          'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
      }
      const img = new Image()
      img.onload = function () {
        const result = img.width > 0 && img.height > 0
        resolve({
          feature: feature,
          result: result
        })
      }
      img.onerror = function () {
        reject({
          feature: feature,
          result: false
        })
      }
      img.src = 'data:image/webp;base64,' + kTestImages[feature]
    })
  }
)
*/
