import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@vueuse/head'
import { CRISP_CHAT_WEBSITE_ID } from '@/constants'
import Sentry from '@/utils/sentry'
import CrispChat from '@/utils/crispchat'
import UpgradeBoxImageMaxHeightDirective from '@/directives/UpgradeBoxImageMaxHeightDirective'
import CapitalizeInputDirective from '@/directives/CapitalizeInputDirective'
import Image from '@/components/Image.vue'

import App from '@/App.vue'
import router from '@/router'

import '@fontsource/rubik/300.css'
import '@fontsource/rubik/400.css'
import '@fontsource/rubik/500.css'
import '@fontsource/rubik/600.css'
import '@fontsource/rubik/700.css'
import '@fontsource/rubik/800.css'
import '@fontsource/rubik/900.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@/assets/main.scss'

const app = createApp(App)

Sentry(app, router)
CrispChat(CRISP_CHAT_WEBSITE_ID)

app.use(createPinia())
app.use(createHead())
app.use(router)

app.component('Image', Image)

app.directive('upgrade-box-image-max-height', UpgradeBoxImageMaxHeightDirective)
app.directive('capitalize-input', CapitalizeInputDirective)

app.mount('#app')
