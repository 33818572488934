<script setup lang="ts">
export interface Props {
  deactivate?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  deactivate: false
})
</script>

<template>
  <button
    class="bg-[#95C11F] text-white font-medium p-3 px-8 rounded-full"
    :disabled="props.deactivate"
    :class="{
      'hover:opacity-90': !props.deactivate,
      'opacity-70': !!props.deactivate
    }"
  >
    <slot></slot>
  </button>
</template>
