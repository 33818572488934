import type { ObjectDirective } from 'vue'

const eventHandler = (e: Event) => {
  const el = e.currentTarget as HTMLInputElement
  el.value = (el.value || '').slice().toUpperCase()
}

export default <ObjectDirective>{
  mounted(el, binding, vnode, prevVnode) {
    el.addEventListener('input', eventHandler)
  },
  unmounted(el, binding, vnode, prevVnode) {
    el.removeEventListener('input', eventHandler)
  }
}
