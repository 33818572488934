// used on the upgrade page to make the people in the box bigger/smaller based on box size
import type { ObjectDirective } from 'vue'

const handlerName = 'zixtyUpgradeBoxImageMaxHeightHandler'

export default <ObjectDirective>{
  mounted(el, binding, vnode, prevVnode) {
    const { parentSelector, percentage, pinToBottomOfParent } = binding.value || {}

    if (!!parentSelector && percentage > 0 && percentage < 101) {
      el[handlerName] = () => {
        if (!parentSelector) return

        var elementToMeasure = document.querySelector(parentSelector)
        if (!elementToMeasure) return

        el.style.maxHeight = Math.ceil(elementToMeasure.offsetHeight * (percentage / 100)) + 'px'

        setTimeout(() => {
          if (!!pinToBottomOfParent) {
            try {
              const pxToBottom =
                elementToMeasure.getBoundingClientRect().bottom - el.getBoundingClientRect().bottom
              el.style.marginBottom = `-${pxToBottom}px`
            } catch (err) {
              //
            }
          }
        }, 75)
      }

      // watch for resize
      window.addEventListener('resize', el[handlerName])

      // run for the first time
      el[handlerName]()
    }
  },
  unmounted(el, binding, vnode, prevVnode) {
    window.removeEventListener('resize', el[handlerName])
  }
}
