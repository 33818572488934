<script setup lang="ts">
import { useQuoteStore } from '@/stores/quote'
import Modal from '@/components/Modal.vue'
import DetailLineCurrencyValue from '@/components/Quote/DetailLineCurrencyValue.vue'
import EffectiveExcessDetailBreakdown from '@/components/Quote/EffectiveExcessDetailBreakdown.vue'

const quote = useQuoteStore()
</script>

<template>
  <DetailLineCurrencyValue
    :track-hotjar="true"
    title=""
    :value="quote.policy.effectiveExcessValue"
    :currency="quote.policy.currency"
    :minimum-fraction-digits="0"
  >
    <template v-slot:title>
      <div>
        <Modal size="max-w-2xl">
          <template v-slot:trigger>
            <div class="text-left">
              <div>Effective Excess</div>
              <div class="cursor-pointer text-[#95C11F] font-thin text-xs mt-0.5 mb-3">
                more info
              </div>
            </div>
          </template>
          <template v-slot:title>
            <div>Effective Excess</div>
          </template>
          <template v-slot:content>
            <div class="text-xs md:text-sm font-normal text-[#595959]">
              <EffectiveExcessDetailBreakdown />
            </div>
          </template>
        </Modal>
      </div>
    </template>
  </DetailLineCurrencyValue>
</template>
