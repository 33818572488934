const win = window as any

const ZIXTY_STANDARD_ITEM = {
  item_id: 'ZIXTY_STANDARD',
  item_name: 'Zixty'
}

const ZIXY_PLUS_ITEM = {
  item_id: 'ZIXTY_PLUS',
  item_name: 'Zixty Plus'
}

const getBrandItem = (brand: string = 'Zixty') => {
  if (brand === 'Zixtyplus') {
    return {
      ...ZIXY_PLUS_ITEM
    }
  }

  return {
    ...ZIXTY_STANDARD_ITEM
  }
}

function google_tracker(...args: any) {
  try {
    if (win?.gtag) {
      win.gtag(...args)
    } else {
      console.log(...args)
    }
  } catch (err) {
    console.log('tracker', 'error', err)
  }
}

export const trackBeginCheckout = (total_billable_amount: string, brand?: string) => {
  google_tracker('event', 'begin_checkout', {
    currency: 'GBP',
    value: total_billable_amount,
    items: [getBrandItem(brand)]
  })
}

export const trackAddPaymentInfo = (total_billable_amount: string, brand?: string) => {
  google_tracker('event', 'add_payment_info', {
    currency: 'GBP',
    value: total_billable_amount,
    items: [getBrandItem(brand)]
  })
}

export const trackPurchase = (
  correlation_id: string,
  total_billable_amount: string,
  brand?: string
) => {
  google_tracker('event', 'purchase', {
    transaction_id: correlation_id,
    currency: 'GBP',
    value: total_billable_amount,
    items: [getBrandItem(brand)]
  })
}

export const trackAwinPurchase = (
  policy_number: string,
  total_billable_amount: string,
  awin_commission_group: string = 'DEFAULT'
) => {
  // awin event and fallback pixel
  const awChannel = 'aw' // unsure about this (???)
  const awCurrencyCode = 'GBP'
  const awPromoCode = ''
  const awAmount = parseFloat(total_billable_amount).toFixed(2)
  const awOrderReference = policy_number
  const awIsTestTransaction = window.location.hostname === 'quote.zixty.com' ? '0' : '1'
  const awMerchantID = 50787
  const awParts = awin_commission_group + ':' + awAmount

  let awin = win.AWIN
  if (!!awin && !!awin?.Tracking) {
    awin.Tracking.Sale = {}
    awin.Tracking.Sale.amount = awAmount
    awin.Tracking.Sale.channel = awChannel
    awin.Tracking.Sale.orderRef = awOrderReference
    awin.Tracking.Sale.parts = awParts
    awin.Tracking.Sale.currency = awCurrencyCode
    awin.Tracking.Sale.voucher = awPromoCode
    awin.Tracking.Sale.test = awIsTestTransaction
    awin.Tracking.run()
  }

  var awPixel = new Image(0, 0)
  awPixel.src =
    'https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=' +
    awMerchantID +
    '&amount=' +
    awAmount +
    '&ch=' +
    awChannel +
    '&parts=' +
    awParts +
    '&ref=' +
    awOrderReference +
    '&vc=' +
    '' +
    '&cr=' +
    awCurrencyCode +
    '&testmode=' +
    awIsTestTransaction
}
