import PartnerLayout from '@/views/Partner/Layout.vue'

import PartnerView from '@/views/Partner/PartnerView.vue'

export const PartnerRoutes = [
  {
    path: '/partner',
    name: 'partner',
    component: PartnerLayout,
    children: [
      {
        path: ':partner',
        name: 'partner.partner',
        // component: () => import('@/views/Partner/PartnerView.vue'),
        component: PartnerView
      }
    ]
  }
]
