<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { delay, getUtmParameters } from '@/utils/helpers'
import {
  getStart,
  isZixtyAxiosErrorCheck,
  type ApiErrorResponse,
  type StartResponse,
  type StartRequest
} from '@/utils/api'
import { useQuoteStore } from '@/stores/quote'
import { useHead } from '@vueuse/head'
import FormSpinner from '@/components/Quote/FormSpinner.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'

useHead({
  title: 'Partner - Zixty Quote'
})

const $route = useRoute()
const $router = useRouter()

const quote = useQuoteStore()

const errorsServer = ref<ApiErrorResponse | undefined>()

const inAjaxCall = ref<boolean>(false)
const partner = ref<string>(($route.params?.partner || '') as string)

const onStart = async (tries: number = 1): Promise<Partial<StartResponse>> => {
  try {
    // build the getStartRequest
    let getStartRequest: StartRequest = {
      partner: partner.value
    }

    // add the old correlation id
    if (!!quote?.responses?.expired_correlation_id) {
      getStartRequest.expired_correlation_id = quote.responses.expired_correlation_id
    }

    // add awin parameter
    if (!!$route?.query?.awc && $route.query.awc.length > 0) {
      getStartRequest.awin = {
        merchant_id: 50787,
        awc: $route?.query?.awc as string
      }
    }

    // get utm params
    const utm_params = getUtmParameters($route)
    if (!!utm_params && Object.keys(utm_params).length > 0) {
      getStartRequest.utm = utm_params
    }

    const response = await getStart(getStartRequest)

    // build the response
    const data = response?.data || {}

    if (!data.correlation_id) {
      throw new Error('no correlation_id')
    }

    quote.responses.correlation_id = data.correlation_id
    quote.responses.quote_expires_at = response?.headers['x-expiry-date'] || ''
    quote.responses.partner = data.partner

    return data
  } catch (error) {
    // don't retry this type of error
    if (
      isZixtyAxiosErrorCheck(error) &&
      (error.response?.data?.code === 'ZWS999' || error.response?.data?.code === 'ZWS9999')
    ) {
      throw error
    }

    if (tries > 10) {
      throw error
    }

    await delay(tries * 1000)
    return await onStart(tries + 1)
  }
}

const redirect = (journey: string = 'direct') => {
  // add ?partner=... if partner
  let query = $route.query ? { ...$route.query } : {}

  if (journey === 'partner' && !!partner.value && !!quote?.responses?.partner?.name) {
    query.partner = partner.value
  }

  // update the url
  $router.replace({
    name: 'quote.vehicle',
    params: {
      journey: journey
    },
    query: query
  })
}

if (!!partner.value) {
  inAjaxCall.value = true

  const errorHandler = (error: any) => {
    inAjaxCall.value = false
    errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined

    if (error?.response?.headers && error.response.headers['content-type'] === 'text/html') {
      redirect()
    }
  }

  onStart()
    .then(() => {
      inAjaxCall.value = false
      redirect(!!quote.responses?.partner?.name ? 'partner' : 'direct')
    }, errorHandler)
    .catch(errorHandler)
} else {
  redirect()
}
</script>

<template>
  <div class="flex justify-center items-center h-screen">
    <FormServerErrors v-if="errorsServer" :error="errorsServer" />
    <div class="w-full">
      <form
        id="form-partner"
        novalidate
        :class="{
          'form23a-loading': inAjaxCall
        }"
      >
        <FormSpinner v-if="inAjaxCall" />
      </form>
    </div>
  </div>
</template>
