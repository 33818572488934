<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { datelocalised } from '@/utils/date'
import { DATE_FORMAT } from '@/constants'
import type { DriverDetailsSchemaErrorsType } from '@/stores/quote.schema'
import QuoteFormFieldErrors from '@/components/Quote/QuoteFormFieldErrors.vue'

// inputs/outputs
export interface Props {
  modelValue?: Date
  errorsClient?: DriverDetailsSchemaErrorsType | undefined
}

const props = withDefaults(defineProps<Props>(), {
  //
})

const $emits = defineEmits(['update:modelValue'])

// input date as a string
const dmyString = props.modelValue ? datelocalised(props.modelValue).format(DATE_FORMAT) : ''

// segments for the inputs
const segments = (dmyString || '').split('/')

// input values
const d = ref<string>(segments[0] || '')
const m = ref<string>(segments[1] || '')
const y = ref<string>(segments[2] || '')

// replace 'Required' error with more detail to the error message
const fieldErrors = computed(() => {
  let errors = props?.errorsClient?.fieldErrors?.date_of_birth

  if (!!errors && !!errors[0] && errors[0] === 'Required') {
    if (d.value === '') {
      errors[0] = 'Day is required in a 2 digit format, e.g. 20'
    } else if (m.value === '') {
      errors[0] = 'Month is required in a 2 digit format e.g. 01'
    } else if (y.value === '' || `${y.value}`.length !== 4) {
      errors[0] = 'Year is required in a 4 digit format e.g. 1990'
    }
  }

  return errors
})

// watch for changes, if the date is valid then update the model value with the value
watch(
  () => {
    let dv = d.value || ''
    dv = dv.length === 1 ? `0${dv}` : dv

    let mv = m.value || ''
    mv = mv.length === 1 ? `0${mv}` : mv

    let yv = y.value || ''

    return {
      d: dv,
      m: mv,
      y: yv
    }
  },
  (nv, ov) => {
    const full = `${nv.d}/${nv.m}/${nv.y}`
    const isValid = !!full.match(/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/)
    $emits('update:modelValue', isValid ? datelocalised(full, DATE_FORMAT).toDate() : undefined)
  }
)

const isNumber = (evt: KeyboardEvent): void => {
  const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  const keyPressed: string = evt.key

  if (!keysAllowed.includes(keyPressed)) {
    evt.preventDefault()
  }
}
</script>

<template>
  <label class="form23a-label">Date of birth</label>
  <div class="flex items-center">
    <div class="w-3/12 mr-1">
      <label class="form23a-label truncate text-[#595959]" for="dbd">
        Day <small>(dd)</small>
      </label>
      <input
        type="text"
        name="dbd"
        id="form-quote-your-details--field-dbd"
        class="data-hj-allow form23a-input w-full mt-1"
        placeholder="DD"
        v-model="d"
        @keypress="isNumber"
        minlength="2"
        maxlength="2"
      />
    </div>
    <div class="w-3/12 mx-1">
      <label class="form23a-label truncate text-[#595959]" for="dbm">
        Month <small>(mm)</small>
      </label>
      <input
        type="text"
        name="dbm"
        id="form-quote-your-details--field-dbm"
        class="data-hj-allow form23a-input w-full mt-1"
        placeholder="MM"
        v-model="m"
        @keypress="isNumber"
        minlength="2"
        maxlength="2"
      />
    </div>
    <div class="w-6/12 ml-1">
      <label class="form23a-label truncate text-[#595959]" for="dby">
        Year <small>(yyyy)</small>
      </label>
      <input
        type="text"
        name="dby"
        id="form-quote-your-details--field-dby"
        class="data-hj-allow form23a-input w-full mt-1"
        placeholder="YYYY"
        v-model="y"
        @keypress="isNumber"
        minlength="4"
        maxlength="4"
      />
    </div>
  </div>
  <QuoteFormFieldErrors :errors="fieldErrors" />
</template>
