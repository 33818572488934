<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { getAddresses } from '@/utils/api'
import { REGEX_UK_POSTCODE } from '@/constants'
import { addressFormatter, delay } from '@/utils/helpers'
import LoadingText from '@/components/LoadingText.vue'

export type PropsOptions = {
  label: string
  value: string
  selected?: boolean
}

export interface Props {
  id: string
  address?: string
  postcode?: string
  placeholder?: string
  correlation_id?: string
  name?: string
  class?: string
}

const props = withDefaults(defineProps<Props>(), {
  id: 'field-address-' + new Date().getTime(),
  address: '',
  postcode: '',
  placeholder: '',
  correlation_id: '',
  name: '',
  class: ''
})

const $emit = defineEmits(['update:address', 'update:postcode'])

const tempModel = ref<string>(props.address || '')
const tempPostcode = ref<string>(props.postcode || '')
const suggestionsRaw = ref<string[]>()
const suggestionChoices = ref<PropsOptions[]>()
const suggestionErrors = ref<any>()
const inAjaxCall = ref<boolean>(false)

watch(
  () => tempModel.value,
  (newValue, oldValue) => {
    $emit('update:address', tempModel.value)
    $emit('update:postcode', tempPostcode.value)
  }
)

watch(
  () => tempPostcode.value,
  (newValue, oldValue) => {
    $emit('update:postcode', newValue)
  }
)

const isValidPostcode = (term: string) => {
  const postcodeExpression = new RegExp(REGEX_UK_POSTCODE)
  return !!term && !!postcodeExpression.test(term)
}

const getTimeInMS = () => {
  const dt = new Date()
  return dt.getTime()
}

const lookup = (term: string) => {
  const isValid = isValidPostcode(term)
  let startTime = getTimeInMS()

  if (isValid) {
    if (!!inAjaxCall.value) return
    inAjaxCall.value = true

    suggestionErrors.value = undefined

    const normalisedPostcode = (term || '').replace(' ', '').toUpperCase()

    const getAddressesRequest = {
      correlation_id: props.correlation_id,
      postcode: normalisedPostcode
    }

    getAddresses(getAddressesRequest)
      .then(
        (res) => {
          const fetchedAddresses = res.data?.addresses || []

          suggestionsRaw.value = fetchedAddresses

          const normaliseStringValue = (val: string) => (val || '').toLowerCase().trim()

          const choicesOptions = fetchedAddresses.slice().map((suggestion) => {
            const fullAddress = suggestion + ', ' + (term || '').toUpperCase()

            const itemValue = fullAddress
            const itemLabel = addressFormatter(fullAddress, ', ')

            const itemValueNormalised = normaliseStringValue(itemValue)
            const itemLabelNormalised = normaliseStringValue(itemLabel)
            const tempModelNormalised = normaliseStringValue(tempModel.value)

            const isSelected =
              itemValueNormalised === tempModelNormalised ||
              itemLabelNormalised === tempModelNormalised

            return {
              value: itemValue,
              label: itemLabel,
              selected: isSelected
            }
          })

          const choicesOptionsSelected = choicesOptions
            .filter((choicesOption) => !!choicesOption.selected)
            .pop()

          // wait at least 250ms to make the UI look like it's done something
          let delayMs = getTimeInMS() - startTime
          delayMs = delayMs <= 250 ? 250 : 0

          delay(delayMs).then(() => {
            // update state
            inAjaxCall.value = false

            tempModel.value = !!choicesOptionsSelected ? choicesOptionsSelected.value : ''

            suggestionChoices.value = choicesOptions

            // mock the error
            if (!choicesOptions || choicesOptions.length <= 0) {
              suggestionErrors.value = {
                what_to_say: 'No addresses found for this postcode.'
              }
            }
          })
        },
        (reason) => {
          inAjaxCall.value = false

          tempModel.value = ''

          getAddresses.delete(getAddressesRequest)

          suggestionChoices.value = []

          suggestionErrors.value = reason?.response?.data || {}
        }
      )
      .catch(() => {
        inAjaxCall.value = false

        tempModel.value = ''

        getAddresses.delete(getAddressesRequest)

        suggestionChoices.value = []
      })
  } else {
    // mock the error
    suggestionErrors.value = {
      what_to_say: 'Please enter a valid postcode.'
    }
  }
}

const onSearch = (e: Event) => {
  tempPostcode.value = (tempPostcode.value || '').toUpperCase()
  lookup(tempPostcode.value)
}

onMounted(() => {
  if (!!props.postcode && !!props?.correlation_id) {
    lookup(props.postcode)
  }
})
</script>

<template>
  <div class="w-full p-0">
    <div class="w-full">
      <div class="flex flex-col justify-between w-full">
        <input
          type="text"
          name="pc_lookup"
          aria-describedby="address_lookup_label"
          id="form-quote-your-details--field-pc-lookup"
          placeholder="Enter driver's postcode..."
          class="data-hj-allow form23a-input flex-grow uppercase w-44 md:w-1/2"
          v-model.trim="tempPostcode"
          v-capitalize-input
          @keydown.prevent.stop.enter="onSearch"
        />
        <button
          type="button"
          class="bg-[#95C11F] hover:opacity-90 text-white py-3 px-3 rounded-md focus:ring-0 text-[14px] w-[120px] mt-3"
          @click.prevent="onSearch"
          :disabled="!!inAjaxCall"
          :class="{
            'opacity-80 cursor-not-allowed': !!inAjaxCall
          }"
        >
          <span v-if="!inAjaxCall"> Find address </span>
          <span v-if="!!inAjaxCall"> <LoadingText text="Searching" :dots="3" /> </span>
        </button>
      </div>
    </div>
    <div class="w-full mt-3" v-if="!!suggestionChoices && !!suggestionChoices.length">
      <label for="addr_select" class="form23a-label"> Address </label>
      <select
        class="form23a-select w-full truncate pr-9"
        name="addr_select"
        id="form-quote-your-details--field-addr-select"
        v-model.trim="tempModel"
        :class="{
          'form23a-unselected': !tempModel
        }"
      >
        <option value="" disabled>Select driver’s address...</option>
        <option
          v-for="suggestionChoice in suggestionChoices"
          :value="suggestionChoice.value"
          :key="suggestionChoice.value"
        >
          {{ suggestionChoice.label }}
        </option>
      </select>
    </div>
    <div class="w-full mt-1.5" v-if="!!suggestionErrors">
      <div class="form23a-error-message block">
        <p class="text-red-500 text-sm">
          {{
            suggestionErrors.what_to_say ||
            suggestionErrors.message ||
            `An unexpected error has occurred. Please try again.`
          }}
        </p>
      </div>
    </div>
  </div>
</template>
