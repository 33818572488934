import { z, type RefinementCtx } from 'zod'
import { datelocalised } from '@/utils/date'
import type { Dayjs } from 'dayjs'
import {
  REGEX_UK_VEHICLE_REGISTRATION,
  REGEX_UK_MOBILE_NUMBER,
  REGEX_UK_DRIVING_LICENCE,
  REGEX_UK_POSTCODE,
  REGEX_PERSON_NAME
} from '@/constants'
import type {
  StartResponse,
  AggregatorStartResponse,
  VehicleLookupResponse,
  QuickQuoteResponse,
  FullQuoteResponseEntry,
  FullQuoteResponse,
  BuyPolicyResponse,
  OnCoverResponse,
  GetWebQuoteResponse,
  StartRequest,
  FullQuoteAddOnResponse,
  RecordWebEventRequest
} from '@/utils/api'

export const coverMaxDays = 29
export const ageMinimumJourneyAggregator = 19
export const ageMaximumJourneyAggregator = 75
export const ageMinimumCarInYears = 19
export const ageMaximumCarInYears = 75
export const ageMinimumCommercialVehicleInYears = 21
export const ageMaximumCommercialVehicleInYears = 75
export const getCoverStartMinimumDaysFromNow = (): Dayjs => datelocalised()
export const getCoverStartMaximumDaysFromNow = (): Dayjs =>
  datelocalised().add(coverMaxDays, 'days')

// indiviudal form schemas
export const VehicleSchema = z.object({
  registration_number_ui: z
    .string()
    .min(1, `Please enter a valid UK vehicle registration number`)
    .describe(
      `This is what the user updates, we allow this to be permissive. This is for display purposes only.`
    ),
  registration_number: z
    .string()
    .regex(REGEX_UK_VEHICLE_REGISTRATION, `Please enter a valid UK vehicle registration number`)
})

export type VehicleSchemaType = z.infer<typeof VehicleSchema>

export type VehicleSchemaErrorsType = z.inferFlattenedErrors<typeof VehicleSchema>

export const HowLongForSchema = z
  .object({
    selected_duration: z
      .number()
      .min(1, `Please select a valid cover duration`)
      .refine((arg) => !!arg, {
        message: `Please select a valid cover duration`,
        path: ['selected_duration']
      }),
    selected_duration_unit: z.enum(['hours', 'days'], {
      errorMap: (issue, ctx) => ({ message: 'Please select either hours or days' })
    }),
    cover_start_date: z.string().refine((arg) => !!arg, {
      message: 'Please select a valid cover start date',
      path: ['cover_start_date_time']
    }),
    cover_start_hour: z.string().refine((arg) => !!arg, {
      message: 'Please select a valid cover start time',
      path: ['cover_start_date_time']
    }),
    cover_start_minute: z.string().refine((arg) => !!arg, {
      message: 'Please select a valid cover start time',
      path: ['cover_start_date_time']
    }),
    cover_start_date_time: z
      .date()
      .min(getCoverStartMinimumDaysFromNow().toDate(), `Please select a valid date and time`)
      .max(getCoverStartMaximumDaysFromNow().toDate(), `Please select a valid date and time`)
  })
  .superRefine((arg, ctx) => {
    // cover selected duration
    if (arg.selected_duration_unit === 'days' && arg.selected_duration > coverMaxDays) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Cover length must be 28 days or less',
        path: ['selected_duration']
      })
    }

    if (arg.selected_duration_unit === 'hours' && arg.selected_duration > 24) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Cover length must be under 24 hours',
        path: ['selected_duration']
      })
    }
  })

export type HowLongForSchemaType = z.infer<typeof HowLongForSchema>

export type HowLongForSchemaErrorsType = z.inferFlattenedErrors<typeof HowLongForSchema>

export const DriverDetailsSchema = z
  .object({
    first_name: z
      .string()
      .regex(REGEX_PERSON_NAME, `Please check the driver's first name`)
      .min(2, `Please enter the driver's first name, and not just their initial.`),
    last_name: z.string().regex(REGEX_PERSON_NAME, `Please check the driver's last name`),
    dlntype: z
      .string()
      .refine((arg) => !!arg && (arg === 'LICENCE_TYPE_FULL' || arg === 'LICENCE_TYPE_EU'), {
        message: `Please note that we currently only accept Full UK driving licences.`,
        path: ['dlntype']
      }),
    type_approval: z.string().describe(`Used purely to check age criteria in this schema`),
    journey_type: z.string().describe(`Used purely to check age criteria in this schema`),
    cover_start_date_time: z.date().describe(`Used purely to check age criteria in this schema`),
    date_of_birth: z.date(),
    address: z.string().refine((arg) => !!arg, {
      message: `Please enter a valid Postcode, then click 'Find address'. Then select your Address from the dropdown.`,
      path: ['postcode']
    }),
    postcode: z
      .string()
      .regex(REGEX_UK_POSTCODE, `Please enter a valid Postcode and then click 'Find address'.`),
    email_address: z.string().email(`Please enter a valid email address`)
  })
  .superRefine((arg, ctx) => {
    // check customer is over min age and under max age
    const checkAgaintDate = datelocalised(arg?.cover_start_date_time || new Date()).startOf('day')
    if (!!arg?.date_of_birth) {
      // get the min/max age
      const isVehicleTypeM1 = arg.type_approval === 'M1'
      let minAge = isVehicleTypeM1 ? ageMinimumCarInYears : ageMinimumCommercialVehicleInYears
      let maxAge = isVehicleTypeM1 ? ageMaximumCarInYears : ageMaximumCommercialVehicleInYears

      // set the min/max age for aggregators
      if (arg.journey_type === 'aggregator') {
        minAge = ageMinimumJourneyAggregator
        maxAge = ageMaximumJourneyAggregator
      }

      // dob
      const dobDate = datelocalised(arg.date_of_birth).startOf('day')

      // check older than min age
      const isUnderMinAge = checkAgaintDate.diff(dobDate, 'year', true) < minAge

      // check under max age
      const isOverMaxAge = checkAgaintDate.diff(dobDate, 'year', true) >= maxAge + 1

      // show an error if either fail
      if (isUnderMinAge || isOverMaxAge) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `The driver needs to be between ${minAge} and ${maxAge} years old`,
          path: ['date_of_birth']
        })
      }
    }
  })

export type DriverDetailsSchemaType = z.infer<typeof DriverDetailsSchema>

export type DriverDetailsSchemaErrorsType = z.inferFlattenedErrors<typeof DriverDetailsSchema>

export const AlmostThereBaseSchema = {
  gender: z
    .enum(['male', 'female'], {
      errorMap: (issue, ctx) => ({ message: 'Please select one of the options' })
    })
    .nullable(),
  occupation: z.string().min(1, `Please pick an option from the drop-down`),
  business_type: z.string().min(1, `Please pick an option from the drop-down`),
  phone_number_ui: z
    .string()
    .min(1, `Please enter a valid UK mobile number`)
    .describe(
      `This is what the user updates, we allow this to be permissive it allows,
      spaces, we then pre-process the value. This is for display purposes only.`
    ),
  phone_number: z.string().regex(REGEX_UK_MOBILE_NUMBER, `Please enter a valid UK mobile number`),
  reason_for_cover: z.string().min(1, `Please pick an option from the drop-down`),
  reason_for_cover_own_vehicle: z.string().optional(),
  relation: z.string().optional(),
  lender_first_name: z
    .string()
    .regex(REGEX_PERSON_NAME, `Please enter a valid first name for the lender`)
    .or(z.string().max(0).nullish())
    .optional(),
  lender_last_name: z
    .string()
    .regex(REGEX_PERSON_NAME, `Please enter a valid last name for the lender`)
    .or(z.string().max(0).nullish())
    .optional()
}

export const AlmostThereBaseSuperRefine = (arg: any, ctx: RefinementCtx) => {
  const isBlankString = (val: string) => !val || val === ''

  if (arg.reason_for_cover === 'IOV') {
    if (isBlankString(arg?.reason_for_cover_own_vehicle || '')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please pick an option from the drop-down',
        path: ['reason_for_cover_own_vehicle']
      })
    }
  }
  if (arg.reason_for_cover === 'BV') {
    if (isBlankString(arg?.relation || '')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please pick an option from the drop-down',
        path: ['relation']
      })
    }
    if (isBlankString(arg.lender_first_name || '')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid first name for the lender',
        path: ['lender_first_name']
      })
    }
    if (isBlankString(arg.lender_last_name || '')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid last name for the lender',
        path: ['lender_last_name']
      })
    }
  }
}

export const AlmostThereSchema = z
  .object({
    ...AlmostThereBaseSchema
  })
  .superRefine(AlmostThereBaseSuperRefine)

export type AlmostThereSchemaType = z.infer<typeof AlmostThereSchema>

export type AlmostThereSchemaErrorsType = z.inferFlattenedErrors<typeof AlmostThereSchema>

export const AlmostThereUKSchema = z
  .object({
    ...AlmostThereBaseSchema,
    driving_licence_number_section_a: z.string().min(5),
    driving_licence_number_section_b: z
      .string()
      .min(6, `Please select one of the options for Gender`),
    driving_licence_number_section_c: z.string().min(1),
    driving_licence_number_section_d: z
      .string()
      .min(
        4,
        `Please enter the final 4 characters from your 16 digit driving licence number. These do not include the 2 digits that are set apart from the rest.`
      ),
    driving_licence_number_ui: z
      .string()
      .min(1, `Please enter a valid UK DVLA issued driving licence`)
      .describe(
        `This is what the user updates, we allow this to be permissive,
      it allows spaces and more characters than the DLN requires,
      we then pre-process the value. This is for display purposes only.`
      ),
    driving_licence_number: z
      .string()
      .regex(REGEX_UK_DRIVING_LICENCE, `Please enter a valid UK DVLA issued driving licence`)
  })
  .superRefine(AlmostThereBaseSuperRefine)

export type AlmostThereUKSchemaType = z.infer<typeof AlmostThereUKSchema>

export type AlmostThereUKSchemaErrorsType = z.inferFlattenedErrors<typeof AlmostThereUKSchema>

export const AlmostThereEUSchema = z
  .object({
    ...AlmostThereBaseSchema,
    driving_licence_number_ui: z
      .string()
      .min(1, `Please enter a valid EU issued driving licence`)
      .describe(
        `This is what the user updates, we allow this to be permissive,
        it allows spaces and more characters than the DLN requires,
        we then pre-process the value. This is for display purposes only.`
      ),
    driving_licence_number: z.string().min(1, `Please enter a valid EU issued driving licence`),
    driving_licence_held_for_years: z
      .number()
      .min(3, `We can only insure where an EU licence has been held for a minimum of 3 years`)
  })
  .superRefine(AlmostThereBaseSuperRefine)

export type AlmostThereEUSchemaType = z.infer<typeof AlmostThereEUSchema>

export type AlmostThereEUSchemaErrorsType = z.inferFlattenedErrors<typeof AlmostThereEUSchema>

export const DeclarationsSchema = z.object({
  accepted_declarations: z.boolean()
})

export type DeclarationsSchemaType = z.infer<typeof DeclarationsSchema>

export type DeclarationsSchemaErrorsType = z.inferFlattenedErrors<typeof DeclarationsSchema>

export const ConfirmAndBuySchema = z
  .object({
    email_address: z.string().email(),
    email_address_confirmation: z.string().email(),
    receive_marketing: z.boolean()
  })
  .superRefine((arg, ctx) => {
    if (arg.email_address !== arg.email_address_confirmation) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'All entered email addresses must match',
        path: ['email_address_confirmation']
      })
    }
  })

export type ConfirmAndBuySchemaType = z.infer<typeof ConfirmAndBuySchema>

export type ConfirmAndBuySchemaErrorsType = z.inferFlattenedErrors<typeof ConfirmAndBuySchema>

// quote inputs schema
export const QuoteInputsSchema = z
  .object({
    //
  })
  .merge(VehicleSchema)
  .merge(HowLongForSchema.sourceType())
  .merge(DriverDetailsSchema.sourceType())
  .merge(AlmostThereUKSchema.sourceType())
  .merge(AlmostThereEUSchema.sourceType())
  .merge(DeclarationsSchema)
  .merge(ConfirmAndBuySchema.sourceType())
  .merge(
    z.object({
      cover_start: z.string(),
      cover_period_hours: z
        .number()
        .min(1)
        .max(coverMaxDays * 24)
    })
  )
  .merge(
    z.object({
      registration_number_mot_valid_shown: z.string()
    })
  )
  .merge(
    z.object({
      is_occupation_business_ok_shown: z.boolean()
    })
  )

export type QuoteInputsSchemaType = z.infer<typeof QuoteInputsSchema>

// --- API Responses -- //

export interface ApiResponsesAggregator extends AggregatorStartResponse {
  revisions?: number
}

export interface ApiResponses {
  correlation_id: StartResponse['correlation_id']
  expired_correlation_id?: StartRequest['expired_correlation_id']
  quote_expires_at?: string
  partner?: StartResponse['partner']
  vehicle: Partial<VehicleLookupResponse>
  quickquote: Partial<QuickQuoteResponse>
  fullquote: Partial<FullQuoteResponse>
  buy: Partial<BuyPolicyResponse>
  cover: Partial<OnCoverResponse>
  webquote: Partial<GetWebQuoteResponse>
  aggregator: Partial<ApiResponsesAggregator>
}

export interface PolicyInterface {
  currency: 'GBP'
  brand: keyof FullQuoteResponse['quotes']
  addOns: Record<string, boolean>
  effectiveExcessValue: number
  selectedAddOnsIncludingMandatory: Record<string, FullQuoteAddOnResponse>
  selectedBrand?: FullQuoteResponseEntry
  selectableBrands?: FullQuoteResponse['quotes']
  total: number
}

export interface MetaInterface {
  isZixtyDotCom: boolean
  isZixtyLocal: boolean
}

export interface QuoteStoreHelpersInterface {
  recordWebEvent: (
    name: RecordWebEventRequest['name'],
    data?: RecordWebEventRequest['data']
  ) => void
  isCoverStartTimeHistorical: () => boolean
  hasQuoteExpired: () => boolean
  restartQuote: () => void
}
