<script setup lang="ts">
export interface Props {
  value: number | string
  currency: 'GBP'
  locale?: string
  minimumFractionDigits?: number
}

const props = withDefaults(defineProps<Props>(), {
  locale: 'en',
  currency: 'GBP',
  minimumFractionDigits: 2
})

const display = (val: Props) => {
  return Number(val.value).toLocaleString(val.locale, {
    style: 'currency',
    currency: val.currency,
    minimumFractionDigits: val.minimumFractionDigits
  })
}
</script>

<template>
  {{ display(props) }}
</template>
