<script setup lang="ts">
import { onMounted, ref } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

const elfRef = ref<HTMLElement>()

onMounted(() => {
  if (!!elfRef && !!elfRef.value && !!elfRef.value.scrollIntoView) {
    elfRef.value.scrollIntoView()
  }
})
</script>

<template>
  <div class="form23a-loading-spinner">
    <div ref="elfRef">
      <LoadingSpinner />
    </div>
  </div>
</template>
