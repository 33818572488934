<script setup lang="ts">
import { computed, onMounted, ref, toRef, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useQuoteStore } from '@/stores/quote'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import QuoteFormContinueButton from '@/components/Quote/QuoteFormContinueButton.vue'
import BackButton from '@/components/Quote/BackButton.vue'
import Modal from '@/components/Modal.vue'
import CurrencyValue from '@/components/Quote/CurrencyValue.vue'
import DownloadsList from '@/components/Quote/DownloadsList.vue'
import UpgradeTable from '@/components/Quote/UpgradeTable.vue'
import { setPolicySelections } from '@/utils/api'
import { delay } from '@/utils/helpers'

const $route = useRoute()
const $router = useRouter()

const isAggregator = computed(() => $route.params?.journey === 'aggregator')

useHead({
  title: 'Upgrade - Zixty Quote'
})

const quote = useQuoteStore()

const policy = toRef(quote, 'policy')

const serialize = (val: any) => JSON.stringify(val)

const savePolicySelections = async () => {
  try {
    await setPolicySelections({
      brand: quote.policy.brand,
      add_on_codes: Object.keys(quote.policy.selectedAddOnsIncludingMandatory),
      correlation_id: quote.responses.correlation_id || ''
    })
  } catch (err) {
    //
  }
}

watch(
  () => ({
    brand: quote.policy?.brand,
    addOns: {
      ...(quote.policy?.addOns || {})
    }
  }),
  async (nv, ov) => {
    if (!!quote?.responses?.correlation_id && serialize(nv) !== serialize(ov)) {
      savePolicySelections()
    }
  }
)

const isAddOnsValid = ref<boolean>()

const onSubmit = async (e: Event) => {
  isAddOnsValid.value = Object.keys(quote.policy.selectedBrand?.AddOns || {})
    .filter((key) => !quote.policy.selectedBrand?.AddOns[key].Mandatory)
    .reduce((acc, key) => {
      if (acc === false) return acc
      return quote.policy.addOns[key] === false || quote.policy.addOns[key] === true
    }, true)

  if (isAddOnsValid.value) {
    if (quote.helpers.hasQuoteExpired()) {
      return $router.push({
        name: 'quote.time'
      })
    }

    $router.push({
      name: 'quote.declarations'
    })
  } else {
    try {
      delay(50).then(() => {
        const selector = '.upgrade-page-select-option'
        const el = document.querySelector(selector)?.parentNode?.parentNode?.parentNode?.parentNode
          ?.parentNode?.parentNode?.parentNode?.parentNode as HTMLElement
        window.scrollTo({
          top: (el.offsetTop || 0) - 10,
          behavior: 'smooth'
        })
      })
    } catch (err) {
      //
    }
  }
}

onMounted(() => {
  quote.helpers.recordWebEvent('ADD_ONS')
  savePolicySelections()
})
</script>

<template>
  <div class="w-full">
    <div class="mx-auto max-w-[650px] lg:w-8/12">
      <div class="mx-auto">
        <form id="form-quote-upgrade" @submit.prevent="onSubmit" novalidate>
          <!-- Header -->
          <div class="w-full mx-auto text-center">
            <div class="flex flex-col flex-wrap justify-center text-[#595959]">
              <div class="text-[#595959] text-3xl my-3 font-semibold tracking-wide">Your quote</div>
              <QuoteFormTitle>
                <span class="text-3xl">
                  <CurrencyValue :value="policy.total" :currency="policy.currency" />
                </span>
              </QuoteFormTitle>
              <div class="text-sm text-[#a9a9a9] mt-3">
                <span class="flex justify-center items-center"> including IPT & fees </span>
              </div>
            </div>
          </div>

          <!-- Upgrade to ZixtyPlus for just message -->
          <div
            class="my-9 w-full mx-auto text-center text-[#95C11F] text-lg font-semibold tracking-wider leading-relaxed"
            v-if="
              !!policy?.selectableBrands?.Zixtyplus?.PremiumInclFeeWithMandatoryAddOns &&
              !!policy?.selectableBrands?.Zixty?.PremiumInclFeeWithMandatoryAddOns
            "
          >
            <span v-if="policy?.brand === 'Zixty'">
              <span class="block"> Upgrade to Zixty Plus </span>
              <span> for just </span>
              <CurrencyValue
                :value="
                  Number(policy.selectableBrands.Zixtyplus.PremiumInclFeeWithMandatoryAddOns || 0) -
                  Number(policy.selectableBrands.Zixty.PremiumInclFeeWithMandatoryAddOns || 0)
                "
                currency="GBP"
              />
            </span>
            <span v-if="policy?.brand === 'Zixtyplus'">
              <span class="block">Valuable benefits</span>
              <span>included as standard</span>
            </span>
          </div>

          <!-- Table -->
          <UpgradeTable />

          <!-- Add Ons -->
          <div class="py-3 w-full flex flex-col justify-center md:justify-start">
            <div v-if="quote.policy.selectedBrand?.AddOns">
              <div
                v-for="(addOn, addOnKey) in quote.policy.selectedBrand?.AddOns"
                :key="addOnKey"
                :id="`upgrade-box-${addOnKey}`"
                class="w-full mx-auto my-6"
                :class="{
                  hidden: !!addOn?.Mandatory
                }"
              >
                <div class="w-full border-[3px] border-[#95C11F] text-white py-[5px] px-[5px]">
                  <div
                    class="flex w-full justify-end text-right items-center px-3 py-3 md:pr-6 md:pb-1.5 md:pl-6"
                  >
                    <h4 class="font-medium text-xl text-[#95C11F]">
                      <span v-html="addOn?.UpgradeBoxTitle || addOn?.Name"></span>
                    </h4>
                  </div>
                  <div class="w-full flex justify-end">
                    <div class="w-9/12 md:w-10/12 mx-3">
                      <div class="text-[#595959] px-6 py-2 pr-3">
                        <div>
                          <div class="text-right text-[#595959] mb-1.5">
                            <small class="ml-1 text-sm font-semibold tracking-wide">Only</small>
                            <span class="ml-1 text-3xl font-semibold tracking-wide">
                              <CurrencyValue
                                :value="addOn.PremiumInclFee"
                                :currency="policy.currency"
                              />
                            </span>
                          </div>
                        </div>
                        <div class="text-left pl-3 md:pl-6 md:pr-1 pt-3">
                          <p class="text-xs leading-tight">
                            <span> <span v-html="addOn.Description"></span> </span>
                            <span v-if="addOnKey === 'PE250'">
                              <Modal size="max-w-2xl">
                                <template v-slot:trigger>
                                  <button type="button" class="text-[#95C11F] hover:opacity-80">
                                    &nbsp;Learn more.
                                  </button>
                                </template>
                                <template v-slot:title>
                                  <div>{{ addOn?.UpgradeBoxTitle || addOn?.Name }}</div>
                                </template>
                                <template v-slot:content>
                                  <div class="text-sm leading-tight">
                                    <div v-if="quote.policy?.brand === 'Zixty'">
                                      <div class="my-3">
                                        Excess protection is designed to safeguard your finances by
                                        refunding some of your motor Excess in the event of a fault
                                        claim.
                                      </div>
                                      <div class="my-3">
                                        Select Excess Protect now to enjoy <strong>£250</strong> of
                                        cover for a modest cost.
                                      </div>
                                      <div class="my-3">
                                        After a fault claim - focus on what matters most - getting
                                        back on the road safely and quickly.
                                      </div>
                                    </div>
                                    <div v-if="quote.policy?.brand === 'Zixtyplus'">
                                      <div class="my-3">
                                        Our £100 Excess Protect included as standard softens the
                                        blow of a fault claim, but you can boost this further.
                                      </div>
                                      <div class="my-3">
                                        Upgrade to <strong>£250</strong> of cover for a modest cost
                                        now, and enjoy extra peace of mind.
                                      </div>
                                      <div class="my-3">
                                        After a fault claim - focus on what matters most - getting
                                        back on the road safely and quickly.
                                      </div>
                                    </div>
                                    <div>
                                      <div class="mt-3 mb-4">
                                        For full information please read the product terms and
                                        conditions.
                                      </div>
                                      <DownloadsList
                                        class="my-3"
                                        :documents="addOn.Documents || []"
                                      />
                                    </div>
                                  </div>
                                </template>
                              </Modal>
                            </span>
                            <span v-else-if="addOnKey === 'BDC'">
                              <Modal size="max-w-3xl">
                                <template v-slot:trigger>
                                  <button type="button" class="text-[#95C11F] hover:opacity-80">
                                    &nbsp;Learn more.
                                  </button>
                                </template>
                                <template v-slot:title>
                                  <div>{{ addOn?.UpgradeBoxTitle || addOn?.Name }}</div>
                                </template>
                                <template v-slot:content>
                                  <div class="text-sm leading-tight">
                                    <div v-if="quote.policy?.brand === 'Zixty'">
                                      <div class="my-3">
                                        Don't wait until you're stranded on the side of the road -
                                        you never know when a breakdown might occur.
                                      </div>
                                      <div class="my-3">
                                        Select <strong>comprehensive breakdown</strong> cover now
                                        and Home Assist, nationwide Roadside and Recovery, and
                                        Onward Travel are just a phone call away.
                                      </div>
                                      <div class="my-3">
                                        Don't leave it to chance - your safety on the road is too
                                        important.
                                      </div>
                                    </div>
                                    <div v-if="quote.policy?.brand === 'Zixtyplus'">
                                      <div class="my-3">
                                        Car issues can strike anywhere, including at home, leaving
                                        your travel plans in tatters.
                                      </div>
                                      <div class="my-3">
                                        Upgrade to our comprehensive breakdown cover, and benefit
                                        from
                                        <strong>Home Assist</strong>, and
                                        <strong>Nationwide Recovery</strong> in addition to
                                        nationwide Roadside and local Recovery.
                                      </div>
                                      <div class="my-3">
                                        Don't leave it to chance - your safety on the road is too
                                        important.
                                      </div>
                                    </div>
                                    <div>
                                      <div class="mt-3 mb-4">
                                        For full information please read the product terms and
                                        conditions.
                                      </div>
                                      <DownloadsList
                                        class="my-3"
                                        :documents="addOn.Documents || []"
                                      />
                                    </div>
                                  </div>
                                </template>
                              </Modal>
                            </span>
                          </p>
                        </div>
                        <div class="pt-3">
                          <div class="flex flex-col items-end">
                            <button
                              type="button"
                              @click="policy.addOns[addOnKey] = true"
                              class="py-2.5 my-1.5 ml-1 rounded-full hover:bg-[#95C11F] text-white font-semibold tracking-wide w-44 justify-end"
                              :class="{
                                'bg-[#C3C3C4]':
                                  typeof policy.addOns[addOnKey] === 'undefined' ||
                                  policy.addOns[addOnKey] === false,
                                'bg-[#95C11F]': policy.addOns[addOnKey] === true
                              }"
                            >
                              Yes please
                            </button>
                            <button
                              type="button"
                              @click="policy.addOns[addOnKey] = false"
                              class="py-2.5 my-1.5 ml-1 rounded-full hover:bg-[#95C11F] text-white font-semibold tracking-wide w-44 justify-end"
                              :class="{
                                'bg-[#C3C3C4]':
                                  typeof policy.addOns[addOnKey] === 'undefined' ||
                                  policy.addOns[addOnKey] === true,
                                'bg-[#95C11F]': policy.addOns[addOnKey] === false
                              }"
                            >
                              No thanks
                            </button>
                            <div
                              class="upgrade-page-select-option text-right pt-2.5 mt-3 pl-3 md:pl-6 md:pr-1 min-h-[70px] md:min-h-[60px] text-xs md:text-sm text-[#FFB93F]"
                            >
                              <span
                                v-if="
                                  isAddOnsValid === false &&
                                  typeof policy.addOns[addOnKey] === 'undefined'
                                "
                              >
                                <span class="text-red-500">Please make a selection</span>
                              </span>
                              <span v-if="policy.addOns[addOnKey] === false">
                                <span v-if="addOnKey === 'BDC'">
                                  <span v-if="quote.policy?.brand === 'Zixty'">
                                    You will be responsible for any breakdown at home or while
                                    driving.
                                  </span>
                                  <span v-if="quote.policy?.brand === 'Zixtyplus'">
                                    You will be responsible for any breakdown at home or while
                                    driving.
                                  </span>
                                </span>
                                <span v-if="addOnKey === 'PE250'">
                                  <span v-if="quote.policy?.brand === 'Zixty'">
                                    You do not want to reduce your Effective Excess to £250 in the
                                    event of a fault claim.
                                  </span>
                                  <span v-if="quote.policy?.brand === 'Zixtyplus'">
                                    You do not want to reduce your Effective Excess to £250 in the
                                    event of a fault claim.
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-3/12 md:w-2/12 mx-3 relative">
                    <div class="absolute left-0 bottom-0">
                      <div class="-ml-9" v-if="!!addOn.Image">
                        <Image
                          :src="addOn.Image"
                          v-upgrade-box-image-max-height="{
                            uniqueRef: `upgrade-box-${addOnKey}:` + new Date().getTime(),
                            parentSelector: `#upgrade-box-${addOnKey}`,
                            percentage: 60,
                            pinToBottomOfParent: true
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="my-6 w-full mx-auto text-center">
              <Modal size="max-w-3xl">
                <template v-slot:trigger>
                  <span class="text-[#95C11F] hover:opacity-80 cursor-pointer">
                    Important information about your insurance policy and chosen extras
                  </span>
                </template>
                <template v-slot:title>
                  <span class="text-xl">More information about your products</span>
                </template>
                <template v-slot:content>
                  <div>
                    <div class="w-full mt-3">
                      <div class="text-sm">Documents for the Motor Policy</div>
                      <DownloadsList :documents="quote?.policy?.selectedBrand?.Documents || []" />
                    </div>
                    <div v-if="!!policy?.selectedAddOnsIncludingMandatory">
                      <div
                        v-for="(addOn, addOnKey) in policy.selectedAddOnsIncludingMandatory"
                        :key="addOnKey"
                      >
                        <div class="w-full mt-3" v-if="!!policy.selectedBrand?.AddOns[addOnKey]">
                          <div class="text-sm">Documents for {{ addOn.Name }}</div>
                          <DownloadsList :documents="addOn.Documents || []" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Modal>
            </div>

            <!--
            <div v-if='!isAggregator' class="w-full mt-3 mx-auto text-center">
              <div class="text-sm">Motor policy documents</div>
              <div class="flex flex-col items-center mx-auto w-[30%]">
                <DownloadsList :documents="quote?.policy?.selectedBrand?.Documents || []" />
              </div>
            </div>
            -->

            <div class="my-6 w-full mx-auto text-center">
              <div class="flex flex-col flex-wrap justify-center text-[#595959]">
                <div class="text-[#595959] text-3xl my-3 font-semibold tracking-wide">
                  Your quote
                </div>
                <QuoteFormTitle>
                  <span class="text-3xl">
                    <CurrencyValue :value="policy.total" :currency="policy.currency" />
                  </span>
                </QuoteFormTitle>
                <div class="text-sm text-[#a9a9a9] mt-3">
                  <span class="flex justify-center items-center"> including IPT & fees </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div class="py-3 md:py-6 w-full flex justify-center">
            <QuoteFormContinueButton type="submit" id="submit-go-to-declarations-button">
              Continue
            </QuoteFormContinueButton>
          </div>
          <BackButton
            :route-name="
              !isAggregator || !!quote.responses.aggregator?.revisions
                ? 'quote.quote'
                : 'quote.almost-there-buy'
            "
          />
        </form>
      </div>
    </div>
  </div>
</template>
