import { isAxiosError } from 'axios'
import type { AxiosError } from 'axios'
import type { ApiErrorResponse } from '@/utils/api'

export const isAxiosErrorCheck = <ResponseType>(
  error: unknown
): error is AxiosError<ResponseType> => {
  return isAxiosError(error)
}

export const isZixtyAxiosErrorCheck = (error: unknown): error is AxiosError<ApiErrorResponse> => {
  return isAxiosError(error)
}

export const asZodFlatErrors = <
  T extends {
    formErrors: unknown[]
    fieldErrors: Record<string | number | symbol, unknown[]>
  }
>(
  error: unknown,
  genericError: string = `Please check the form and try again`
): T => {
  let formErrors = [] as T['formErrors']
  let fieldErrors = {} as T['fieldErrors']

  if (isZixtyAxiosErrorCheck(error)) {
    formErrors = [error.response?.data?.what_to_say]
    fieldErrors = error.response?.data?.errors?.reduce((acc, { name, message }) => {
      if (!!name) {
        acc = {
          ...acc,
          [name]: [...(acc[name] || []), message]
        }
      }
      return acc
    }, fieldErrors) as T['fieldErrors']
  }

  if (!formErrors.length) {
    formErrors = [genericError]
  }

  return {
    formErrors,
    fieldErrors
  } as T
}
