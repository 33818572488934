<script setup lang="ts">
import { ref, computed } from 'vue'
import { useQuoteStore } from '@/stores/quote'
import Modal from '@/components/Modal.vue'
import AssumptionsCarText from '@/components/Quote/AssumptionsCarText.vue'
import AssumptionsCommercialVehicleText from '@/components/Quote/AssumptionsCommercialVehicleText.vue'

const quote = useQuoteStore()

const type = ref<string>(quote?.responses?.quickquote?.type_approval || 'M1')

const isCar = computed(() => type.value === 'M1')
const isCommercialVehicle = computed(() => type.value === 'N1')
</script>

<template>
  <Modal>
    <template v-slot:trigger>
      <slot></slot>
    </template>
    <template v-slot:title>
      <span v-if="isCar">SHORT PERIOD ASSUMPTIONS - CAR</span>
      <span v-if="isCommercialVehicle">SHORT PERIOD ASSUMPTIONS - COMMERCIAL VEHICLE</span>
    </template>
    <template v-slot:content>
      <div class="assumptions-text">
        <AssumptionsCarText v-if="isCar" />
        <AssumptionsCommercialVehicleText v-if="isCommercialVehicle" />
      </div>
    </template>
  </Modal>
</template>

<style lang="scss">
.assumptions-text {
  @apply text-[#a9a9a9] text-sm;
  p {
    @apply my-3;
  }
  ul {
    @apply list-disc mx-6 my-3;
  }
}
</style>
