<template>
  <div>
    <p><span> (V1.0 - 14th March 2023) </span></p>
    <p>
      <span
        >To give an indicative quote we&#39;ve made some general assumptions so we can generate a
        price. If you like the price and look to proceed we will need to gather a few more bits of
        information which may change the price from the indicative quote provided.
      </span>
    </p>
    <p>
      <span> We have made these assumptions to generate the indicative quote: </span>
    </p>
    <ul>
      <li>
        <span>
          An assumed average value for your vehicle that may be different to the current market
          value
        </span>
      </li>
      <li>
        <span> You are in an industry acceptable occupation </span>
      </li>
      <li>
        <span>
          You are a UK resident and have held a full UK driving licence for at least 12 months
        </span>
      </li>
      <li>
        <span> You are in good standing with credit reference agencies </span>
      </li>
    </ul>
    <p>
      <span
        >In addition, if you do look to buy a policy there are some assumptions and conditions that
        you should be aware of with all of our policies. We cannot help you if:
      </span>
    </p>
    <ul>
      <li>
        <span> You had more than 2 claims irrespective of blame within 3 years of cover date </span>
      </li>
      <li>
        <span> You have more than 6 declarable penalty points endorsed on your licence </span>
      </li>
      <li>
        <span>
          You have been banned from driving or had your licence revoked within 5 years of the cover
          date
        </span>
      </li>
      <li>
        <span>
          You have pending motoring convictions/prosecutions pending that will trigger the above
        </span>
      </li>
      <li>
        <span>
          You have criminal convictions not spent under the rehabilitation of offenders act
        </span>
      </li>
      <li>
        <span> You have had previous insurance policies cancelled/voided/refused </span>
      </li>
      <li>
        <span>
          You have medical conditions that have not been declared to the DVLA where required</span
        >
      </li>
      <li>
        <span>
          Your address is a mobile home/caravan site/houseboat or any other non fixed abode
        </span>
      </li>
      <li>
        <span>
          The vehicle is a tipper/lorry/dropside/flat-bed/motor caravan/motorhome/cherry
          picker/horsebox/refrigerated/mini bus/recovery vehicle/licenced taxi
        </span>
      </li>
      <li>
        <span> The gross vehicle weight (GVW) of the vehicle is above 3.5T </span>
      </li>
      <li>
        <span>
          The vehicle is modified (other than manufacturer options/tow bar/modified for disability)
        </span>
      </li>
      <li>
        <span>
          The vehicle will be used for anything other than social domestic and
          pleasure/commuting/business use/carriage of own goods only
        </span>
      </li>
      <li>
        <span> The vehicle has more than 7 seats as manufactured </span>
      </li>
      <li>
        <span> The vehicle will be used to carry hazardous/corrosive/explosive goods </span>
      </li>
      <li>
        <span> The vehicle will be used for any type of delivery or taxi service </span>
      </li>
      <li>
        <span> The vehicle is not right hand drive or not registered in the UK </span>
      </li>
      <li>
        <span>
          The vehicle does not have a valid MOT and is being used other than to drive to a
          pre-booked MOT
        </span>
      </li>
      <li>
        <span> The vehicle has been declared a Cat A/B write off </span>
      </li>
      <li>
        <span>
          The vehicle is being exported and will be used outside of the UK other than for leisure
          purposes
        </span>
      </li>
      <li>
        <span> The vehicle is valued over &pound;25,000 </span>
      </li>
    </ul>
    <p><span> </span></p>
    <p><span> </span></p>
    <p><span> Please note that: </span></p>
    <ul>
      <li>
        <span> Your policy will not provide stand alone windscreen or glass cover </span>
      </li>
      <li>
        <span> Your policy will not cover driving of other vehicles </span>
      </li>
      <li>
        <span> Your policy will not cover release of the vehicle from an impound centre </span>
      </li>
      <li>
        <span> Your policy will not cover rented/hire vehicles of any description </span>
      </li>
      <li>
        <span> No amendments/alterations or changes can be made once cover is in place </span>
      </li>
    </ul>
  </div>
</template>
