<script setup lang="ts">
import { useQuoteStore } from '@/stores/quote'
import { useRoute, useRouter } from 'vue-router'

export interface Props {
  fontWeight?: string
  color?: string
  textColor?: string
}

const props = withDefaults(defineProps<Props>(), {
  fontWeight: 'font-medium',
  color: 'bg-[#595959]',
  textColor: 'text-white'
})

const $route = useRoute()
const $router = useRouter()
const quote = useQuoteStore()

const restartQuote = (e: Event) => {
  // check for a partner
  const journey_type = $route?.params?.journey || 'direct'
  const partner = (document.querySelector('.layout-quote')?.id || '').split('-').pop() || ''

  // either send them to the direct vehicle route or a partner route
  let toRoute = $router.resolve({
    name: 'quote.vehicle',
    params: {
      journey: journey_type
    }
  })

  if (journey_type === 'partner' && !!partner && partner !== '') {
    toRoute = $router.resolve({
      name: 'partner.partner',
      params: {
        partner: partner
      }
    })
  }

  // reset the quote
  quote.helpers.restartQuote()

  // redirect using vue-router
  $router.push(toRoute)
}
</script>

<template>
  <button
    type="button"
    id="form-quote-sorry--restart-quote-button"
    class="p-3 px-8 rounded-full hover:opacity-90 cursor-pointer"
    :class="{
      [props.fontWeight]: true,
      [props.color]: true,
      [props.textColor]: true
    }"
    @click="restartQuote"
  >
    <slot></slot>
  </button>
</template>
