<script setup lang="ts">
import { computed } from 'vue'
import type { StartResponse } from '@/utils/api'

export interface Props {
  partner?: Partial<StartResponse['partner']>
}

const props = withDefaults(defineProps<Props>(), {
  //
})

const logoSize = computed(() => {
  const partnerName = String(props.partner?.code || '')
  const customSizes: Record<string, string> = {
    discountsforteachers: 'h-4',
    discountsforcarers: 'h-4',
    healthservicediscounts: 'h-4',
    charityworkerdiscounts: 'h-6',
    esure: 'h-6',
    firstalternative: 'h-24',
    sheilaswheels: 'h-20'
  }
  return customSizes[partnerName] || 'h-12'
})
</script>

<template>
  <div>
    <div class="text-center">
      <div v-if="!!partner?.name" class="text-[#C3C3C4] text-xs my-1 md:-mt-5">
        In partnership with
      </div>
      <div class="flex justify-center">
        <Image
          v-if="!!partner?.webquote_default_logo_path"
          :src="partner.webquote_default_logo_path"
          :alt="`Logo for ${partner?.name || 'partner'}`"
          :class="[logoSize]"
        />
      </div>
    </div>
  </div>
</template>
