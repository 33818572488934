<script setup lang="ts">
import { computed } from 'vue'

export interface Props {
  errors?: readonly string[]
  firstOnly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  firstOnly: true
})

const shownErrors = computed(() => (props?.errors || []).slice(0, props?.firstOnly ? 1 : undefined))
</script>

<template>
  <div v-if="shownErrors && !!shownErrors.length" class="form23a-error-message">
    <ul class="text-red-500 text-sm mt-1">
      <li v-for="err in shownErrors" :key="err">
        {{ err }}
      </li>
    </ul>
  </div>
</template>
