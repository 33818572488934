import Layout from '@/views/PolicyDocuments/Layout.vue'

import PolicyDocumentsView from '@/views/PolicyDocuments/PolicyDocumentsView.vue'

export const PolicyDocumentRoutes = [
  {
    path: '/my-documents',
    name: 'my-documents',
    component: Layout,
    children: [
      {
        path: ':state?',
        name: 'my-documents.root',
        component: PolicyDocumentsView
      }
    ]
  }
]
