<script lang="ts" setup>
import type { AlmostThereUKSchemaErrorsType } from '@/stores/quote.schema'
import IconInformation from '@/components/icons/IconInformation.vue'
import QuoteFormFieldErrors from '@/components/Quote/QuoteFormFieldErrors.vue'
import { useToggle } from '@vueuse/core'
import { useQuoteStore } from '@/stores/quote'
import { computed, onMounted, watch } from 'vue'
import { datelocalised } from '@/utils/date'

export interface Props {
  modelValue?: string
  errorsClient?: AlmostThereUKSchemaErrorsType | undefined
}

const props = withDefaults(defineProps<Props>(), {
  //
})

const $emits = defineEmits(['update:modelValue'])

const quote = useQuoteStore()

const [drivingLicenseHelpToggleValue, drivingLicenseHelpToggle] = useToggle()

// replace 'Required' error with more detail to the error message
const fieldErrors = computed(() => {
  let errors =
    props?.errorsClient?.fieldErrors?.driving_licence_number_section_a ||
    props?.errorsClient?.fieldErrors?.driving_licence_number_section_b ||
    props?.errorsClient?.fieldErrors?.driving_licence_number_section_c ||
    props?.errorsClient?.fieldErrors?.driving_licence_number_section_d ||
    props?.errorsClient?.fieldErrors?.driving_licence_number_ui ||
    props?.errorsClient?.fieldErrors?.driving_licence_number

  if (
    !!errors &&
    !!errors[0] &&
    errors[0] === 'Please enter a valid UK DVLA issued driving licence'
  ) {
    if (
      quote.inputs.driving_licence_number_section_d === '' ||
      quote.inputs.driving_licence_number_section_d?.length !== 4 ||
      !!quote.inputs.driving_licence_number_section_d.match(/\s/)
    ) {
      errors[0] =
        'Please enter the final 4 characters from your 16 digit driving licence number. These do not include the 2 digits that are set apart from the rest.'
    }
  }
  return errors
})

// DLN
const dlnComputeClean = (str: string) => {
  return str.toUpperCase().replace(/[^A-Z]/g, '')
}

const dlnComputeSectionA = (last_name: string) => {
  const base = dlnComputeClean(last_name).replace(/^MAC/i, 'MC')
  return (base + '9999').slice(0, 5)
}

const dlnComputeSectionB = (date_of_birth: Date, gender: string) => {
  const dob = datelocalised(date_of_birth)
  let year = dob.format('YY')
  let month = dob.format('MM')
  let day = dob.format('DD')

  if (gender === 'female') {
    month = (parseInt(month[0], 10) + 5).toString() + month[1]
  }

  return year[0] + month + day + year[1]
}

const dlnComputeSectionC = (first_name: string) => {
  return dlnComputeClean(first_name).slice(0, 1)
}

const dlnSectionAValue = computed(() => {
  return quote?.inputs?.last_name ? dlnComputeSectionA(quote.inputs.last_name) : ''
})

const dlnSectionBValue = computed(() => {
  return quote?.inputs?.date_of_birth && quote?.inputs?.gender
    ? dlnComputeSectionB(quote.inputs.date_of_birth, quote.inputs.gender)
    : ''
})

const dlnSectionCValue = computed(() => {
  return quote?.inputs?.first_name ? dlnComputeSectionC(quote.inputs.first_name) : ''
})

// watchers
watch(
  () => quote.inputs?.gender,
  () => {
    if (!!quote.inputs?.date_of_birth && !!quote.inputs?.gender) {
      quote.inputs.driving_licence_number_section_b = dlnSectionBValue.value
    }
  }
)

watch(
  () =>
    [
      quote.inputs.driving_licence_number_section_a || '',
      quote.inputs.driving_licence_number_section_b || '',
      quote.inputs.driving_licence_number_section_c || '',
      quote.inputs.driving_licence_number_section_d || ''
    ]
      .join('')
      .replace(' ', ''),
  (newVal: string, oldVal: string) => {
    $emits('update:modelValue', newVal)
  }
)

const isNumber = (evt: KeyboardEvent): void => {
  const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  const keyPressed: string = evt.key

  if (!keysAllowed.includes(keyPressed)) {
    evt.preventDefault()
  }
}

// for each section we check and set it if we don't have the value or if the underlying value has changed
const dlnCompute = () => {
  // first 5 characters of the licence (surname)
  if (
    quote.inputs?.last_name &&
    (!quote.inputs?.driving_licence_number_section_a ||
      quote.inputs?.driving_licence_number_section_a !== dlnSectionAValue.value)
  ) {
    quote.inputs.driving_licence_number_section_a = dlnSectionAValue.value
  }

  // next 6 characters of the licence (dob)
  if (
    quote.inputs?.date_of_birth &&
    quote.inputs?.gender &&
    (!quote.inputs?.driving_licence_number_section_b ||
      quote.inputs?.driving_licence_number_section_b !== dlnSectionBValue.value)
  ) {
    quote.inputs.driving_licence_number_section_b = dlnSectionBValue.value
  }

  // next 1 character of the licence (firstname initial)
  if (
    quote.inputs?.first_name &&
    (!quote.inputs?.driving_licence_number_section_c ||
      quote.inputs?.driving_licence_number_section_c.charAt(0) !== dlnSectionCValue.value.charAt(0))
  ) {
    quote.inputs.driving_licence_number_section_c = dlnSectionCValue.value
  }
}

const dlnComputedReady = computed((): boolean => {
  return (
    !!quote.inputs.driving_licence_number_section_a &&
    !!quote?.inputs?.driving_licence_number_section_b &&
    !!quote?.inputs?.gender
  )
})

// boot
onMounted(async () => {
  dlnCompute()
})
</script>

<template>
  <div
    class="form23a-field-group"
    :class="{
      error:
        !!errorsClient?.fieldErrors?.driving_licence_number_section_a ||
        !!errorsClient?.fieldErrors?.driving_licence_number_section_b ||
        !!errorsClient?.fieldErrors?.driving_licence_number_section_c ||
        !!errorsClient?.fieldErrors?.driving_licence_number_section_d ||
        !!errorsClient?.fieldErrors?.driving_licence_number_ui ||
        !!errorsClient?.fieldErrors?.driving_licence_number
    }"
    id="field-group-licence-number"
  >
    <div class="flex">
      <label class="form23a-label" for="driver_licence_number"> Driver's licence number </label>
      <button
        type="button"
        @click.prevent="($event) => drivingLicenseHelpToggle()"
        class="ml-1 -mt-1 text-[#95C11F] cursor-pointer"
      >
        <IconInformation class="h-5 w-5" />
      </button>
    </div>
    <div>
      <small class="my-1 mb-3 text-[#595959]">
        Please enter the final 4 digits of your driving licence number
      </small>
    </div>
    <div class="w-full">
      <div class="flex -ml-3">
        <div class="flex items-center">
          <div
            class="flex w-[8.25rem] items-center ml-3 text-sm border border-1 h-[50px] py-3 px-3 pr-4 select-none rounded-md focus:ring-0"
            :class="{
              'text-gray-300 border-gray-300 focus:border-gray-300': !dlnComputedReady,
              'text-gray-400 border-gray-400 focus:border-gray-400': !!dlnComputedReady
            }"
          >
            <span>{{ quote.inputs.driving_licence_number_section_a }}</span>
            <span>
              {{ quote.inputs.driving_licence_number_section_b || '' }}
            </span>
            <span>
              {{ quote?.inputs?.gender ? quote.inputs.driving_licence_number_section_c : '' }}
            </span>
          </div>
        </div>

        <input
          type="text"
          name="driver_licence_number_section_d"
          id="form-quote-almost-there--field-driver-licence-number-section-d"
          class="data-hj-allow form23a-input w-16 uppercase ml-3 my-1.5"
          placeholder=""
          maxlength="4"
          v-model="quote.inputs.driving_licence_number_section_d"
          v-capitalize-input
        />
      </div>

      <input
        type="text"
        name="driver_licence_number"
        id="form-quote-almost-there--field-driver-licence-number"
        class="form23a-input w-full uppercase hidden"
        placeholder="Enter driver's licence number..."
        v-model="quote.inputs.driving_licence_number_ui"
        v-capitalize-input
      />
    </div>
    <QuoteFormFieldErrors :errors="fieldErrors" />
    <div
      v-show="drivingLicenseHelpToggleValue"
      class="mt-3 text-xs text-[#595959] py-3 px-6 bg-[#F6F6F6] rounded-md"
    >
      <div class="flex justify-end -mr-3">
        <button
          type="button"
          class="cursor-pointer hover:text-[#95C11F] border-none focus:ring-0 focus-visible:outline-none"
          @click.prevent="drivingLicenseHelpToggle()"
          title="Close"
        >
          <span aria-hidden="true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
          <span class="sr-only">Close</span>
        </button>
      </div>
      <div class="mt-0.5 mx-auto">
        <Image
          src="/images/static/uk-driving-licence-example.png"
          class="shadow-md rounded-[15px]"
        />
      </div>
      <div class="mt-7 pb-3">
        <p class="mt-3">
          We only accept Full UK DVLA issued driving licences that have been held for 3+ months for
          car, and 12+ months for van.
        </p>
        <p class="mt-3">
          We require this information to provide your Driving Licence Number to the DVLA to confirm
          your licence status, entitlement, and restriction information and endorsement/ conviction
          data.
        </p>
        <p class="mt-3">
          By continuing you consent to us and any 3rd party company including insurers, to conduct a
          credit search to verify your identity. These checks may also include information held on
          claims and anti-fraud registers in the interests of preventing fraud. These checks include
          a 'soft search' on your credit file but does not affect your credit rating and will not be
          visible to potential lenders.
        </p>
      </div>
    </div>
  </div>
</template>
