export const REGEX_UK_MOBILE_NUMBER = /((07)|((\+|00)447)){1}[0-9]{9}\b/

// Simplified UK Driving Licence Regex using guide:
// https://www.gov.uk/government/publications/ins57p-information-on-driving-licences
export const REGEX_UK_DRIVING_LICENCE = /^[A-Z]{1,5}9{0,4}\d{6}[A-Z]{1}[A-Z9]{1}[A-Z0-9]{3}$/i

export const REGEX_UK_VEHICLE_REGISTRATION =
  /^([A-Z]{2}[0-9]{2}\s{0,1}[A-Z]{3})$|^([A-Z]{1}[0-9]{1,3}\s{0,1}[A-Z]{3})$|^([A-Z]{3}\s{0,1}[0-9]{1,3}[A-Z]{1})$|^([A-Z]{1,3}\s{0,1}[0-9]{1,4})$|^([0-9]{1,4}\s{0,1}[A-Z]{1,3})$/i

// Using a slightly modified version of the regex expression provided on
// https://stackoverflow.com/questions/164979/regex-for-matching-uk-postcodes
// by GOV UK. This version makes the spaces optional.
export const REGEX_UK_POSTCODE =
  /^([Gg][Ii][Rr] ?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$/

// Useful unicode ref = https://jrgraphix.net/r/Unicode/
// this includes:
// - space
// - full stop
// - apostrophe
// - hyphen
// - Upper and Lower Case from base Latin Unicode
//     - SO FAR = \u0020\u0027\u002D-\u002E\u0041-\u005A\u0061-\u007A
// - Letter like characters in Latin-1 Supplement
//     - \u00C0-\u00FF
// - All characters in Latin Extended A
//     - \u0100-\u017F
// - All characters in Latin Extended B
//     - \u180-\u24F
// - All characters in Latin Extended Additional
//     - \u1E00-\u1EFF
// Together:
export const REGEX_PERSON_NAME =
  /^[\u0020\u0027\u002D-\u002E\u0041-\u005A\u0061-\u007A\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF]+$/

export const DATE_FORMAT = 'DD/MM/YYYY'

export const CRISP_CHAT_WEBSITE_ID = '91b70d06-85a1-4651-a27c-6f7069e71e13'
