<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'

export interface Props {
  text: string
  dots: number
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  dots: 3
})

const createArrayFilledWith = (count: number, fillWith: string = '.') => {
  return Array(count)
    .fill(fillWith)
    .map((val, i) => {
      return Array(i + 1)
        .fill(fillWith)
        .join('')
    })
}

const timer = ref<ReturnType<typeof setInterval> | null>(null)
const displayedDots = ref<string[]>(createArrayFilledWith(props.dots))
const dotsShownIndex = ref<number>(0)

onMounted(async () => {
  timer.value = setInterval(() => {
    dotsShownIndex.value =
      dotsShownIndex.value === displayedDots.value.length ? 0 : dotsShownIndex.value + 1
  }, 750)
})

onBeforeUnmount(async () => {
  if (!!timer.value) {
    clearInterval(timer.value)
    timer.value = null
  }
})
</script>

<template>
  <div class="flex items-center">
    <div class="mr-0.5">
      {{ text }}
    </div>
    <div v-if="!!displayedDots && !!displayedDots.length" class="w-[14.5px] text-left">
      {{ displayedDots[dotsShownIndex] }}
    </div>
  </div>
</template>
