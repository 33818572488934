<script setup lang="ts">
import { onMounted, nextTick } from 'vue'
import { useHead } from '@vueuse/head'
import { useQuoteStore } from '@/stores/quote'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import { delay } from '@/utils/helpers'

useHead({
  title: 'Sorry - Zixty Quote'
})

const quote = useQuoteStore()

const onSubmit = (e: Event) => {
  //
}

onMounted(async () => {
  if (quote.responses?.correlation_id) {
    quote.helpers.recordWebEvent('SORRY')
  }

  // clear these as they are no longer needed
  await delay(100)
  quote.responses.correlation_id = ''
  quote.responses.quote_expires_at = ''
})
</script>

<template>
  <div class="w-full">
    <div class="pt-6 mx-auto">
      <div class="py-3 md:py-6 flex justify-center text-center">
        <QuoteFormTitle> Sorry, not this time </QuoteFormTitle>
      </div>
      <div class="mx-auto">
        <form id="form-quote-sorry" class="mx-auto" @submit.prevent="onSubmit" novalidate>
          <div
            class="py-3 w-full flex flex-col justify-center text-center text-[#595959] max-w-lg mx-auto"
          >
            <p class="my-1.5">Sorry - it looks like we can't offer you cover right now.</p>
            <p class="my-1.5">
              We're continuously working to offer eco friendly short term car and van insurance to
              more people, so please check back in the future.
            </p>
            <p class="my-1.5">Thanks for thinking of Zixty.</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
