<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import tippy from 'tippy.js'
import IconInformation from '@/components/icons/IconInformation.vue'

export interface Props {
  content?: string
}

const props = withDefaults(defineProps<Props>(), {
  content: '?'
})

const tippyel = ref()
const tippyinstance = ref()

onMounted(() => {
  tippyinstance.value = tippy(tippyel.value, {
    content: props.content,
    trigger: 'click'
  })
})

onBeforeUnmount(() => {
  tippyinstance.value = undefined
})
</script>

<template>
  <div ref="tippyel" class="text-[#95C11F] cursor-pointer ml-1">
    <IconInformation class="h-5 w-5" />
  </div>
</template>
