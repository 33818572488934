<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useQuoteStore } from '@/stores/quote'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import QuoteFormContinueButton from '@/components/Quote/QuoteFormContinueButton.vue'
import BackButton from '@/components/Quote/BackButton.vue'
import IconCheck from '@/components/icons/IconCheck.vue'
import ToolTip from '@/components/Tooltip.vue'
import AssumptionsModal from '@/components/Quote/AssumptionsModal.vue'
import CurrencyValue from '@/components/Quote/CurrencyValue.vue'
import { onMounted } from 'vue'

const $router = useRouter()

const quote = useQuoteStore()

useHead({
  title: 'Quick Quote - Zixty Quote'
})

const highlights = [
  { label: 'Comprehensive cover' },
  { label: 'On the road in minutes' },
  { label: 'Flexible cover' },
  { label: 'Upgrades available' },
  { label: 'FREE carbon offsetting' }
]

const onSubmit = (e: Event) => {
  $router.push({
    name: 'quote.almost-there'
  })
}

onMounted(() => {
  quote.helpers.recordWebEvent('QUICK_QUOTE')
})
</script>

<template>
  <div class="w-full">
    <div class="pt-6 mx-auto lg:w-7/12">
      <div class="py-3 md:py-6 flex justify-center items-center text-center">
        <QuoteFormTitle>Your quick quote</QuoteFormTitle>
      </div>
      <div class="mx-auto">
        <form id="form-quote-quick-quote" @submit.prevent="onSubmit" novalidate>
          <div class="py-3 w-full text-center">
            <QuoteFormTitle>
              <span class="text-[#595959]">
                <CurrencyValue
                  currency="GBP"
                  :value="quote.responses?.quickquote?.quote?.premium || 0"
                />
              </span>
            </QuoteFormTitle>
            <div class="text-sm text-[#a9a9a9] mt-3">
              <AssumptionsModal>
                <span class="mr-1">Based on these</span>
                <span class="text-[#95C11F] hover:opacity-80 cursor-pointer">assumptions</span>
              </AssumptionsModal>
              <span class="flex justify-center items-center mt-1">
                including IPT & fees
                <span class="mx-0.5"></span>
                <ToolTip
                  class="self-center"
                  content="We make assumptions to work out your indicative price. Additional answers are required when you buy a policy through the app which may affect your final price."
                />
              </span>
            </div>
          </div>
          <div class="py-3 pt-6 w-full flex flex-col justify-center text-[#595959]">
            <div class="w-54 mx-auto">
              <div class="flex items-center my-1" v-for="item in highlights">
                <div class="text-[#95C11F]">
                  <IconCheck class="w-4 h-4" />
                </div>
                <div class="ml-2">{{ item.label }}</div>
              </div>
            </div>
          </div>
          <div class="pt-6 w-full flex flex-col justify-center text-[#595959] text-center">
            <h2 class="text-[#a9a9a9] text-xl md:text-2xl font-thin">Sound good?</h2>
          </div>
          <div class="py-3 md:py-6 w-full flex justify-center">
            <QuoteFormContinueButton type="submit" id="submit-go-full-quote-button">
              Continue
            </QuoteFormContinueButton>
          </div>
          <BackButton route-name="quote.driver-details" />
        </form>
      </div>
    </div>
  </div>
</template>
