<script setup lang="ts">
import { useQuoteStore } from '@/stores/quote'
import IconCross from '@/components/icons/IconCross.vue'
import IconCheck from '@/components/icons/IconCheck.vue'
import IconPlus from '@/components/icons/IconPlus.vue'
import CurrencyValue from '@/components/Quote/CurrencyValue.vue'
import Modal from '@/components/Modal.vue'
import EffectiveExcessDetailBreakdown from '@/components/Quote/EffectiveExcessDetailBreakdown.vue'

const quote = useQuoteStore()
</script>

<template>
  <div class="mx-auto w-full">
    <div class="w-full flex items-center mb-8">
      <div class="w-6/12">&nbsp;</div>
      <div class="w-3/12">
        <span
          class="flex justify-center text-center items-end cursor-pointer mt-3 md:mt-4"
          @click="quote.policy.brand = 'Zixty'"
        >
          <img src="/images/logo.png" class="w-16 md:w-20" />
        </span>
      </div>
      <div class="w-3/12">
        <span
          class="flex justify-center text-center items-end cursor-pointer"
          @click="quote.policy.brand = 'Zixtyplus'"
        >
          <img src="/images/logo-plus.png" class="w-16 md:w-20" />
        </span>
      </div>
    </div>

    <div class="w-full flex">
      <div class="w-6/12 text-[#595959] p-4 font-semibold text-left text-xs">
        <div class="flex items-center">Breakdown cover</div>
        <div class="mt-1">
          <Modal size="max-w-2xl">
            <template v-slot:trigger>
              <span class="cursor-pointer text-[#95C11F] font-thin text-xs mt-3">more info</span>
            </template>
            <template v-slot:title>
              <div>Key features: Breakdown Cover</div>
            </template>
            <template v-slot:content>
              <div class="text-sm font-normal text-[#595959]">
                <div v-if="quote.policy?.brand === 'Zixty'">
                  <div class="my-3">
                    <p>
                      Our Breakdown cover is provided by Call Assist which includes, but is not
                      limited to, the following key features:
                    </p>
                  </div>
                  <div class="my-3">
                    <ul class="list-disc ml-6">
                      <li>
                        Nationwide Roadside Assistance - Up to 60 minutes to attempt to rectify the
                        issue.
                      </li>
                      <li>Nationwide Recovery</li>
                      <li>Home Assist - Cover at home or within 1 mile of your home address</li>
                      <li>Access to over 3,500 approved technicians</li>
                    </ul>
                  </div>
                  <div class="my-3">
                    <p>Cover duration is linked to the main insurance policy</p>
                  </div>
                  <div class="my-3">
                    <p>Only covers you in the car on the main insurance policy</p>
                  </div>
                  <div class="my-3">
                    <p>For full information please read the product terms and conditions.</p>
                  </div>
                </div>

                <div v-if="quote.policy?.brand === 'Zixtyplus'">
                  <div class="my-3">
                    <p>
                      Zixty Rescue is breakdown cover provided by Call Assist, and includes, but is not
                      limited, to the following key features:
                    </p>
                  </div>
                  <div class="my-3">
                    <ul class="list-disc ml-6">
                      <li>
                        Nationwide Roadside Assistance - Up to 60 minutes to attempt to rectify the issue
                      </li>
                      <li>
                        Local Recovery - Up to 10 miles from the scene of the breakdown
                      </li>

                      <li>Access to over 3,500 approved technicians</li>
                    </ul>
                  </div>
                  <div class="my-3">
                    <p>
                      If you choose to upgrade your Zixty Rescue breakdown cover, you will additionally get the following:
                    </p>
                  </div>
                  <div class="my-3">
                    <ul class="list-disc ml-6">
                      <li>
                        Home Assist - Cover at home or within 1 mile of your home address
                      </li>
                      <li>
                        Nationwide Recovery - To the nearest suitable garage, home or destination (terms apply)
                      </li>
                    </ul>
                  </div>
                  <div class="my-3">
                    <p>Cover duration is linked to the main motor insurance policy, and only covers you in the car on the main motor insurance policy.</p>
                  </div>
                  <div class="my-3">
                    <p>For full information please read the product terms and conditions.</p>
                  </div>
                </div>
              </div>
            </template>
          </Modal>
        </div>
      </div>
      <div
        class="w-3/12 text-[#595959] p-4 font-semibold flex items-center justify-center text-sm border-2 border-b-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixty',
          'border-[#FFFFFF]': quote.policy?.brand !== 'Zixty'
        }"
      >
        <div class="mx-auto w-6 text-[#95C11F]">
          <IconPlus />
        </div>
      </div>
      <div
        class="w-3/12 text-[#595959] p-4 font-semibold flex items-center justify-center text-sm border-2 border-b-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixtyplus',
          'border-[#FFFFFF]': quote.policy?.brand !== 'Zixtyplus'
        }"
      >
        <div class="mx-auto w-6 text-[#95C11F]">
          <IconCheck />
        </div>
      </div>
    </div>

    <div class="w-full flex">
      <div class="w-6/12 text-[#595959] p-4 font-semibold text-left text-xs">
        <div class="flex items-center">Legal Expenses</div>
        <div class="mt-1">
          <Modal size="max-w-2xl">
            <template v-slot:trigger>
              <span class="cursor-pointer text-[#95C11F] font-thin text-xs mt-3">more info</span>
            </template>
            <template v-slot:title>
              <div>Legal Expenses</div>
            </template>
            <template v-slot:content>
              <div class="text-sm font-normal text-[#595959]">
                <div v-if="quote.policy?.brand === 'Zixty'">
                  <div class="my-3">
                    <p>
                      Legal Expenses cover is only available as part of Zixty Plus policies. When
                      available it provides financial assistance for legal proceedings relating to
                      defined events. This policy is provided by Winn Group, and includes, but is
                      not limited to, the following key features:
                    </p>
                  </div>
                  <div class="my-3">
                    <ul class="list-disc ml-6">
                      <li>Costs of legal proceedings up to £100,000</li>
                      <li>
                        Covers personal injury and/ or losses sustained in a road traffic accident;
                        uninsured loss recovery; Motor Insurance Database disputes.
                      </li>
                      <li>
                        Vehicle Hire, Repair and Storage Charges – unrecovered charges incurred
                        under a hire, repair, or storage agreement through On Hire up to £50,000.
                      </li>
                    </ul>
                  </div>
                  <div class="my-3">
                    <p>For full information please read the product terms and conditions.</p>
                  </div>
                </div>
                <div v-if="quote.policy?.brand === 'Zixtyplus'">
                  <div class="my-3">
                    <p>
                      Legal Expenses cover is included as standard with Zixty Plus policies. It
                      provides financial assistance for legal proceedings relating to defined
                      events. This policy is provided by Winn Group, and includes, but is not
                      limited to, the following key features:
                    </p>
                  </div>
                  <div class="my-3">
                    <ul class="list-disc ml-6">
                      <li>Costs of legal proceedings up to £100,000</li>
                      <li>
                        Covers personal injury and/ or losses sustained in a road traffic accident;
                        uninsured loss recovery; Motor Insurance Database disputes.
                      </li>
                      <li>
                        Vehicle Hire, Repair and Storage Charges – unrecovered charges incurred
                        under a hire, repair, or storage agreement through On Hire up to £50,000.
                      </li>
                    </ul>
                  </div>
                  <div class="my-3">
                    <p>For full information please read the product terms and conditions.</p>
                  </div>
                </div>
              </div>
            </template>
          </Modal>
        </div>
      </div>
      <div
        class="w-3/12 text-[#595959] p-4 font-semibold py-6 flex items-center justify-center text-sm"
        :class="{
          'border-2 border-[#95C11F] border-b-0 border-t-0': quote.policy?.brand === 'Zixty'
        }"
      >
        <div class="mx-auto w-6 text-[#C3C3C4]">
          <IconCross />
        </div>
      </div>
      <div
        class="w-3/12 text-[#595959] p-4 font-semibold flex items-center justify-center text-sm border-2 border-b-0 border-t-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixtyplus',
          'border-[#FFFFFF]': quote.policy?.brand !== 'Zixtyplus'
        }"
      >
        <div class="mx-auto w-6 text-[#95C11F]">
          <IconCheck />
        </div>
      </div>
    </div>

    <div class="w-full flex">
      <div class="w-6/12 text-[#595959] bg-[#F6F6F6] p-4 font-semibold text-left text-xs">
        Your Excess
      </div>
      <div
        class="w-3/12 text-[#595959] bg-[#F6F6F6] p-4 font-semibold flex items-center justify-center text-sm border-2 border-b-0 border-t-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixty',
          'border-[#F6F6F6]': quote.policy?.brand !== 'Zixty'
        }"
      >
        <CurrencyValue
          :value="quote?.policy?.selectableBrands?.Zixty?.PolicyExcess || 0"
          :minimum-fraction-digits="0"
          currency="GBP"
        />
      </div>
      <div
        class="w-3/12 text-[#595959] bg-[#F6F6F6] p-4 font-semibold flex items-center justify-center text-sm border-2 border-b-0 border-t-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixtyplus',
          'border-[#F6F6F6]': quote.policy?.brand !== 'Zixtyplus'
        }"
      >
        <CurrencyValue
          :value="quote?.policy?.selectableBrands?.Zixtyplus?.PolicyExcess || 0"
          :minimum-fraction-digits="0"
          currency="GBP"
        />
      </div>
    </div>

    <div class="w-full flex">
      <div class="w-6/12 text-[#595959] bg-[#F6F6F6] p-4 font-semibold text-left text-xs">
        <div class="flex items-center">Excess Protect</div>
        <div class="mt-1">
          <Modal size="max-w-2xl">
            <template v-slot:trigger>
              <span class="cursor-pointer text-[#95C11F] font-thin text-xs mt-3">more info</span>
            </template>
            <template v-slot:title>
              <div>Key features: Excess Protect</div>
            </template>
            <template v-slot:content>
              <div class="text-sm font-normal text-[#595959]">
                <div v-if="quote.policy?.brand === 'Zixty'">
                  <div class="my-3">
                    <p>
                      Excess Protection is optional and provides reimbursement of part of your motor
                      insurance excess in the event of a fault claim. The policy is provided by
                      Universal insurance and includes, but is not limited to, the following key
                      features:
                    </p>
                  </div>
                  <div class="my-3">
                    <ul class="list-disc ml-6">
                      <li>£250 cover standard cover</li>
                      <li>Covers fault accidents/ fire/ theft/ vandalism, and flood</li>
                    </ul>
                  </div>
                  <div class="my-3">
                    <p>
                      The Excess must be paid to the motor insurer, and then reclaimed via Universal
                      insurance
                    </p>
                  </div>
                  <div class="my-3">
                    <p>For full information please read the product terms and conditions.</p>
                  </div>
                </div>
                <div v-if="quote.policy?.brand === 'Zixtyplus'">
                  <div class="my-3">
                    <p>
                      Excess Protect is included as standard with every Zixty Plus policy and
                      provides reimbursement of part of your motor insurance excess in the event of
                      a fault claim. The policy is provided by Universal insurance and includes, but
                      is not limited to, the following key features:
                    </p>
                  </div>
                  <div class="my-3">
                    <ul class="list-disc ml-6">
                      <li>£100 cover as standard with Zixty Plus</li>
                      <li>£250 total cover upgrade available</li>
                      <li>Covers fault accidents/ fire/ theft/ vandalism, and flood</li>
                    </ul>
                  </div>
                  <div class="my-3">
                    <p>
                      The Excess must be paid to the motor insurer, and then reclaimed via Universal
                      insurance
                    </p>
                  </div>
                  <div class="my-3">
                    <p>For full information please read the product terms and conditions.</p>
                  </div>
                </div>
              </div>
            </template>
          </Modal>
        </div>
      </div>
      <div
        class="w-3/12 text-[#595959] bg-[#F6F6F6] p-4 font-semibold flex items-center justify-center text-sm border-2 border-b-0 border-t-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixty',
          'border-[#F6F6F6]': quote.policy?.brand !== 'Zixty'
        }"
      >
        <div class="mx-auto w-6 text-[#95C11F]">
          <IconPlus />
        </div>
      </div>
      <div
        class="w-3/12 text-[#595959] bg-[#F6F6F6] p-4 font-semibold flex items-center justify-center text-sm border-2 border-b-0 border-t-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixtyplus',
          'border-[#F6F6F6]': quote.policy?.brand !== 'Zixtyplus'
        }"
      >
        <div>£100</div>
      </div>
    </div>

    <div class="w-full flex">
      <div class="w-6/12 text-[#595959] bg-[#F6F6F6] p-4 font-semibold text-left text-xs">
        <div class="flex items-center">Effective Excess</div>
        <div class="mt-1">
          <Modal size="max-w-2xl">
            <template v-slot:trigger>
              <span class="cursor-pointer text-[#95C11F] font-thin text-xs mt-3">more info</span>
            </template>
            <template v-slot:title>
              <div>Key features: Effective Excess</div>
            </template>
            <template v-slot:content>
              <div class="text-sm font-normal text-[#595959]">
                <EffectiveExcessDetailBreakdown />
              </div>
            </template>
          </Modal>
        </div>
      </div>
      <div
        class="w-3/12 text-[#595959] bg-[#F6F6F6] p-4 font-semibold flex items-center justify-center text-sm border-2 border-b-0 border-t-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixty',
          'border-[#F6F6F6]': quote.policy?.brand !== 'Zixty'
        }"
      >
        £500
      </div>
      <div
        class="w-3/12 text-[#595959] bg-[#F6F6F6] p-4 font-semibold flex items-center justify-center text-sm border-2 border-b-0 border-t-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixtyplus',
          'border-[#F6F6F6]': quote.policy?.brand !== 'Zixtyplus'
        }"
      >
        £400
      </div>
    </div>

    <div class="w-full flex">
      <div class="w-6/12 text-[#595959] p-4 font-semibold text-left text-sm md:text-md">
        <div class="flex flex-col">
          <span> Total Premium </span>
          <span class="font-thin text-xs mt-0.5">Excludes add-ons</span>
        </div>
      </div>
      <div
        class="w-3/12 text-[#595959] font-semibold p-4 text-center flex items-center justify-center text-sm md:text-md border-2 border-t-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixty',
          'border-[#FFFFFF]': quote.policy?.brand !== 'Zixty'
        }"
      >
        <div class="flex justify-center items-center text-center">
          <CurrencyValue
            :value="quote.policy?.selectableBrands?.Zixty?.PremiumInclFeeWithMandatoryAddOns || 0"
            currency="GBP"
          />
        </div>
      </div>
      <div
        class="w-3/12 text-[#595959] font-semibold p-4 flex items-center justify-center text-sm md:text-md border-2 border-t-0"
        :class="{
          'border-[#95C11F]': quote.policy?.brand === 'Zixtyplus',
          'border-[#FFFFFF]': quote.policy?.brand !== 'Zixtyplus'
        }"
      >
        <div class="flex justify-center items-center text-center">
          <CurrencyValue
            :value="
              quote.policy?.selectableBrands?.Zixtyplus?.PremiumInclFeeWithMandatoryAddOns || 0
            "
            currency="GBP"
          />
        </div>
      </div>
    </div>

    <div class="w-full flex">
      <div class="w-6/12">&nbsp;</div>
      <div
        class="w-3/12 py-6 pb-1 text-center cursor-pointer"
        @click.prevent="quote.policy.brand = 'Zixty'"
      >
        <button
          class="text-white rounded-full px-4 py-1.5 md:px-6 text-xs md:text-sm font-semibold tracking-wide"
          :class="{
            'bg-[#C3C3C4]': quote.policy.brand !== 'Zixty',
            'bg-[#95C11F]': quote.policy.brand === 'Zixty'
          }"
        >
          Select
        </button>
      </div>
      <div
        class="w-3/12 py-6 pb-1 text-center cursor-pointer"
        @click.prevent="quote.policy.brand = 'Zixtyplus'"
      >
        <button
          class="text-white rounded-full px-4 py-1.5 md:px-6 text-xs md:text-sm font-semibold tracking-wide"
          :class="{
            'bg-[#C3C3C4]': quote.policy.brand !== 'Zixtyplus',
            'bg-[#95C11F]': quote.policy.brand === 'Zixtyplus'
          }"
        >
          Select
        </button>
      </div>
    </div>
  </div>
</template>
