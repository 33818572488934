<script setup lang="ts">
import CurrencyValue from '@/components/Quote/CurrencyValue.vue'

export interface Props {
  title: string
  value: number | string
  currency: 'GBP'
  locale?: string
  minimumFractionDigits?: number
  trackHotjar?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  value: 0,
  currency: 'GBP',
  locale: 'en',
  minimumFractionDigits: 2,
  trackHotjar: false
})
</script>

<template>
  <div class="flex flex-wrap justify-between w-full py-1 items-start">
    <span
      class="text-[#595959] text-xs md:text-sm font-normal px-2"
      :class="{
        'w-2/5': true
      }"
    >
      <span v-if="!!$slots?.title"><slot name="title" /></span>
      <span v-else v-html="props.title"></span>
    </span>
    <span
      class="text-[#414042] text-xs md:text-sm font-semibold px-2"
      :class="{
        'w-2/5': !!$slots.action,
        'w-3/5': !$slots.action,
        'data-hj-allow': !!trackHotjar,
        'data-hj-suppress': !trackHotjar
      }"
    >
      <CurrencyValue
        :value="props.value"
        :currency="props.currency"
        :locale="props.locale"
        :minimum-fraction-digits="props.minimumFractionDigits"
      />
    </span>
    <span
      v-if="!!$slots.action"
      class="text-[#95C11F] text-xs md:text-sm font-normal px-2"
      :class="{
        'w-1/5': true
      }"
    >
      <slot name="action"></slot>
    </span>
  </div>
</template>
