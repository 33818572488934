<script setup lang="ts">
import DetailLineCurrencyValue from '@/components/Quote/DetailLineCurrencyValue.vue'
import CurrencyValue from './CurrencyValue.vue'
import { useQuoteStore } from '@/stores/quote'

const quote = useQuoteStore()
</script>

<template>
  <div>
    <div v-if="quote?.policy.brand === 'Zixty'">
      <div class="my-3">Your standard policy Excess is £500</div>
      <div class="my-3">
        <span>
          In the event of a claim against your policy, you will need to pay your £500 Excess.
        </span>
        <span v-if="!!quote?.policy?.addOns?.PE250" class="ml-0.25">
          You will then need to claim £250 of your Excess from our Excess protection provider -
          Universal Insurance.
        </span>
      </div>
      <div class="my-3">For full information please read the product terms and conditions.</div>
      <div class="my-3">
        <div class="-ml-2">
          <DetailLineCurrencyValue
            :track-hotjar="true"
            title="Standard Excess"
            :value="500"
            :currency="quote.policy.currency"
            :minimum-fraction-digits="0"
          />
          <DetailLineCurrencyValue
            :track-hotjar="true"
            title="Your Excess protection"
            :value="!!quote?.policy?.addOns?.PE250 ? 250 : 0"
            :currency="quote.policy.currency"
            :minimum-fraction-digits="0"
          />
          <DetailLineCurrencyValue
            :track-hotjar="true"
            title="Effective Excess"
            :value="quote.policy.effectiveExcessValue"
            :currency="quote.policy.currency"
            :minimum-fraction-digits="0"
          />
        </div>
      </div>
    </div>

    <div v-if="quote?.policy.brand === 'Zixtyplus'">
      <div class="my-3">
        Your standard policy Excess is
        <CurrencyValue :value="500" currency="GBP" :minimum-fraction-digits="0" />.
      </div>
      <div class="my-3">
        Your Zixty Plus policy includes £100 of Excess protection as standard. In the event of a
        claim against your policy, you will need to pay your £500 Excess. You will then need to
        claim your
        <CurrencyValue
          :value="!!quote?.policy?.addOns?.PE250 ? 250 : 100"
          currency="GBP"
          :minimum-fraction-digits="0"
        />
        protected Excess from our Excess protection provider - Universal Insurance.
      </div>
      <div class="my-3">For full information please read the product terms and conditions.</div>
      <div class="my-3">
        <div class="-ml-2">
          <DetailLineCurrencyValue
            :track-hotjar="true"
            title="Standard Excess"
            :value="500"
            :currency="quote.policy.currency"
            :minimum-fraction-digits="0"
          />
          <DetailLineCurrencyValue
            :track-hotjar="true"
            title="Your Excess protection"
            :value="!!quote?.policy?.addOns?.PE250 ? 250 : 100"
            :currency="quote.policy.currency"
            :minimum-fraction-digits="0"
          />
          <DetailLineCurrencyValue
            :track-hotjar="true"
            title="Effective Excess"
            :value="quote.policy.effectiveExcessValue"
            :currency="quote.policy.currency"
            :minimum-fraction-digits="0"
          />
        </div>
      </div>
    </div>
  </div>
</template>
