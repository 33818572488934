<script setup lang="ts">
import { ref, watch, toRef } from 'vue'
import { useRoute, useRouter, type RouteRecordName } from 'vue-router'
import { set } from '@vueuse/core'
import Footer from '@/components/Footer.vue'
import { useQuoteStore } from '@/stores/quote'
import HeaderPartnershipBadge from '@/components/HeaderPartnershipBadge.vue'
import LinkExternal from '@/components/LinkExternal.vue'

const $route = useRoute()
const $router = useRouter()

const quote = useQuoteStore()
const partner = toRef(quote.responses, 'partner')

// set the partner name, either 'direct', from the query string, or from the store
const partnerName = ref<string>()
if (!!$route?.query?.partner && typeof $route?.query?.partner === 'string') {
  partnerName.value = $route.query.partner
} else if (!!partner?.value?.name) {
  partnerName.value = partner?.value?.name
}

// set the layoutid
const layoutId = ref<string>(
  `quote-journey-${partnerName.value || 'direct'}`.replace(' ', '_').toLowerCase()
)

// logic to figure out if vehicle page... which has it's own template
const isLandingPage = ref<boolean>(false)

const getRouteName = (): RouteRecordName => $route?.name || ''
const updateIsLandingPage = (newName: RouteRecordName) =>
  set(isLandingPage, !!newName && newName === 'quote.vehicle')

watch(getRouteName, updateIsLandingPage)

updateIsLandingPage(getRouteName())

const sendToDirectJourney = () => {
  $router.push({
    name: 'quote.vehicle',
    params: {
      journey: 'direct'
    },
    query: $route.query ? { ...$route.query } : {}
  })
}

if ($route?.params?.journey) {
  // send the user to the direct journey if they are on the partner url without a partner value
  if ($route?.params?.journey === 'partner' && !partnerName.value) {
    sendToDirectJourney()
  }
} else {
  // logic to direct the user to the 'direct journey' if they land on this page directly
  sendToDirectJourney()
}
</script>

<template>
  <div :id="layoutId" class="flex layout-quote">
    <div class="w-full bg-white">
      <div class="w-full flex">
        <div class="w-full px-6 py-3 pb-3 md:px-12 md:pt-12 md:pb-6 md:w-2/3">
          <div class="container mx-auto">
            <div
              id="header"
              class="flex flex-col items-center md:items-start md:justify-between px-6"
            >
              <LinkExternal href="https://zixty.com/" title="Zixty Homepage">
                <Image src="/images/logo.png" class="h-12" />
              </LinkExternal>
              <HeaderPartnershipBadge
                v-if="!!isLandingPage && !!partner?.webquote_default_logo_path"
                class="md:hidden flex flex-col items-center justify-center"
                :partner="partner"
              />
            </div>
          </div>
        </div>
        <!-- blank to make the page flow the same as the other pages -->
        <div class="hidden md:block md:w-1/3 max-w-[650px] md:px-12 md:pt-12">
          <HeaderPartnershipBadge
            v-if="!!isLandingPage && !!partner?.webquote_default_logo_path"
            class="flex flex-col items-end justify-center"
            :partner="partner"
          />
        </div>
      </div>
      <div class="w-full flex">
        <div class="min-h-[80vh] w-full px-6 py-6 md:px-12 md:py-12">
          <div id="content" class="h-full flex px-3 py-3 md:px-6 md:py-6">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isLandingPage"
      class="hidden md:block md:w-1/3 max-w-[650px] overflow-hidden bg-gradient-to-r from-[#BCCF00] via-[#95C11F] to-[#52AE32] py-3 pl-6"
    >
      <div class="flex flex-wrap h-screen p-3">
        <div class="w-full justify-end p-3 px-6">
          <Image src="/images/static/quote-and-buy-sun-image.png" class="w-24 float-right" />
        </div>
        <div class="w-full self-end">
          <Image src="/images/static/quote-and-buy-image.png" class="-ml-10 max-w-[700px]" />
        </div>
      </div>
    </div>
  </div>
  <div id="footer">
    <Footer />
  </div>
</template>
