import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import type { Dayjs } from 'dayjs'

export const dateutc = (...args: Parameters<typeof dayjs>): Dayjs => {
  dayjs.extend(isToday)
  dayjs.extend(isTomorrow)
  dayjs.extend(advancedFormat)
  dayjs.extend(customParseFormat)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  return dayjs(...args).utc()
}

export const datelocalised = (...args: Parameters<typeof dayjs>): Dayjs => {
  return dateutc(...args).local()
}
