<script setup lang="ts">
import { datelocalised } from '@/utils/date'
import { onMounted, ref, toRef } from 'vue'
import { useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useQuoteStore } from '@/stores/quote'
import { DriverDetailsSchema, type DriverDetailsSchemaErrorsType } from '@/stores/quote.schema'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import QuoteFormContinueButton from '@/components/Quote/QuoteFormContinueButton.vue'
import BackButton from '@/components/Quote/BackButton.vue'
import ProgressDots from '@/components/Quote/ProgressDots.vue'
import QuoteFormFieldErrors from '@/components/Quote/QuoteFormFieldErrors.vue'
import ToolTip from '@/components/Tooltip.vue'
import FieldDateOfBirth from '@/components/Quote/FieldDateOfBirth.vue'
import FieldPostcodeAddressLookup from '@/components/Quote/FieldPostcodeAddressLookup.vue'
import LoadingText from '@/components/LoadingText.vue'
import FormSpinner from '@/components/Quote/FormSpinner.vue'
import FormServerErrors from '@/components/Quote/FormServerErrors.vue'
import { getQuickQuote, isZixtyAxiosErrorCheck, type ApiErrorResponse } from '@/utils/api'

const $router = useRouter()

useHead({
  title: 'Driver Details - Zixty Quote'
})

const quote = useQuoteStore()

const inAjaxCall = ref<boolean>(false)

const showAddressField = ref<boolean>(true)

const errorsClient = ref<DriverDetailsSchemaErrorsType | undefined>()
const errorsServer = ref<ApiErrorResponse | undefined>()

/* get the choices list */
const createEnumerationMappingHelper = (
  arr?: Array<{ code: string; description: string }>,
  selectedCode?: string
) => {
  return (arr || []).map(({ code, description }) => ({
    value: code,
    label: description,
    selected: !!(selectedCode && code === selectedCode)
  }))
}

const dlntypeChoices = ref(
  createEnumerationMappingHelper(
    [
      {
        code: 'LICENCE_TYPE_FULL',
        description: 'UK full'
      },
      {
        code: 'LICENCE_TYPE_PROVISIONAL',
        description: 'UK provisional/ learner'
      },
      {
        code: 'LICENCE_TYPE_NORTHERN_IRELAND',
        description: 'Northern Ireland licence'
      },
      {
        code: 'LICENCE_TYPE_EU',
        description: 'EU licence'
      },
      {
        code: 'LICENCE_TYPE_INTERNATIONAL',
        description: 'International driving licence'
      }
    ],
    quote.inputs.dlntype
  )
)

const postcodeReset = (e: Event) => {
  quote.inputs.address = ''
  quote.inputs.postcode = ''
  showAddressField.value = false
  setTimeout(() => {
    showAddressField.value = true
  }, 0)
}

const onSubmit = async (e: Event) => {
  if (!!inAjaxCall.value) return
  inAjaxCall.value = true

  quote.helpers.recordWebEvent('DRIVER_SUBMITTED')

  const safeParsed = DriverDetailsSchema.safeParse({ ...quote.inputs })

  errorsClient.value = safeParsed.success ? undefined : safeParsed.error.flatten()
  errorsServer.value = undefined

  if (safeParsed.success) {
    try {
      const request = {
        correlation_id: quote.responses.correlation_id || '',
        cover_start: datelocalised(quote.inputs?.cover_start_date_time).toISOString(),
        cover_period_hours: quote.inputs.cover_period_hours || 0,
        first_name: quote.inputs.first_name || '',
        last_name: quote.inputs.last_name || '',
        date_of_birth: datelocalised(quote.inputs.date_of_birth).format('YYYY-MM-DD'),
        address: quote.inputs.address || '',
        email_address: quote.inputs.email_address || '',
        licence_type: String(quote.inputs.dlntype)
      }

      const response = await getQuickQuote(request)

      if (response.data.status !== 'OK') {
        $router.push({
          name: 'quote.sorry'
        })
      } else {
        quote.responses.quickquote = response.data
        quote.responses.quote_expires_at = response?.headers['x-expiry-date'] || ''

        $router.push({
          name: 'quote.quick-quote'
        })
      }
    } catch (error) {
      errorsServer.value = isZixtyAxiosErrorCheck(error) ? error.response?.data : undefined
    }
  }

  inAjaxCall.value = false
}

const setFieldErrorOnCondition = (
  condition: boolean,
  fieldName: string,
  fieldErrorMessage: string,
  pageErrorMessage: string
) => {
  if (condition) {
    errorsClient.value = {
      formErrors: errorsClient.value?.formErrors || [],
      fieldErrors: {
        ...errorsClient.value?.fieldErrors,
        [fieldName]: [fieldErrorMessage]
      }
    }

    errorsServer.value = {
      code: '',
      message: '',
      what_to_do: '',
      what_to_say: pageErrorMessage
    }
  }
}

onMounted(() => {
  quote.helpers.recordWebEvent('DRIVER_DETAILS')

  // show the error for the 'first_name' being a single letter
  setFieldErrorOnCondition(
    quote.responses?.aggregator?.single_char_first_name === true,
    'first_name',
    `Please enter the driver's first name, and not just their initial.`,
    `Please check the fields below before continuing.`
  )

  // show the error for the 'first_name' has a title
  setFieldErrorOnCondition(
    quote.responses?.aggregator?.first_name_includes_title === true,
    'first_name',
    `Please enter the driver's first name without the title.`,
    `Please check the fields below before continuing.`
  )

  // show the error for the 'last_name' has a title
  setFieldErrorOnCondition(
    quote.responses?.aggregator?.last_name_includes_title === true,
    'last_name',
    `Please enter the driver's last name without the title.`,
    `Please check the fields below before continuing.`
  )

  // show the error for the 'address' is missing
  setFieldErrorOnCondition(
    Boolean(quote.responses.aggregator?.postcode_lookup?.addresses && !quote.inputs.address),
    'address',
    `We have not been able to find your address, please enter the driver's address.`,
    `Please check the fields below before continuing.`
  )
})
</script>

<template>
  <div class="w-full">
    <div class="pt-6 mx-auto lg:w-5/12 md:max-w-sm">
      <div class="py-3 md:py-6 flex justify-center">
        <ProgressDots :total="3" :selected="2" />
      </div>
      <div class="py-3 md:py-6 flex justify-center items-center text-center">
        <QuoteFormTitle>Driver details</QuoteFormTitle>
      </div>
      <FormServerErrors v-if="errorsServer" :error="errorsServer" />
      <div class="w-full">
        <form
          id="form-quote-your-details"
          @submit.prevent="onSubmit"
          novalidate
          :class="{
            'form23a-loading': inAjaxCall
          }"
        >
          <FormSpinner v-if="inAjaxCall" />
          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.first_name
            }"
          >
            <label class="form23a-label" for="first_name">
              First name <small> (as shown on your driving licence) </small>
            </label>
            <div class="w-full md:max-w-sm flex">
              <input
                type="text"
                name="first_name"
                id="form-quote-your-details--field-first-name"
                class="data-hj-allow form23a-input w-full"
                placeholder="Enter driver's first name..."
                v-model="quote.inputs.first_name"
              />
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.first_name" />
          </div>
          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.last_name
            }"
          >
            <label class="form23a-label" for="last_name">Last name</label>
            <div class="w-full md:max-w-sm flex">
              <input
                type="text"
                name="last_name"
                id="form-quote-your-details--field-last-name"
                class="data-hj-allow form23a-input w-full"
                placeholder="Enter driver's last name..."
                v-model="quote.inputs.last_name"
              />
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.last_name" />
          </div>
          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.dlntype
            }"
            id="field-group-dlntype"
          >
            <label class="form23a-label flex" for="dlntype">
              Select your driving licence type
              <span class="mx-0.5"></span>
              <ToolTip
                content="Please note that we currently only accept Full UK and EU driving licences."
              />
            </label>
            <div class="w-full">
              <select
                id="form-quote-almost-there--field-dlntype"
                class="data-hj-allow form23a-select w-full"
                name="dlntype"
                v-model="quote.inputs.dlntype"
                :class="{
                  'form23a-unselected': !quote.inputs.dlntype
                }"
              >
                <option :value="undefined" disabled>Please pick an option...</option>
                <option v-for="item in dlntypeChoices" :value="item.value" :key="item.value">
                  {{ item.label }}
                </option>
              </select>
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.dlntype" />
          </div>
          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.date_of_birth
            }"
          >
            <FieldDateOfBirth v-model="quote.inputs.date_of_birth" :errors-client="errorsClient" />
          </div>
          <div
            id="choices-address-lookup"
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.address || !!errorsClient?.fieldErrors?.postcode
            }"
          >
            <label class="form23a-label" id="address_lookup_label">
              Postcode
              <small
                v-if="!!quote.inputs?.postcode || !!quote.inputs?.address"
                class="hover:text-[#95C11F] cursor-pointer"
                @click="postcodeReset"
              >
                (reset)
              </small>
            </label>
            <div class="w-full" v-if="!!showAddressField">
              <FieldPostcodeAddressLookup
                class="w-full"
                id="form-quote-your-details--field-address-lookup"
                :correlation_id="quote.responses.correlation_id"
                v-model:address="quote.inputs.address"
                v-model:postcode="quote.inputs.postcode"
                placeholder="Enter driver's postcode..."
              />
            </div>
            <QuoteFormFieldErrors
              :errors="errorsClient?.fieldErrors?.address || errorsClient?.fieldErrors?.postcode"
            />
          </div>
          <div
            class="form23a-field-group"
            :class="{
              error: !!errorsClient?.fieldErrors?.email_address
            }"
          >
            <label class="form23a-label flex" for="email_address">
              Email address
              <span class="mx-0.5"></span>
              <ToolTip
                content="Please use your real email address, as we need this in order to send you important information."
              />
            </label>
            <div class="w-full md:max-w-sm flex">
              <input
                type="email"
                name="email_address"
                id="form-quote-your-details--field-email-address"
                placeholder="Enter driver's email address..."
                class="form23a-input w-full"
                v-model="quote.inputs.email_address"
              />
            </div>
            <QuoteFormFieldErrors :errors="errorsClient?.fieldErrors?.email_address" />
          </div>
          <div class="py-3 md:py-6 w-full flex justify-center">
            <QuoteFormContinueButton
              type="submit"
              :deactivate="inAjaxCall"
              id="submit-details-button"
            >
              <span v-if="inAjaxCall" class="flex items-center">
                <LoadingText text="Quoting" :dots="0" />
              </span>
              <span v-else>Continue</span>
            </QuoteFormContinueButton>
          </div>
          <BackButton route-name="quote.time" />
        </form>
      </div>
    </div>
  </div>
</template>
