<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { ref, computed, watch, onMounted } from 'vue'
import { useQuoteStore } from '@/stores/quote'
import QuoteFormTitle from '@/components/Quote/QuoteFormTitle.vue'
import QuoteFormContinueButton from '@/components/Quote/QuoteFormContinueButton.vue'
import BackButton from '@/components/Quote/BackButton.vue'
import LinkExternal from '@/components/LinkExternal.vue'
import { getHTMLContent } from '@/utils/api'
import { captureException as SentryCaptureException } from '@sentry/vue'

const $router = useRouter()

useHead({
  title: 'Declarations - Zixty Quote'
})

const redirectIfCoverStartTimeHistorical = () => {
  if (quote.helpers.hasQuoteExpired()) {
    return $router.push({
      name: 'quote.time'
    })
  }
}

const UnableToLoadHtmlMessage = `
  <div>
    <div class="my-3">Unable to load Declaration.</div>
    <div class="my-3">Please click the Back link below, then try again.</div>
  </div
`

const quote = useQuoteStore()

const html = ref<Record<string, string>>({
  //
})

const haveErrors = ref<boolean>(false)

const urls = computed<string[]>(() => {
  return (quote?.policy?.selectedBrand?.Documents || [])
    .filter((item) => item.mime_type === 'text/html')
    .map((item) => item.url)
})

const processUrls = async (urlsToProcess: string[]) => {
  urlsToProcess
    .filter((url) => !html.value[url])
    .forEach(async (url: string) => {
      try {
        const res = await getHTMLContent(url)
        const parsed = new DOMParser().parseFromString(res.data, 'text/html')

        ;[].slice.call(parsed.getElementsByTagName('ol')).forEach((item: HTMLOListElement) => {
          if (!item.style.listStyleType) {
            item.style.listStyleType = 'decimal'
          } else {
            item.style.marginLeft = '15px'
            item.style.padding = 'initial'
          }
        })

        html.value[url] = parsed.querySelector('body')?.innerHTML || ''
      } catch (err) {
        html.value[url] = UnableToLoadHtmlMessage
        haveErrors.value = true

        SentryCaptureException(err, {
          extra: {
            DeclarationsUrl: url
          }
        })

        redirectIfCoverStartTimeHistorical()
      }
    })
}

onMounted(() => {
  redirectIfCoverStartTimeHistorical()

  quote.helpers.recordWebEvent('DECLARATIONS')
  processUrls(urls.value)
})

const onSubmit = (e: Event) => {
  quote.inputs.accepted_declarations = true

  $router.push({
    name: 'quote.confirm-and-buy'
  })
}
</script>

<template>
  <div class="w-full">
    <div class="pt-6 mx-auto lg:w-8/12">
      <div class="py-3 md:py-6 flex justify-center text-center">
        <QuoteFormTitle> Declarations </QuoteFormTitle>
      </div>
      <div class="mx-auto">
        <form id="form-quote-declarations" @submit.prevent="onSubmit" novalidate>
          <div
            class="py-3 text-[#595959] text-sm leading-normal"
            v-if="!!urls && !!urls.length"
            v-for="url in urls"
          >
            <div class="declarations-html" v-if="!!html && !!html[url]" v-html="html[url]"></div>
          </div>
          <div class="form23a-field-group" v-if="!haveErrors">
            <label class="form23a-label flex items-center">
              <span class="text-sm font-semibold">
                By tapping Continue you agree that you have read and you accept the insurer terms
                above, as well as the Zixty
                <LinkExternal href="https://www.zixty.com/terms-and-conditions/"
                  >Terms & Conditions</LinkExternal
                >, and our
                <LinkExternal href="https://www.zixty.com/terms-of-business/"
                  >Terms of Business</LinkExternal
                >.
              </span>
            </label>
          </div>
          <div class="py-3 md:py-6 w-full flex justify-center" v-if="!haveErrors">
            <QuoteFormContinueButton type="submit" id="submit-go-to-buy-button">
              Continue
            </QuoteFormContinueButton>
          </div>
          <BackButton route-name="quote.upgrade" />
        </form>
      </div>
    </div>
  </div>
</template>

<style>
.declarations-html strong {
  font-weight: 600;
}
</style>
